import Vue from "vue";
import { Component } from "vue-property-decorator";

@Component({ name: "Navigation" })
export default class Navigation extends Vue {
  drawer = false;
  navigation = [
    {
      title: "Home",
      link: "/"
    },
    {
      title: "Getting started",
      link: "/getting-started-with-axis",
      subnav: [
        {
          title: "ABAP Proxy",
          link: "/getting-started-with-axis/abap-proxy"
        },
        {
          title: "BW Data Extractor",
          link: "/getting-started-with-axis/bw-data-extractor"
        },
        {
          title: "Data Wizards",
          link: "/getting-started-with-axis/data-wizards"
        },
        {
          title: "Events",
          link: "/getting-started-with-axis/events"
        },
        {
          title: "Function Modules",
          link: "/getting-started-with-axis/function-modules"
        },
        {
          title: "Hyper Automation",
          link: "/getting-started-with-axis/hyper-automation"
        },
        {
          title: "IDoc",
          link: "/getting-started-with-axis/idoc"
        },
        {
          title: "Receiver Framework",
          link: "/getting-started-with-axis/receiver-framework"
        },
        {
          title: "RFC Configuration",
          link: "/getting-started-with-axis/configuration"
        },
        {
          title: "Table Service",
          link: "/getting-started-with-axis/table-service"
        },
        {
          title: "Table Service Extract",
          link: "/getting-started-with-axis/table-service-extract"
        }
      ]
    },
    {
      title: "Learning Hub",
      link: "/Learning-Hub"
    },
    {
      title: "About",
      subnav: [
        {
          title: "Release Notes",
          link: "/about/release-notes"
        }
      ]
    },
    {
      title: "Request Trial",
      link: "/request-trial"
    },
    {
      title: "Request Demo",
      link: "/request-demo"
    }
  ];
}
