import { Component, Vue } from "vue-property-decorator";
import * as countryCodes from "@/assets/countryCodes.json";
// import axios from "axios";

@Component({ name: "RequestTrial" })
export default class RequestTrial extends Vue {
  countryCodes = Object.values(countryCodes);
  requiredField = false;
  valid: boolean = false;
  trialAccountType = "";
  name: string = "";
  email: string = "";
  phoneNumber = "";
  phonePrefix = null;
  position = "";
  company = "";
  vat = "";
  sap = "";
  isRepresentative = false;
  terms = false;
  termsError = true;

  submitting = false;
  showResponseError = false;
  showResponseSuccess = false;

  nameRules = [
    (v: string) => !!v || "Name is required"
  ];

  positionRules = [
    (v: string) => !!v || "Position is required"
  ];

  companyRules = [
    (v: string) => !!v || "Company is required"
  ];

  emailRules = [
    (v: string) => !!v || "E-mail is required",
    (v: string) => /.+@.+\..+/.test(v) || "E-mail must be valid"
  ];

  async validate() {
    await this.$nextTick();
    if (
      (this.$refs.form as Vue & {
        validate: () => boolean;
      }) !== undefined
    ) {
      this.valid = (this.$refs.form as Vue & {
        validate: () => boolean;
      }).validate();
    }
  }

  updated() {
    this.$nextTick(() => {
      if (this.$refs.terms) {
        this.termsError = (this.$refs.terms as any).hasError;
      }
    });
  }

  reloadPage() {
    window.location.reload();
  }

  submit() {
    this.submitting = true;
    this.validate().then(() => {
      if (this.valid) {
        // using axios to submit, and dostuff on data returned
        /* gives CORS error (invixo.com does not allow cors from axis.invixo.com (yet))
        const bodyFormData = new FormData();
        bodyFormData.append("userName", "Fred");
        bodyFormData.append("post_id", "3327");
        bodyFormData.append("form_id", "7cb37dd");
        bodyFormData.append("action", "elementor_pro_forms_send_form");
        bodyFormData.append("form_fields[trialAccountType]", this.trialAccountType);
        bodyFormData.append("form_fields[name]", this.name);
        bodyFormData.append("form_fields[email]", this.email);
        bodyFormData.append("form_fields[prefixCountry]", "Phone Prefix:" + this.phonePrefix);
        bodyFormData.append("form_fields[telephone]", this.phoneNumber);
        bodyFormData.append("form_fields[position]", this.position);
        bodyFormData.append("form_fields[company]", this.company);
        bodyFormData.append("form_fields[vat]", this.vat);
        bodyFormData.append("form_fields[isRepresentative]", "" + this.isRepresentative);
        bodyFormData.append("form_fields[terms]", "" + this.terms);

        axios({
          method: "post",
          url: "https://www.invixo.com/wp-admin/admin-ajax.php",
          data: bodyFormData,
          headers: { "Content-Type": "application/x-www-form-urlencoded" }
        })
          .then(function(response){
            // handle success
            console.log(response);
          })
          .catch(function(response) {
            // handle error
            console.log(response);
          });
*/

        (this.$refs.form as any).$el.submit();
        // this.wait(6000);
        // already remove submitting circler here, and show 'yay' message
        // this.submitting = false;
        this.showResponseSuccess = true;

      } else {
        this.submitting = false;
      }
    });
  }

}