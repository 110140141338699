<template><div>
<div class="sect1">
<h2 class="section-headline pb-4" id="_function_modules">Function Modules</h2>
<div class="sectionbody">
<div class="sect2">
<h2 id="_function_modules_2" class="section-headline pb-4">Function Modules</h2>
<div class="paragraph">
<p>In the following section, the <em><strong>Function Modules</strong></em> will be described and visualized.</p>
</div>
<div class="paragraph">
<p>This section describes how to create and discover Function Module services in Boomi for SAP.</p>
</div>
<div class="sect3">
<h3 id="_function_module_search" class="section-headline pb-4">Function Module Search</h3>
<div class="paragraph">
<p>When you enter the front page of Boomi for SAP, choose <em><strong>Function Module</strong></em> under <strong>CREATE</strong> in the menu on the left side.</p>
</div>
<div class="paragraph">
<p>By clicking on <strong>'Function Module'</strong>, you will be directed to the Function Module page listing all Function Modules which exist in SAP allowing you to enable them for use in Boomi. It is possible to find all existing modules, however, it is only the Modules made available in SAP (by default only RFC-enabled Modules) that are possible to expose to Boomi as Function Module services.</p>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/FM-frontpage.png" alt="FM-frontpage" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p>The search function in the upper left corner of the page can be used to search for Function Modules by name, description, or a combination of name and description. Searching by name for <em>'BAPI_BUPA'</em> will list all Function Modules starting with that string. If you search for a word that is contained somewhere in the name, put a '*' in front of the word.</p>
</div>
<div class="paragraph">
<p>Note: The descriptions are created by developers in SAP. Hence, descriptions can be empty.</p>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/FM-search.png" alt="FM-search" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p>It is also possible to see any group relations related to the search you make. If you search for 'BAPI_BUPA', all Function Modules related to that and any associated groups will be shown. To show group relations, enable the <strong>'Search group relations'</strong> option below the search boxes.</p>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/FM-related.png" alt="FM-related" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p>If you want to ensure specific search results, the strict search option will be helpful for you. Simply tick the box <strong>Strict search</strong>, but be aware that strict search is case sensitive and excludes wildcards(*).</p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/FM-strict-search.png" alt="FM-strict-search" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
</div>
<div class="sect3">
<h3 id="_unsupported_function_modules" class="section-headline pb-4">Unsupported Function Modules</h3>
<div class="paragraph">
<p>It is only possible to create a Function Module service (and thereby connecting to the service in Boomi) if a developer has exposed the particular Function Module in SAP. However, it&#8217;s possible to include unsupported Function Modules in the search results which the citizen integrators can use to get an overview of existing Function Modules in SAP.</p>
</div>
<div class="paragraph">
<p>To see unsupported function modules, enable the <strong>'Include unsupported function modules'</strong> option below the search boxes.</p>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/FM-text.png" alt="FM-text" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p>As shown in the picture above, a red panel will show up indicating that the Function Module is not supported and cannot be created as a service. By hovering above the exclamation mark, the reason for not being supported is displayed.</p>
</div>
<div class="paragraph">
<p>If the unsupported Function Modules are not RFC-enabled, it is possible to make them available in Boomi for SAP. All Non RFC-enabled Function Modules will show up as unsupported functions that cannot be called by default in SAP as these are functions that are defined not to be called from outside of SAP. To make the Non RFC-enabled functions available in Boomi for SAP, go to <em><strong>/IXBX/FM_SETTINGS</strong></em> or /<em><strong>IXBX/ZBX010</strong></em> to enable the functions. Think carefully before exposing Non RFC-enabled Function Modules as the developer did not intend for them be exposed outside of SAP. Unsupported function modules contain an object type that is not yet supported by our JSON translator.</p>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/FM-sap-rfc.png" alt="FM-sap-rfc" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p>The records entered indicates if exposing 'Non RFC'-enabled Function Modules is allowed in the system specified in 'SAP System ID' and from which date the record is valid 'Valid from'. 'X' is true and '' is false in the field 'Non RFC'. When Boomi for SAP checks if RFC is allowed, it reads the record where the System ID is equal to the system it is executed in with the lowest date greater or equal to the system date. In the examples in the figure above:</p>
</div>
<div class="ulist">
<ul>
<li>
<p>Records will only be selected from the 'DEV' system</p>
</li>
<li>
<p>Between 13.01.2021 and 16.02.2021 (both days included), the first record will be the 'active' setting. Creating and exposing Function Module services for 'Non RFC'-enabled Function Modules is not allowed.</p>
</li>
<li>
<p>On 17.01.2021, the second record will be the 'active' setting. Non RFC Modules will be allowed. Creating and exposing Function Module services for 'Non RFC'-enabled Function Modules is allowed.</p>
</li>
<li>
<p>On and after 18.02.2021, the third record will be the 'active' setting. Creating and exposing Function Module services for 'Non RFC'-enabled Function Modules is not allowed.</p>
</li>
</ul>
</div>
<div class="paragraph">
<p>If the cause of a Function Module being unsupported is not due to 'Non RFC' being disabled, the Function Module can have parameters that cannot be called via Boomi for SAP. This could be due to a parameter expecting an instance of an object. If such a function is needed, contact your SAP developers and ask if they can create a wrapper module or a version with simple parameters. This kind of function will show up with a red panel and an exclamation mark which shows the reason for the Function Module being unsupported.</p>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/FM-unsupported.png" alt="FM-unsupported" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
</div>
<div class="sect3">
<h3 id="_create_a_function_module" class="section-headline pb-4">Create a Function Module</h3>
<div class="paragraph">
<p>When you have found the Function Module you want to expose as a service, click on the Module on the right hand side and drag and drop it onto the canvas or double click it. If you choose a wrong Function Module, you can just click on the correct Module and drag and drop it again. Then the former Module will disappear and the new Module will appear on the canvas.</p>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/bapi-bupa-adress-get.png" alt="bapi-bupa-adress-get" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p><strong>Configure Service</strong>
This section describes the Function Module elements that will be used when creating a service. The json schema definition adds a pre pending letter for which kind the parameter is related to. These are shown in the highlighted text:</p>
</div>
<div class="paragraph">
<p><strong>Import - "I_":</strong> Input parameters</p>
</div>
<div class="paragraph">
<p><strong>Export - "E_":</strong> Output parameters</p>
</div>
<div class="paragraph">
<p><strong>Changing - "C_":</strong> Changing parameters</p>
</div>
<div class="paragraph">
<p><strong>Tables - "T_":</strong> Tables are both in, out, and changing depending on the individual Module. Technically, they can be seen as changing, where in, out, and changing can be different data types. Tables will always be tables.</p>
</div>
<div class="paragraph">
<p><em><strong>Note:</strong></em> If mandatory tables are not included in the request, Boomi for SAP Core will automatically insert a null array when communicating with SAP. If output is needed for optional tables, a null array must be added to the input Function Module JSON document. E.g. <code>"T_table_name" : null</code>  or <code>"T_table_name" : []</code>.</p>
</div>
<div class="paragraph">
<p><strong>Exceptions:</strong> Exceptions indicate which errors the modules have specified as possible errors. Take the possible exceptions into account when developing Boomi processes. The exceptions can be class-based. The figure below shows an example of a Function Module that does not contain exception classes but includes two expected errors when calling the function.</p>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/FM-error.png" alt="FM-error" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p>Within <em>Import</em>, <em>Export</em>, <em>Changing</em> or <em>Tables</em>, the following parameters occur:</p>
</div>
<div class="paragraph">
<p><strong>Parameter name:</strong> Name of the parameter included in the import of the Function Module</p>
</div>
<div class="paragraph">
<p><strong>Mandatory:</strong> The check box is ticked if the parameter is mandatory.</p>
</div>
<div class="paragraph">
<p><strong>Type:</strong> The type indicates the technical details of the parameter. If a description exists, it will be shown here as well. To see the details of the type, you must hover over the colored circles.</p>
</div>
<div class="paragraph">
<p><strong>Default value:</strong> Information provided by SAP. If a default value is defined, the value will be sent if no other value is requested.</p>
</div>
<div class="paragraph">
<p><strong>Documentation:</strong> Shows available documentation. Documentation is not available in all cases.</p>
</div>
<div class="paragraph">
<p><strong>Associated type:</strong> If a parameter is defined with reference to an object type, the associated type will be shown here.</p>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/FM-parameter-import.png" alt="FM-parameter-import" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
</div>
<div class="sect3">
<h3 id="_service_configuration" class="section-headline pb-4">Service Configuration</h3>
<div class="paragraph">
<p>This section describes how to configure a Function Module service.</p>
</div>
<div class="paragraph">
<p>Click on <strong>'CONFIGURE FUNCTION MODULE'</strong> in the upper right corner. A pop-up window will then appear with the following fields:</p>
</div>
<div class="paragraph">
<p><strong>Service name:</strong> The name of the service you are about to create. This is the same name as the Function Module.</p>
</div>
<div class="paragraph">
<p><strong>Mode:</strong> This is the mode indicating whether the service created is transportable. By selecting PROD mode the service can be transported, meaning that you must select a transport for the service before the service can be created. By selecting Local the service created can not be transported, meaning that you should not choose a transport for the service.</p>
</div>
<div class="paragraph">
<p><strong>Transport:</strong> This is the transport that will be assigned to the service.</p>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/FM-configure-service.png" alt="FM-configure-service" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p>When the required fields are filled out, click on <strong>'Create Service'</strong>.</p>
</div>
<div class="paragraph">
<p>If the service is already created, an error message will show up.</p>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/FM-service-error.png" alt="FM-service-error" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p>If the service is created successfully, a pop-up window will appear.</p>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/FM-success.png" alt="FM-success" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
</div>
<div class="sect3">
<h3 id="_see_active_services" class="section-headline pb-4">See Active Services</h3>
<div class="paragraph">
<p>This section describes how to find active Function Modules services.</p>
</div>
<div class="paragraph">
<p>By clicking on <strong>'Function Modules'</strong> under <strong>'LIST &amp; MANAGE'</strong>, you will be directed to the page with all the active Function Module services.</p>
</div>
</div>
<div class="sect3">
<h3 id="_search_for_generated_services" class="section-headline pb-4">Search for Generated Services</h3>
<div class="paragraph">
<p>You can search for table services by using the search function in the upper right corner of the page. If, for example, you search for the name 'BAPI', all active Function Modules services containing 'BAPI' will appear.</p>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/FM-search-bapi.png" alt="FM-search-bapi" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
</div>
<div class="sect3">
<h3 id="_delete_service" class="section-headline pb-4">Delete Service</h3>
<div class="paragraph">
<p>Go to the page with active Functions Module services and search for the service you would like to delete. Then click on the trash can icon on the row of the service you want to delete. A pop-up window will then appear. If the service is created in PROD mode, then a transport must be selected. Click on <strong>'Delete'</strong> to delete the service.</p>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/FM-delete.png" alt="FM-delete" />
</div>
</div>
</div>
<div class="sect3">
<h3 id="_mandatory_fields" class="section-headline pb-4">Mandatory Fields</h3>
<div class="paragraph">
<p>If Import, Changing, Tables parameters are mandatory or has a default value it is not required to be sent from client.</p>
</div>
</div>
</div>
<div class="sect2">
<h2 id="_boomi_atomsphere" class="section-headline pb-4">Boomi AtomSphere</h2>
<div class="paragraph">
<p>This section describes how to utilize the Function Module service created above in Boomi.</p>
</div>
<div class="paragraph">
<p>The <em><strong>Boomi for SAP Connector</strong></em> is no different from other Boomi Connectors. It makes development easy by importing operations and profiles.</p>
</div>
<div class="sect3">
<h3 id="_building_a_boomi_process" class="section-headline pb-4">Building a Boomi Process</h3>
<div class="paragraph">
<p>Create a new Process and configure the Start Shape type to <em><strong>No Data</strong></em></p>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/FM-nodata.png" alt="FM-nodata" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p>Add a <em><strong>Message Shape</strong></em> immediately after the Start Shape and connect the Start Shape to the Message Shape. In this example process, the Message Shape contains test data corresponding to the Request Profile created in the next step. When building your processes, map your data to the Request Profiles of the Operations.</p>
</div>
</div>
<div class="sect3">
<h3 id="_connection" class="section-headline pb-4">Connection</h3>
<div class="paragraph">
<p>This section describes how to configure the <em><strong>Boomi for SAP Connector</strong></em>.</p>
</div>
<div class="paragraph">
<p>Add a Connector Shape to the canvas. Add a <em>'Display Name'</em>. Select an existing Connector or create a new Connector (see below).</p>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/FM-connector.png" alt="FM-connector" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p>If a Connector has not already been created in your Boomi account, create a new Connector by clicking on the small <strong>'green plus icon'</strong>.</p>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/boomi-connection-green-icon.png" alt="boomi-connection-green-icon" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p>Fill in the fields shown on the connection page with the values provided by your SAP team. Click the <strong>'Save and Close'</strong> button to go back.</p>
</div>
<div class="paragraph">
<p>When the Connector is configured, set the Action to <strong><em>FUNCTION</em></strong>.</p>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/FM-action.png" alt="FM-action" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
</div>
<div class="sect3">
<h3 id="_operation" class="section-headline pb-4">Operation</h3>
<div class="paragraph">
<p>Create a new Connector Operation, you should click on the small <strong>'green plus icon'</strong> in the Operation field. You are redirected to the page where you can create the Connector Operation.</p>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/boomi-operater.png" alt="boomi-operater" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p>To easily find the operation in the future, add the name of the service you created in Boomi for SAP in the Operation name in the upper left corner. In this example, we named the operation <em>'BAPI_BUPA_ADDRESSES_GET'</em>.</p>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/FM-operation.png" alt="FM-operation" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p>Click on the <strong>'Import'</strong> button to import the Operation and Profiles.</p>
</div>
<div class="paragraph">
<p>Choose an <em><strong>Atom</strong></em> to run perform the import. The Atom should have network access to the SAP (Boomi for SAP) instance. Choose the appropriate Connector. The optional Filter option allows for wildcard filtering.</p>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/FM-import.png" alt="FM-import" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p>When you have selected the Atom and the Connection, click on the <strong>'Next'</strong> button to query the objects. Choose the Object Type from the drop-down menu.</p>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/FM-objecttype.png" alt="FM-objecttype" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p>Click on <strong>'Next'</strong> and a pop-up window will then appear showing the following:</p>
</div>
<div class="paragraph">
<p><strong>Object Name:</strong> The name of the Function Module</p>
</div>
<div class="paragraph">
<p><strong>Request Profile:</strong> The format of the data expected by the Operation.</p>
</div>
<div class="paragraph">
<p><strong>Response Profile:</strong> The format of the data returned from the Operation.</p>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/FM-operationloaded.png" alt="FM-operationloaded" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p>Click on <strong>'Finish'</strong> to get back to the Operation view with the newly imported Operation and Profiles.</p>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/FM-import-done.png" alt="FM-import-done" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p>Click on <strong>'Save and Close'</strong> to get back to the configuration of the Connector Shape. Click on <strong>'Save'</strong>.</p>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/FM-connector-done.png" alt="FM-connector-done" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p>Finalize the configuration by adding a <em><strong>Stop Shape</strong></em> and connecting it to the Connector Shape.</p>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/FM-process.png" alt="FM-process" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p>Add test data to the <em><strong>Message Shape</strong></em> that conforms to the Request Profile format.
To test the process, click on <strong>'Test'</strong> in the upper right corner and choose the Atom to run the test. Click then on <strong>'Run Test'</strong>.</p>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/FM-test.png" alt="FM-test" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p>When the test execution has successfully completed, a message will briefly pop up in the lower right corner with the notifying that the "Test execution of BAPI_BUPA_ADDESSES_GET completed successfully".</p>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/FM-testsuccess.png" alt="FM-testsuccess" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p>To see the result click on the <strong>Connector Shape</strong> on the canvas and then on <strong>'Connection Data'</strong>.</p>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/FM-testsuccess.png" alt="FM-testsuccess" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p>Then click on the <strong>file icon</strong> right under <em>View</em> to see the output.</p>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/FM-documentviewer.png" alt="FM-documentviewer" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
</div>
<div class="sect3">
<h3 id="_filter_response_parameter" class="section-headline pb-4">FILTER_RESPONSE PARAMETER</h3>
<div class="paragraph">
<p>In the request profile of a function module, there is a parameter called I_/IXBX/FILTER_RESPONSE (I_~1IXBX~1FILTER_RESPONSE in boomi profiles), which allows for filtering the returned data.
For instance if a function module returns a lot of data but in a table which is not needed, then this table can be filtered away before the data is returned to the atom from the backend.</p>
</div>
<div class="paragraph">
<p>For instance you can filter the returned values from 'export', or the tables from 'tables'.  You can use either the backend-names of the parameters, or the service exposed names (with E_ or T_ in front)</p>
</div>
</div>
<div class="sect3">
<h3 id="_force_commit_or_rollback_parameter" class="section-headline pb-4">FORCE_COMMIT_OR_ROLLBACK PARAMETER</h3>
<div class="paragraph">
<p>The request profile also includes a parmaeter called I_/IXBX/FORCE_COMMIT_OR_ROLLBACK (I_~1IXBX~1FORCE_COMMIT_OR_ROLLBACK in boomi profiles).
This parameter allows the caller to force a commit or rollback after a function module has been called. If this flag is set (to 'X') a commit will be perfomed after the function module was executed, or a rollback, if there was an error executing the function module.
This is particular useful for BAPIs, since they usually don&#8217;t call commit themselves.</p>
</div>
</div>
</div>
</div>
</div>
<div class="sect1">
<h2 class="section-headline pb-4" id="_create_services_via_boomi_for_sap_api">Create Services via Boomi for SAP API</h2>
<div class="sectionbody">
<div class="sect2">
<h2 id="_introduction" class="section-headline pb-4">Introduction</h2>
<div class="paragraph">
<p>This feature was developed to enhance the capabilities of Boomi for SAP and provide an easier way to create services through API calls. Its main purpose is for bundling, and automatic creation of services from Boomi integration.</p>
</div>
<div class="paragraph">
<p>Supported services at the moment are:</p>
</div>
<div class="ulist">
<ul>
<li>
<p>Function Module enablement</p>
</li>
<li>
<p>Subscription creation</p>
</li>
<li>
<p>Table service creation</p>
</li>
<li>
<p>Topic creation</p>
</li>
<li>
<p>Receiver creation</p>
</li>
<li>
<p>Function Module activation</p>
</li>
<li>
<p>BW activation</p>
</li>
</ul>
</div>
</div>
<div class="sect2">
<h2 id="_function_module_description" class="section-headline pb-4">Function Module Description</h2>
<div class="paragraph">
<p>The core of this feature is a SAP function module designed to create services programmatically. Here are the key details:</p>
</div>
<div class="sect4">
<h5 id="_function_module_name_for_fm_enablement_ixbxfm_service_creation"><strong>Function Module Name for FM enablement <code>/IXBX/FM_SERVICE_CREATION</code></strong></h5>
<div class="paragraph">
<p><em>Input Parameters:</em></p>
</div>
<div class="ulist">
<ul>
<li>
<p><code>is_fm</code>: Expects a structure with all the information for an FM enablement which includes fm id, transport and service type.</p>
</li>
</ul>
</div>
</div>
<div class="sect4">
<h5 id="_function_module_name_for_subscription_creation_ixbxsubscr_creation"><strong>Function Module Name for subscription creation <code>/IXBX/SUBSCR_CREATION</code></strong></h5>
<div class="paragraph">
<p><em>Input Parameters:</em></p>
</div>
<div class="ulist">
<ul>
<li>
<p><code>is_subscr</code>: Expects a structure with all the information for a subscription creation which includes header, filters, events, transport etc.</p>
</li>
</ul>
</div>
</div>
<div class="sect4">
<h5 id="_function_module_name_for_bw_activation_ixbxbw_activation"><strong>Function Module Name for BW activation <code>/IXBX/BW_ACTIVATION</code></strong></h5>
<div class="paragraph">
<p><em>Input Parameters:</em></p>
</div>
<div class="ulist">
<ul>
<li>
<p><code>is_bw</code>: Expects a structure with all the information for a BW activation which includes datasource, if it is custom_extract and transport.</p>
</li>
</ul>
</div>
</div>
<div class="sect4">
<h5 id="_function_module_name_for_table_service_creation_ixbxtable_service_creation"><strong>Function Module Name for Table Service creation <code>/IXBX/TABLE_SERVICE_CREATION</code></strong></h5>
<div class="paragraph">
<p><em>Input Parameters:</em></p>
</div>
<div class="ulist">
<ul>
<li>
<p><code>is_table_service</code>: Expects a structure with all the information for a table service creation which includes its id, transport, service type, tables, auth etc.</p>
</li>
</ul>
</div>
</div>
<div class="sect4">
<h5 id="_function_module_name_for_topic_creation_ixbxtopic_creation"><strong>Function Module Name for Topic creation <code>/IXBX/TOPIC_CREATION</code></strong></h5>
<div class="paragraph">
<p><em>Input Parameters:</em></p>
</div>
<div class="ulist">
<ul>
<li>
<p><code>is_topic</code>: Expects a structure with all the information for a Topic creation which includes its header, position, maintain type and transport.</p>
</li>
</ul>
</div>
</div>
<div class="sect4">
<h5 id="_function_module_name_for_receiver_creation_ixbxreceiver_creation"><strong>Function Module Name for Receiver creation <code>/IXBX/RECEIVER_CREATION</code></strong></h5>
<div class="paragraph">
<p><em>Input Parameters:</em></p>
</div>
<div class="ulist">
<ul>
<li>
<p><code>is_receiver</code>: Expects a structure with all the information for a Receiver creation which includes its id, type, deployment, maintain type, transport, and parameters data.</p>
</li>
</ul>
</div>
</div>
</div>
<div class="sect2">
<h2 id="endpoints" class="section-headline pb-4">API Endpoint and Authentication</h2>
<div class="paragraph">
<p>To interact with the feature, you will need to make API requests. There are 2 ways of doing it, through the following 2 API endpoints:</p>
</div>
<div class="olist arabic">
<ol class="arabic">
<li>
<p><code><strong><a href="https://{" class="bare">https://{</a><em>hostname</em>}:{<em>port</em>}/zbx/fm-service/{<em>FM name</em>}/read?sap-client={<em>client number</em>}</strong></code>
(this option is used for any other exposed FM):</p>
<div class="ulist">
<ul>
<li>
<p>For FM Enablement: <code><a href="https://{" class="bare">https://{</a><em>hostname</em>}:{<em>port</em>}/zbx/fm-service/<strong>/IXBX/FM_SERVICE_CREATION</strong>/read?sap-client={<em>client number</em>}</code></p>
</li>
<li>
<p>For Subscription Creation: <code><a href="https://{" class="bare">https://{</a><em>hostname</em>}:{<em>port</em>}/zbx/fm-service/<strong>/IXBX/SUBSCR_CREATION</strong>/read?sap-client={<em>client number</em>}</code></p>
</li>
<li>
<p>For BW Activation: <code><a href="https://{" class="bare">https://{</a><em>hostname</em>}:{<em>port</em>}/zbx/fm-service/<strong>/IXBX/BW_ACTIVATION</strong>/read?sap-client={<em>client number</em>}</code></p>
</li>
<li>
<p>For Table Service Creation: <code><a href="https://{" class="bare">https://{</a><em>hostname</em>}:{<em>port</em>}/zbx/fm-service/<strong>/IXBX/TABLE_SERVICE_CREATION</strong>/read?sap-client={<em>client number</em>}</code></p>
</li>
<li>
<p>For Topic Creation: <code><a href="https://{" class="bare">https://{</a><em>hostname</em>}:{<em>port</em>}/zbx/fm-service/<strong>/IXBX/TOPIC_CREATION</strong>/read?sap-client={<em>client number</em>}</code></p>
</li>
<li>
<p>For Receiver Creation: <code><a href="https://{" class="bare">https://{</a><em>hostname</em>}:{<em>port</em>}/zbx/fm-service/<strong>/IXBX/RECEIVER_CREATION</strong>/read?sap-client={<em>client number</em>}</code></p>
</li>
</ul>
</div>
</li>
<li>
<p><code><strong><a href="https://{" class="bare">https://{</a><em>hostname</em>}:{<em>port</em>}/zbx/{<em>service name</em>}-service/generate?sap-client={<em>client number</em>}</strong></code></p>
<div class="ulist">
<ul>
<li>
<p>API Endpoint for FM Enablement: <code><a href="https://{" class="bare">https://{</a><em>hostname</em>}:{<em>port</em>}/zbx/<strong>fm-service</strong>/generate?sap-client={<em>client number</em>}</code></p>
</li>
<li>
<p>API Endpoint for Subscription Creation: <code><a href="https://{" class="bare">https://{</a><em>hostname</em>}:{<em>port</em>}/zbx/<strong>subscr-service</strong>/generate?sap-client={<em>client number</em>}</code></p>
</li>
<li>
<p>API Endpoint for BW Activation: <code><a href="https://{" class="bare">https://{</a><em>hostname</em>}:{<em>port</em>}/zbx/<strong>bw-service</strong>/generate?sap-client={<em>client number</em>}</code></p>
</li>
<li>
<p>API Endpoint for Table Service Creation: <code><a href="https://{" class="bare">https://{</a><em>hostname</em>}:{<em>port</em>}/zbx/<strong>table-service</strong>/generate?sap-client={<em>client number</em>}</code></p>
</li>
<li>
<p>API Endpoint for Topic Creation: <code><a href="https://{" class="bare">https://{</a><em>hostname</em>}:{<em>port</em>}/zbx/<strong>topic-service</strong>/generate?sap-client={<em>client number</em>}</code></p>
</li>
<li>
<p>API Endpoint for Receiver Creation: <code><a href="https://{" class="bare">https://{</a><em>hostname</em>}:{<em>port</em>}/zbx/<strong>receiver-service</strong>/generate?sap-client={<em>client number</em>}</code></p>
</li>
</ul>
</div>
</li>
</ol>
</div>
<div class="paragraph">
<p>For authentication and access, you need Basic Authorization, which means your credentials for the specific SAP system and client.</p>
</div>
</div>
<div class="sect2">
<h2 id="_request_format" class="section-headline pb-4">Request Format</h2>
<div class="paragraph">
<p>To create a service using the function module, send a POST request to the API endpoint. The request should include the following:</p>
</div>
<div class="ulist">
<ul>
<li>
<p>HTTP Method: POST</p>
</li>
<li>
<p>Request Body: JSON payload with the required input parameters.</p>
</li>
</ul>
</div>
</div>
<div class="sect2">
<h2 id="_request_examples" class="section-headline pb-4">Request Examples</h2>
<div class="ulist">
<ul>
<li>
<p>For <a href="#endpoints">API Endpoint and Authentication</a>, Option 1</p>
</li>
</ul>
</div>
<div class="imageblock">
<div class="content">
<img src="graphics/service-creation-via-api-example-2.png" alt="Service creation example 1">
</div>
</div>
<div class="ulist">
<ul>
<li>
<p>For <a href="#endpoints">API Endpoint and Authentication</a>, Option 2:</p>
</li>
</ul>
</div>
<div class="imageblock">
<div class="content">
<img src="graphics/service-creation-via-api-example.png" alt="Service creation example 2">
</div>
</div>
</div>
<div class="sect2">
<h2 id="_response_format" class="section-headline pb-4">Response Format</h2>
<div class="paragraph">
<p>The API response will have a standard structure, including HTTP status codes. Successful responses will return a <code>200 OK</code> status code.</p>
</div>
<div class="imageblock">
<div class="content">
<img src="graphics/service-creation-via-api-successful-request.png" alt="Successful request">
</div>
</div>
</div>
<div class="sect2">
<h2 id="_error_handling" class="section-headline pb-4">Error Handling</h2>
<div class="paragraph">
<p>In case of errors or issues, the API will return appropriate status codes and error messages.</p>
</div>
<div class="imageblock">
<div class="content">
<img src="graphics/service-creation-via-api-error-request.png" alt="Error in request">
</div>
</div>
</div>
</div>
</div>
</div></template>
<script>export default {}; </script>