<template><div>
<div class="sect1">
<h2 class="section-headline pb-4" id="_user_guide">User Guide</h2>
<div class="sectionbody">
<div class="sect2">
<h2 id="_data_wizards" class="section-headline pb-4">Data Wizards</h2>
<div class="paragraph">
<p>In the following section the <em><strong>Data Wizards</strong></em> will be described and visualized.</p>
</div>
<div class="paragraph">
<p>When you enter the front page, you will find the menu on the left side, where you can choose the <em><strong>Data Wizards</strong></em> under the <em><strong>CREATE</strong></em> section.</p>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="././graphics/data-wizards-list.png" alt="data-wizards-list" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p>The data wizards page shows you all the SAP BAPI&#8217;s that can be used find Function Modules. The Function Modules can then be exposed as services. We refer the reader to the Function Module Feature description for more information about the technical details of Function Modules.</p>
</div>
<div class="sect3">
<h3 id="_search_data_wizards" class="section-headline pb-4">Search Data Wizards</h3>
<div class="paragraph">
<p>You can search for data wizards by using the search function in the upper left corner of the page. If you for example search on <em>‘Business Partner’</em>, you will filter on all Function Modules that are contained within a BAPI with <em>‘Business Partner’</em> in the name or description.</p>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="././graphics/data-wizard-list-searching.png" alt="data-wizard-list-searching" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p>Moreover, you can change the search language in the dropdown menu. The <em>‘Include empty’</em> checkbox allows you to find BAPI&#8217;s that does not contain any Function Modules. They are excluded by default.</p>
</div>
</div>
</div>
<div class="sect2">
<h2 id="_using_the_data_wizard_tree" class="section-headline pb-4">Using the Data Wizard tree</h2>
<div class="paragraph">
<p>The Data Wizard shall be thought of as a tree of folders. Each Folder is a BAPI and it can have none or multiple subfolders. Lastly a folder can have multiple Function Modules that are represented with an  <em>‘ f ’</em> icon. To access a Function Module, you can simply drag or double click the Function Module item in the tree.</p>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="././graphics/data-wizards-add-to-canvas.png" alt="data-wizards-add-to-canvas" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p>To understand how the Function Module that is added to the canvas works, we refer the reader to the Function Module feature description. The canvas part is identical to Function Modules. How to utilize the Function Module in Boomi is also described in the Function Module feature description.</p>
</div>
</div>
</div>
</div>
</div></template>
<script>export default {}; </script>