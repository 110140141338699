<template>
  <div class="subpage-wrapper">
    <div class="header-banner">
      <div class="image-wrapper">
        <v-img alt="Boomi for SAP core" contain src="@/graphics/paper-airplanes.svg" />
      </div>
      <v-container class="content">
        <h1>Getting started</h1>
      </v-container>
    </div>
    <v-container class="content-section">
      <v-card width="100%" class="pa-10 mb-10">
        <GettingStartedWithAxis />
        <v-row class="buttons-wrapper">
          <v-col cols="12" sm="6">
            <div class="text-center">
              <div class="my-8">
                <v-btn
                  x-large
                  color="accent"
                  dark
                  to="/getting-started-with-axis/abap-proxy"
                  >ABAP Proxy</v-btn
                >
                <p class="description">The ABAP Proxy feature enables exposing ABAP Proxies as REST services (in Boomi for SAP core) and sending ABAP Proxy data to REST services (in Boomi).</p>
              </div>
              <div class="my-8">
                <v-btn
                  x-large
                  color="accent"
                  dark
                  to="/getting-started-with-axis/bw-data-extractor"
                  >BW Data Extractor</v-btn
                >
                <p class="description">With BW Data Extractor it is easy to extract data for BW reporting from SAP, allowing the data to be used in Boomi processes</p>
              </div>
              <div class="my-8">
                <v-btn
                  x-large
                  color="accent"
                  dark
                  to="/getting-started-with-axis/data-wizards"
                  >Data Wizards</v-btn
                 >
                 <p class="description">Data wizard content is part of the UI installation and will automatically be updated and maintained by SAP with new releases as it is a visual reflection of native SAP functionality.</p>
              </div>
              <div class="my-8">
                <v-btn
                  x-large
                  color="accent"
                  dark
                  to="/getting-started-with-axis/events"
                  >Events</v-btn
                >
                <p class="description">Boomi for SAP core Events enable SAP Event-Driven Architecture (EDA) by leveraging standard SAP functionalities through Business Object events and Change Pointer events.</p>
              </div>
               <div class="my-8">
                <v-btn
                  x-large
                  color="accent"
                  dark
                  to="/getting-started-with-axis/function-modules"
                  >Function Modules</v-btn
                >
                <p class="description">With Function Module Services, it&#8217;s easy to expose SAP Function Modules and use them in Boomi processes.</p>
              </div>
              <div class="my-8">
                <v-btn
                  x-large
                  color="accent"
                  dark
                  to="/getting-started-with-axis/receiver-framework"
                  >Receiver Framework</v-btn
                >
                <p class="description">The Receiver Framework feature gives you the freedom of sending data wherever you would like in whatever format suits you.</p>
              </div>
            </div>
          </v-col>
          <v-col cols="12" sm="6">
            <div class="text-center">
                <div class="my-8">
                <v-btn
                  x-large
                  color="accent"
                  dark
                  to="/getting-started-with-axis/hyper-automation"
                  >Hyper Automation</v-btn
                >
                <p class="description">Hyper Automation makes it easy to transport objects through SAP clients<br><br></p>
              </div>
                <div class="my-8">
                <v-btn
                  x-large
                  color="accent"
                  dark
                  to="/getting-started-with-axis/idoc"
                  >IDoc</v-btn
                >
                <p class="description">With the IDocs feature, it&#8217;s easy to send and receive SAP IDocs in Boomi processes.<br><br></p>
              </div>
              <div class="my-8">
                <v-btn
                  x-large
                  color="accent"
                  dark
                  to="/getting-started-with-axis/configuration"
                  >RFC Configuration</v-btn
                >
                <p class="description">An SAP RFC Connection is required to send data to REST services in the following features: Events, BW Extractors and Table Service Extracts<br><br></p>
              </div>
              <div class="my-8">
                <v-btn
                  x-large
                  color="accent"
                  dark
                  to="/getting-started-with-axis/table-service"
                  >Table Service</v-btn
                >
                <p class="description">With Table Services, it is easy to expose tables and views from SAP so the data can be used in Boomi processes <br><br></p>
              </div>
               <div class="my-8">
                <v-btn
                  x-large
                  color="accent"
                  dark
                  to="/getting-started-with-axis/table-service-extract"
                  >Table Service Extract</v-btn
                >
                <p class="description">With Table Service Extracts, large amounts of Table Service data can easily be sent to Boomi.</p>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-card>
    </v-container>
  </div>
</template>

<style scoped lang="scss">
.header-banner .image-wrapper ::v-deep .v-image__image--contain {
  height: 650px;
  transform: translate(-530px, -30px) rotate(10deg);
}
.description {
  color: #435563;
  max-width: 70%;
  margin-left: 15%;
  align-self: center;
  margin-top: 2%;
}

</style>

<script>
import Vue from "vue";
import { Component } from "vue-property-decorator";
import GettingStartedWithAxis from "./_index.vue";

@Component({ components: { GettingStartedWithAxis } })
export default class index extends Vue {}
</script>
