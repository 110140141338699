<template>
    <div class="subpage-wrapper">
      <div class="header-banner">
        <div class="image-wrapper">
          <v-img
            alt="Boomi for SAP core"
            contain
            src="@/graphics/birds.svg"
          />
        </div>
        <v-container class="content">
          <h1>Configuration Guide</h1>
        </v-container>
      </div>
      <v-container class="content-section">
        <v-card
          width="100%"
          class="pa-10 mb-10"
        >
        <ConfigurationGuide />
        <div id="faq">
          <h2 class="section-headline pb-4">POC Challenges FAQ</h2>
          <v-expansion-panels popout focusable>
            <v-expansion-panel>
                <v-expansion-panel-header>Connectivity to SAP from Boomi</v-expansion-panel-header>
                <v-expansion-panel-content>
                  <br>
                  <div class="paragraph">
                    <p>Observed error messages when testing the connection in Boomi to SAP:</p>
                  </div>
                  <div class="olist arabic">
                    <ol class="arabic">
                      <li>
                        <p>401: Unauthorized.</p>
                      </li>
                      <li>
                        <p>Unexpected Character: Unexpected character ('&lt;'&#8230;&#8203;)</p>
                      </li>
                    </ol>
                  </div>
                  <div class="paragraph">
                    <p>Both messages have been observed to happen due to misconfiguration of authentication.</p>
                  </div>
                  <div class="paragraph">
                    <p>In case of the "Unexpected Character" error message, Boomi is most likely receiving HTML when it&#8217;s expecting JSON.</p>
                  </div>
                  <div class="paragraph">
                    <p>This can be caused by the SSO set-up expecting interactive log in.</p>
                  </div>
                  <div class="paragraph">
                    <p>The possible solutions below should be attempted one at a time. The solutions are list in order of increasing complexity.</p>
                  </div>
                  <div class="paragraph">
                    <p>If SAP is set-up to use other Logon Procedures than Basic Authentication, they might take precedence in SAP. <a href="https://help.sap.com/doc/329ac769552a411b97bc7adb991b6197/3.0.12/en-US/713a1ed2bb6142b097338abd38dae1ad.html">SAP Documentation of the issue</a>.</p>
                  </div>
                  <div class="paragraph">
                    <p>Try to force SAP to accept Basic authentication by enabled the following options one at a time:</p>
                  </div>
                  <div class="olist arabic">
                    <ol class="arabic">
                      <li>
                        <p>Enable Preemptive authentication</p>
                      </li>
                      <li>
                        <p>Add the following parameter in the Boomi connector: spnego = disabled</p>
                      </li>
                      <li>
                        <p>Add the following parameter in the Boomi connector: saml2 = disabled</p>
                      </li>
                    </ol>
                  </div>
                  <div class="imageblock">
                    <div class="content">
                      <img src="./graphics/boomi-connector-disable-spnego.png" alt="boomi connector disable spnego">
                    </div>
                  </div>
                  <h3 class="section-headline pb-4">Prioritize Authentication Mode in SAP</h3>
                  <p>The authentication type must be set to Basic.</p>
                  <p>Modify the SICF service 'ZBX' in the BR2 system in order to prioritize Basic authentication.</p>
                  <img src="./graphics/service-config.png" alt="service config">
                  <p>Move 'Basic Authentication' option to the top of the list or delete the other options.</p>
                  <img src="./graphics/service-config-order.png" alt="service config order">
                  <p>If none of the resolutions work, perform advanced Connection debugging found below</p>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header>404: Not found Error</v-expansion-panel-header>
                <v-expansion-panel-content>
                  <br>
                  <div class="paragraph">
                    <p>Example of an error message:</p>
                  </div>
                  <div class="listingblock">
                    <div class="content">
                      <pre class="highlight"><code>Connection Failed; Caused by: [404] Not found Error; host/url misconfiguration or mismatch between connector version and backend version.
  Connector version:171-d558055-v1.1-Hanne Backend version: -(-/-)</code></pre>
                    </div>
                  </div>
                  <div class="paragraph">
                    <p>The error message is different depending on the root cause.</p>
                  </div>
                  <div class="paragraph">
                    <p>Error message variants and possible causes:</p>
                  </div>
                  <div class="ulist">
                    <ul>
                      <li>
                        <p>[404] <strong>Extension</strong> Not found Error &#8230;&#8203;</p>
                      <div class="ulist">
                        <ul>
                          <li>
                            <p>Incorrect Boomi for SAP core installation. Does Boomi for SAP core UI work? Open transaction /n/IXBX/ZBX001 or append /zbx to the SAP URL and open it in a browser. If you get an error, verify that Boomi for SAP core is installed correct (e.g., correct SICF handler)</p>
                          </li>
                        </ul>
                      </div>
                      </li>
                      <li>
                        <p>[404] Not found Error &#8230;&#8203; Backend version: <strong>-(-/-)</strong></p>
                      <div class="ulist">
                        <ul>
                          <li>
                            <p>No connection to Boomi for SAP core. If the back end version is blank, -(-/-), Boomi is not connecting to Boomi for SAP core. Check host/port/url.</p>
                          </li>
                        </ul>
                      </div>
                      </li>
                      <li>
                        <p>[404] Not found Error &#8230;&#8203; Backend version: <strong>(NOT blank)</strong>.</p>
                      <div class="ulist">
                        <ul>
                          <li>
                            <p>Incompatible Boomi for SAP core and Boomi for SAP connector versions. Update Boomi for SAP Core and/or the connector</p>
                          </li>
                        </ul>
                      </div>
                      </li>
                    </ul>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header>Advanced Connection Debugging</v-expansion-panel-header>
                <v-expansion-panel-content>
                  <br>
                  <div class="paragraph">
                    <p>(Not possible for Boomi Cloud infrastructure.)</p>
                  </div>
                  <div class="paragraph">
                    <p>To see the raw http traffic between Boomi and SAP, follow these steps:</p>
                  </div>
                  <div class="olist arabic">
                    <ol class="arabic">
                      <li>
                      <p>Enable Connector Trace Logging <a href="https://help.boomi.com/bundle/integration/page/t-atm-Enabling_trace_logging_for_various_connectors.html">Boomi guide</a></p>
                      <div class="olist loweralpha">
                        <ol class="loweralpha" type="a">
                          <li>
                            <p>Requires atom restart.</p>
                          </li>
                        </ol>
                      </div>
                      </li>
                      <li>
                        <p>Make a connection test to SAP with the Boomi for SAP connector.</p>
                      </li>
                      <li>
                        <p>Download the container log and inspect the http traffic.</p>
                      <div class="olist loweralpha">
                        <ol class="loweralpha" type="a">
                          <li>
                            <p>For molecules, the container log of the head node normally contains the data from the connection test.</p>
                          </li>
                        </ol>
                      </div>
                      </li>
                    </ol>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header>Connectivity to Boomi from SAP</v-expansion-panel-header>
                <v-expansion-panel-content>
                  <br>
                  <div class="paragraph">
                    <p>The result of a 'Connection Test' in the SM59 RFC does not have to result in a 200 OK to be successful.</p>
                  </div>
                  <div class="paragraph">
                    <p>The first step should be to look in the Shared Server Logs in Boomi to see if the connection was established.</p>
                  </div>
                  <div class="paragraph">
                    <p>Possibly successful results:</p>
                  </div>
                  <div class="olist arabic">
                    <ol class="arabic">
                      <li>
                        <p>404/405 Not Found/Method not allowed: No Boomi process is deployed to the Atom at the URL with a GET method (Boomi for SAP receiver processes use POST)</p>
                      </li>
                    </ol>
                  </div>
                  <div class="paragraph">
                    <p>Unsuccessful results and their possible solutions:</p>
                  </div>
                  <div class="olist arabic">
                    <ol class="arabic">
                      <li>
                        <p>400 Bad Request</p>
                      <div class="olist loweralpha">
                        <ol class="loweralpha" type="a">
                          <li>
                            <p>Verify that the RFC SSL active/inactive status matches the port and setting in Boomi.</p>
                          </li>
                          <li>
                            <p>E.g., an RFC on port 443 with SSL inactive connecting to an SSL port on a Boomi atom can result in http code 400.</p>
                          </li>
                        </ol>
                      </div>
                      </li>
                      <li>
                        <p>401 Unauthorized</p>
                      <div class="olist loweralpha">
                        <ol class="loweralpha" type="a">
                          <li>
                            <p>Verify that the Boomi web service user name and password used in the RFC are correct.</p>
                          </li>
                          <li>
                            <p>Verify that the Boomi web service user is allowed to call the web service in question (Use Process Filtering section in Boomi)</p>
                          </li>
                          <li>
                            <p>Verify that the Boomi web service user is allowed to call the web service from the SAP IP (IP Filter section in Boomi)</p>
                          </li>
                        </ol>
                      </div>
                      </li>
                    </ol>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
            <br>
            </div>
        </v-card>
      </v-container>
    </div>
  </template>

<style scoped lang="scss">
@import '~vuetify/src/styles/settings/_variables';

</style>

<script>
import Vue from "vue";
import { Component } from "vue-property-decorator";
import ConfigurationGuide from "./_ConfigurationGuide.vue";

@Component({ components: { ConfigurationGuide } })
export default class configurationGuide extends Vue {
  mounted() {
    const tableOfContent = document.getElementById("toc");
    tableOfContent.remove();
    const htmlCollection = document.getElementsByTagName("h2");
    const headers = [...htmlCollection];
    headers.forEach(element => {
      const header = element.innerHTML;
      const firstElement = header.split(" ")[0];
      if (firstElement.charAt(firstElement.length - 1) === ".") {
        element.innerHTML = header
          .split(" ")
          .slice(1)
          .reduce((accumulator, currentvalue) => accumulator + " " + currentvalue);
      }
    });
    const expansionPanelContainer = document.getElementById("expansionPanelContainer");
    expansionPanelContainer.firstElementChild.remove();
    expansionPanelContainer.appendChild(document.getElementById("faq"));
    // removes siblings and children recursively beginning from the element in the argument to the end of the elements from challenges_installation.adoc
    function recursionDelete(current){
      if (current.id === "expansionPanelContainerEnd") {
        return current;
      } else {
        const elementToBeDeleted = current;
        if (current.nextElementSibling) {
          recursionDelete(current.nextElementSibling);
          elementToBeDeleted.remove();
        } else if (current.firstElementChild) {
          recursionDelete(current.firstElementChild);
          elementToBeDeleted.remove();
        }
      }
    }
    const poc = document.getElementById("_poc_challenges");
    recursionDelete(poc.parentElement);
    expansionPanelContainer.parentElement.parentElement.nextElementSibling.remove();
    document.getElementById("_poc_challenges").innerHTML = "POC Challenges FAQ";
    document.getElementById("_sap_installation").remove();

  }
}
</script>