<template><div>
<div class="sect1">
<h2 class="section-headline pb-4" id="_boomi_for_sap">Boomi for SAP</h2>
<div class="sectionbody">
<div class="paragraph">
<p>Boomi for SAP is a product to accelerate SAP integration which utilizes the functionality in Boomi for SAP Core to create your own services and integration scenarios. The product is designed to quickly guide the user to design the OpenAPI services to retrieve SAP data and quickly integrate them via Boomi.</p>
</div>
<div class="paragraph">
<p>Boomi for SAP includes functionality to send SAP Events to Boomi. This has been done to optimize how to react to events inside SAP and distribute these to Boomi. This includes Workflow events, change pointer events and custom defined events. An event framework has been built based on SAP&#8217;s own event framework, which can send messages from SAP to Boomi for specific events and/or when required to do initial load of SAP Data to the developed integrations. Events can be sent as is, assigned payload from table services or metadata from SAP Framework including values before and after changes, user who did the change and where the change was done. All events from Boomi for SAP can be sent directly to Boomi or a Solace connector, and can then be utilized in Boomi to communicate with relevant systems and thereby support an Event Driven Architecture.</p>
</div>
<div class="paragraph">
<p>Boomi for SAP is composed of two parts – Boomi for SAP Core which is a SAP module/application installed on the SAP systems which Boomi for SAP communicates with, and the Boomi for SAP Connector which is a branded connector optimized and UI designed to interact with the services generated by Boomi for SAP Core.</p>
</div>
<div class="sect2">
<h2 id="_boomi_for_sap_core" class="section-headline pb-4">Boomi for SAP Core</h2>
<div class="paragraph">
<p>The Boomi for SAP Core is installed on your SAP System. The application is comprised of UI and the Application.</p>
</div>
<div class="paragraph">
<p>Boomi for SAP Core UI is designed to support Business Analysts, Line of Business users and Data Citizens.  It enables users with familiarity of business data to expose the data without any SAP coding.</p>
</div>
<div class="paragraph">
<p>Boomi for SAP Core Monitoring is targeted for technical SAP users focused on monitoring, creating event jobs, and data for any auditing requirements.</p>
</div>
</div>
<div class="sect2">
<h2 id="_boomi_for_sap_connector" class="section-headline pb-4">Boomi for SAP Connector</h2>
<div class="paragraph">
<p>The Boomi for SAP connector is a branded connector available in your Boomi AtomSphere account listed in the drop down as <em><strong>Boomi for SAP</strong></em>.</p>
</div>
<div class="paragraph">
<p>It can be deployed in an Atom, Molecule, and both public and private Atom Clouds. The deployment of the connector on the public Atom Cloud assumes that your SAP system is accessible outside the network which the SAP system is residing.</p>
</div>
<div class="paragraph">
<p><em>Example Boomi processes are available for all features in the <a href="https://platform.boomi.com/AtomSphere.html#build;processLibrary=2693ee36-9c7a-43bc-bf94-c1fcbadfa1f1">Boomi Process Library</a>.</em></p>
</div>
</div>
</div>
</div>
</div></template>
<script>export default {}; </script>