<template><div>
<div class="sect1">
<h2 class="section-headline pb-4" id="_events">Events</h2>
<div class="sectionbody">
<div class="paragraph">
<p><strong>Intro</strong></p>
</div>
<div class="paragraph">
<p>Entering the Boomi for SAP UI and pressing the 'Events' tab under 'CREATE' will you be redirected to the canvas of our event creation site. Here you have two fundamental options, which is to choose between 'Business Objects' or 'Change Pointers'.</p>
</div>
<div class="paragraph">
<p><strong>You have the following prerequisites:</strong></p>
</div>
<div class="ulist">
<ul>
<li>
<p>Workflow user - To be able to send events from SAP, the Workflow batch user has to be created and be active. Typically it&#8217;s called WF-batch</p>
</li>
<li>
<p>Furthermore the RFC Destination has to be configured.</p>
</li>
</ul>
</div>
<div class="paragraph">
<p><strong>The content of the user guide:</strong></p>
</div>
<div class="ulist">
<ul>
<li>
<p>Business Objects Wizard</p>
</li>
<li>
<p>Reciever and Topic description</p>
</li>
<li>
<p>Change Pointer Wizard</p>
</li>
<li>
<p>SAP Handler</p>
</li>
</ul>
</div>
<div class="sect2">
<h2 id="_events_configuration_for_boomi_for_sap" class="section-headline pb-4">Events configuration for Boomi for SAP</h2>
<div class="paragraph">
<p>The following needs to be activated in SAP to be able to process Change pointers from the Boomi for SAP UI:</p>
</div>
<div class="ulist">
<ul>
<li>
<p>BD50 - Use this transaction to activate Change pointers.</p>
</li>
</ul>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/BD50.png" alt="BD50" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p>The following should be configured when using Business Objects:</p>
</div>
<div class="ulist">
<ul>
<li>
<p>Business Object events</p>
<div class="ulist">
<ul>
<li>
<p>Use transaction <em><strong>SWETYPV</strong></em> and identify the right business object event to send through Boomi for SAP. Receiver call must be set to <em>'method'</em>, using one of the following three classes: /IXBX/CL_EVENT_HANDLER, /IXBX/CL_EVENT_HANDLER_R, /IXBX/CL_EVENT_HANDLER_R_TOPIC.</p>
</li>
</ul>
</div>
</li>
</ul>
</div>
<div class="paragraph">
<p>Usage for the three handlers for Business Object events:</p>
</div>
<div class="ulist">
<ul>
<li>
<p>/IXBX/CL_EVENT_HANDLER - For Batch processing</p>
</li>
<li>
<p>/IXBX/CL_EVENT_HANDLER_R - For Real time processing without path prefix</p>
</li>
<li>
<p>/IXBX/CL_EVENT_HANDLER_R_TOPIC - For Real time processing with /topic path prefixed (e.g. for use with Solace)</p>
</li>
</ul>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/SWETYPV-1.png" alt="SWETYPV-1" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/SWETYPV-2.png" alt="SWETYPV-2" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p>*User exits, example source code below:</p>
</div>
<div class="paragraph">
<p><em>As message type use the one you have created/chosen for this Change pointer</em></p>
</div>
<div class="listingblock">
<div class="content">
<pre class="highlight"><code class="language-abap" data-lang="abap">  CALL FUNCTION 'CHANGE_POINTERS_CREATE_DIRECT'
    EXPORTING
      message_type          = ZBX_EVENT
    TABLES
      t_cp_data             = lt_data
    EXCEPTIONS
      number_range_problems = 1
      OTHERS                = 2.</code></pre>
</div>
</div>
<div class="ulist">
<ul>
<li>
<p>This populates the change pointer so it can be processed by Boomi for SAP subscriptions.</p>
</li>
</ul>
</div>
</div>
</div>
</div>
<div class="sect1">
<h2 class="section-headline pb-4" id="_business_objects">Business Objects</h2>
<div class="sectionbody">
<div class="paragraph">
<p>For Business Objects you have the option to search for Business specific terms which returns you business object events with data corresponding to your request. The list of Business Objects you can choose between are either implemented or not implemented.</p>
</div>
<div class="paragraph">
<p>Be aware that Business Objects needs to be accessible in all clients as they are client dependent.</p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/EDA-business-objects-search.png" alt="EDA-Business-objects-search" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p><strong>The Language function:</strong> Here you can choose which language you prefer have your data displayed. <em>Please note that only objects described for other languages will change.</em></p>
</div>
<div class="paragraph">
<p>For this example you see that we have searched for 'Business Partner' and all the Business Objects relevant for the search is displayed. In this case we choose 'BUS1006' which we also could have directly searched for.</p>
</div>
<div class="paragraph">
<p>When you have chosen which Business Object you want to subscribe an event for, you drag or double click it onto the canvas. You will then see which different types of events you can subscribe to.</p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/EDA-business-objects-events.png" alt="EDA-Business-objects-events" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p>The symbols indicate if the event already have been implemented in the SAP backend, the 'lightning' indicates it is ready for realtime processing and the 'stop watch' indicates it is ready for batch processing. You can still create your subscription even if the event haven&#8217;t been implemented in SAP backend, this can be done after finalizing the subscription.</p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/EDA-business-objects-realtime-and-batch.png" alt="EDA-Business-objects-realtime-and-batch" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p><strong>Deployment mode:</strong> Deployment mode indicates whether the service created is transportable. By selecting <em>Production</em> the service can be transported, meaning that you must select a transport for the service before the service can be created. By selecting <em>Local</em> the service created can not be transported, and therefore no transport can be selected.</p>
</div>
<div class="paragraph">
<p><strong>Boomi for SAP Best Practice:</strong></p>
</div>
<div class="exampleblock">
<div class="content">
 <em>Subscriptions for events can not change deployment mode. The reason for this is that you store your subscription in a transport for production and not for local. Therefore if you would like to change the deployment mode, you need to recreate your subscription.</em>
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/EDA-business-objects-deployment-mode.png" alt="EDA-Business-objects-deployment-mode" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p>The receiver defines the connection of your event. You have the choice of choosing a reciever you already have created, og creating a new one.</p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/EDA-business-objects-empty-reciever.png" alt="EDA-Business-objects-empty-reciever" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p>When creating a new receiver you have the following to answer:</p>
</div>
<div class="paragraph">
<p><strong>Receiver ID:</strong> An ID of your choice</p>
</div>
<div class="paragraph">
<p><strong>Receiver Type:</strong> The connection type (RFC only)</p>
</div>
<div class="paragraph">
<p><strong>RFC Connection:</strong> Choose one from the list of connection available in SAP.</p>
</div>
<div class="paragraph">
<p>Further information about the receiver will follow in the "Receiver and Topic" section.</p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/EDA-business-objects-creating-reciever.png" alt="EDA-Business-objects-creating-reciever" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p>The subscription is where you choose and define what you want to get as output.</p>
</div>
<div class="paragraph">
<p>Events have a small amount of metadata on them, usually describing origins of the event. It is possible to add an event filter that enforces certain criteria to be met for the event to be sent processed.</p>
</div>
<div class="paragraph">
<p>For an in-depth description of how Boomi for SAP handles business object events and filtering, please refer to Appendix(available in bottom of this page).</p>
</div>
<div class="sect2">
<h2 id="_event_only_and_metadata" class="section-headline pb-4">Event only and Metadata</h2>
<div class="paragraph">
<p><em>To see user guide for 'Payload' please go to the next section.</em></p>
</div>
<div class="paragraph">
<p><strong>Bundle Size</strong> = How many events to process at a time. If messages go to different URL&#8217;s, then they will be send as groups, with bundle size dictating the total processing size.</p>
</div>
<div class="paragraph">
<p><strong>Notes when using Bundle size</strong> = If a topic with dynamic path is used, then it will generate different topics/paths for the events in the processing. During processing, the events in the bundle are split into the relevant topics before they are sent individually.</p>
</div>
<div class="paragraph">
<p>If a payload filter is applied, then only events of the same CD object and table can be processed together. This logic is handled after splitting into bundles therefore additional sending of events can occur. But keep in mind that the events is processed in order, so the events of the same CD Object/Table will have to be in sequence otherwise it is not bundled.</p>
</div>
<div class="paragraph">
<p><strong>Event Only</strong> = Only an ID will be send as output</p>
</div>
<div class="paragraph">
<p><strong>Event - Metadata</strong> = Sends output of data before and after the change.</p>
</div>
<div class="paragraph">
<p><strong>Add Event Filters</strong> = It is possible to add an event filter, which can take metadata from the event itself, and determine if the event is to be handled at all. A filter can look at the table key(s) in change pointer events and or the fields from a business object event, as to determine if an event should be processed. For an in-depth description of how Boomi for SAP handles business object events and payload filtering, please refer to the Appendix(available in bottom of this page).</p>
</div>
<div class="paragraph">
<p>The subscriptions can be created without a topic.</p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/EDA-business-objects-subscription.png" alt="EDA-Business-objects-subscription" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p>For Topic you have the option of choosing between already existing Topics or Create a new Topic.</p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/EDA-business-objects-topic.png" alt="EDA-Business-objects-topic" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p>When creating a new Topic you must create an ID of your choice, furthermore you have the option of making a description to your Topic. At Topic position for 'Event only and Metadata' you have the options of choosing between a <strong>static text</strong> or give the position a <strong>system variable</strong>.</p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/EDA-business-objects-create-topic-filled.png" alt="EDA-Business-objects-create-topic-filled" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
</div>
<div class="sect2">
<h2 id="_event_payload" class="section-headline pb-4">Event - Payload</h2>
<div class="paragraph">
<p>Event Payload gives you the possibility of enabling table extraction with filtering based on the event key.</p>
</div>
<div class="paragraph">
<p>When subscribing to a Payload you must choose a 'Table Service'. Furthermore you need to identify which key field(s) to map your payload to.</p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/EDA-business-objects-subscription-payload.png" alt="EDA-Business-objects-subscription-payload" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p>When you have chosen Event Payload for subscription, you must select which 'Table Service' you want to receive your payload from. When chosen you must determine the value of your topic to define where you would like your data from. This can be E.G. used in an Event Mesh to route messages without looking at the payload data.</p>
</div>
<div class="paragraph">
<p><em>Note that when using a table service for subscription payload the authorization check is not performed.</em></p>
</div>
<div class="paragraph">
<p><strong>Payload filtering</strong> = Before the event and payload is sent out from SAP, it is possible to add a filter, forcing the selected payload data to adhere to specific requirements.  For an in-depth description of how Boomi for SAP handles business object events and payload filtering, please refer to the Appendix(available in bottom of this page).</p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/EDA-business-objects-create-topic-payload.png" alt="EDA-Business-objects-create-topic-payload" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p>When you have created your subscription you are able to see all of your created event components under 'List and Manage', as shown on the pictures both Receiver, Subscription and Topic can be seen, edited and deleted from this page.</p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/EDA-business-objects-LM-sub.png" alt="EDA-Business-objects-LM-sub" />
</div>
</div>
</div>
</div>
</div>
<div class="sect1">
<h2 class="section-headline pb-4" id="_receiver_and_topic">Receiver and Topic</h2>
<div class="sectionbody">
<div class="paragraph">
<p>In the session you will find a deeper dive into the functionality of the Receiver and Topic.</p>
</div>
<div class="paragraph">
<p>Both of these components are independently made and can therefore be reused for multiple subscription. This makes your event management more streamlined and less heavy.</p>
</div>
<div class="paragraph">
<p>To create either a 'Receiver' or 'Topic' independently, press the the three dots on the 'Events' tap under 'CREATE' and choose which you would like.</p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/EDA-R&T-canvas.png" alt="EDA-R&amp;T-canvas" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="sect2">
<h2 id="_receiver" class="section-headline pb-4">Receiver</h2>
<div class="paragraph">
<p>For release 1.6 the Receiver Framework was created, which enables way more functionality around what receivers you can send to. Please have a look under "Getting Started - Receiver Framework" to find more about this.</p>
</div>
<div class="paragraph">
<p>Be aware that the connections are required to have the same name across all systems.</p>
</div>
<div class="paragraph">
<p>The receiver is independent so that it is usable for multiple subscriptions and topics. e.g. is that you can use a connection to Boomi for multiple subscriptions to Boomi events.</p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/EDA-RandT-create-receiver-filled.png" alt="EDA-RandT-create-receiver-filled" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
</div>
<div class="sect2">
<h2 id="_topic" class="section-headline pb-4">Topic</h2>
<div class="paragraph">
<p>Topic is also available to be reused for more subscriptions. This is meant to make it easier for you when you want to streamline your maintenance of your subscriptions.</p>
</div>
<div class="paragraph">
<p>Positions types:</p>
</div>
<div class="paragraph">
<p><strong>VAL - Value:</strong> A reference to the data that the subscription makes available, which
allows you to reference data from the event payload. Please note that there are no requirements for using the same table service for topic, that you have used at the subscription. E.G. the Topic could be for a single table where the subscription is using a view that contains this table.</p>
</div>
<div class="paragraph">
<p>If the topic value reference is not available in the subscription payload then no value will be generated as the position output.</p>
</div>
<div class="paragraph">
<p><strong>SYS - System Fields:</strong> Available during program execution.
All system fields that are not described as obsolete are available.
The most logical candidates for use are: SYSID, DATUM, UZEIT, MANDT, UNAME.</p>
</div>
<div class="paragraph">
<p>SYSID - is the name of the SAP system</p>
</div>
<div class="paragraph">
<p>DATUM - current date of the application server</p>
</div>
<div class="paragraph">
<p>UZEIT - current time of the application server</p>
</div>
<div class="paragraph">
<p>MANDT - Client ID of the current user.</p>
</div>
<div class="paragraph">
<p>UNAME - Name of current user</p>
</div>
<div class="paragraph">
<p><strong>TXT - text:</strong> Used for static text output.</p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/EDA-R&T-create-topic-filled.png" alt="EDA-R&amp;T-create-topic-filled" />
</div>
</div>
</div>
</div>
</div>
<div class="sect1">
<h2 class="section-headline pb-4" id="_change_pointers">Change Pointers</h2>
<div class="sectionbody">
<div class="paragraph">
<p>Using Change Pointers you have a range of different opportunities compared to Business Objects. For Change Pointers you can specify your event to a message type in SAP, which let you get information about changes in other type of business specific data. In this example the message type 'MATMAS'(Material Master) will be used to show how you can
<strong>Subscribe to every MATMAS</strong>.</p>
</div>
<div class="paragraph">
<p>Be aware that Change Pointers needs to be accessible in all clients as they are client dependent.</p>
</div>
<div class="paragraph">
<p>Start by choosing 'CHG POINT' in the top of your screen. Now you are able to search for name og description.</p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/EDA-change-pointer-canvas.png" alt="EDA-change-pointer-canvas" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p>When searching for the Change Pointer you would like to create an Event on, you have the following options:</p>
</div>
<div class="ulist">
<ul>
<li>
<p><strong>Change Pointer Name</strong> =  If you have the exact name of the object or table you are searching for, then use this field for searching.</p>
</li>
<li>
<p><strong>Description</strong> = If you are searching for the Change Pointers containments.</p>
</li>
<li>
<p><strong>Table</strong> = If you would like to search for all the Change Pointers containing a certain table</p>
</li>
<li>
<p><strong>Field</strong> = If you would like to search for a description of a field in the change pointer, then use this field for searching.</p>
</li>
<li>
<p><strong>Strict Search</strong> = Here you have the option of only getting a specific wish, but bear in mind that it needs to be spelled correct and is case sensitive.</p>
</li>
</ul>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/EDA-change-pointer-search.png" alt="EDA-R&amp;T-create-receiver" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p>When you have found the Change Pointer you want to process you are able to see the status of it.</p>
</div>
<div class="ulist">
<ul>
<li>
<p><strong>Red cross:</strong> If the change Pointer has a Red Cross assigned to it, that means is not active in SAP.</p>
</li>
<li>
<p><strong>Green Checkmark:</strong> Means it has been activated in SAP.</p>
</li>
<li>
<p><strong>'ACTIVE' batch:</strong> It has been activated for Boomi for SAP and it is now ready for processing.</p>
</li>
</ul>
</div>
<div class="paragraph">
<p>To activate it for Boomi for SAP, simply drag it to the canvas and give it a name,description(optional), choose a deployment mode and you are good to go.</p>
</div>
<div class="paragraph">
<p><strong>Boomi for SAP active</strong> means that Boomi for SAP event prossing application is allowed to process these change pointer. This has been chosen as a safety to insure that this application can&#8217;t process change pointer that aren&#8217;t relevant for Boomi for SAP.</p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/EDA-change-pointer-activation.png" alt="EDA-R&amp;T-create-receiver" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p>In your side panel you are now able to see that 'MATMAS' has received the 'ACTIVE' sign. Next you will see a list of all the components the given change pointer contains. When you have confirmed that the information&#8217;s corresponds with your wishes, press continue.</p>
</div>
<div class="paragraph">
<p>From here on you can follow the process as described in the Business Objects section.</p>
</div>
<div class="paragraph">
<p>For an in-depth description of how Boomi for SAP handles change pointer events and filtering, please refer to Appendix(available in bottom of this page).</p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/EDA-change-pointer-event-canvas.png" alt="EDA-R&amp;T-create-receiver" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="sect2">
<h2 id="_list_and_manage" class="section-headline pb-4">List and Manage</h2>
<div class="paragraph">
<p>Under <strong>List and Manage</strong> you are able to see a list of all the Business Objects and Change Pointers you have marked as Boomi for SAP relevant and therefore can process. <strong>You are able to filter on all parameters with standard options available.</strong></p>
</div>
<div class="paragraph">
<p>If these Change Pointers isn&#8217;t meant for more Boomi for SAP processing then it is a recommendation to deactivate them. Please note that when deactivating, the actual activation of a change pointer happens in SAP, since it is only the Boomi for SAP relevant you deactivate.</p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/EDA-change-pointer-LM.png" alt="EDA-R&amp;T-create-receiver" />
</div>
</div>
</div>
</div>
</div>
<div class="sect1">
<h2 class="section-headline pb-4" id="_sap_event_processing">SAP Event Processing</h2>
<div class="sectionbody">
<div class="sect2">
<h2 id="_process_eda_subscriptions" class="section-headline pb-4">Process EDA subscriptions</h2>
<div class="paragraph">
<p><em>Transaction: /IXBX/ZBX011 or /IXBX/EVENT_PROCESS</em></p>
</div>
<div class="paragraph">
<p><strong>Subscription filter:</strong></p>
</div>
<div class="paragraph">
<p>This filter controls which subscriptions are processed during execution. All relevant subscriptions for the new events are updated in the Boomi for SAP dispatch layer independent of the filtering.</p>
</div>
<div class="paragraph">
<p><strong>Receiver filter:</strong></p>
</div>
<div class="paragraph">
<p>This filter controls which receivers are processed during this execution. All relevant receivers for the new events are updated in the Boomi for SAP dispatch layer independent of the filtering.</p>
</div>
<div class="paragraph">
<p><strong>Destination data:</strong></p>
</div>
<div class="paragraph">
<p>This is a prefix to the path generated by the topic. E.g. <em>/TOPIC</em>. Please note that the generated topic path has a leading <strong>/</strong> therefore no trailing <strong>/</strong> is needed here.</p>
</div>
<div class="paragraph">
<p><br></p>
</div>
</div>
<div class="sect2">
<h2 id="_processing_options" class="section-headline pb-4">Processing options</h2>
<div class="paragraph">
<p><strong>Incl. custom Change Pointers:</strong> Process Change Pointer subscriptions. When selected a new select option is available.</p>
</div>
<div class="paragraph">
<p><strong>Change Pointer filtering:</strong> This is a filtering of which message types you want to process, and it is based on those who have been marked as Boomi for SAP relevant.</p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/EDA-SAP-processing-with-CP.png" alt="EDA-SAP-processing-empty-canvas" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p><strong>Include new events:</strong> Process Change Pointer subscriptions only for new events in SAP.
<br></p>
</div>
<div class="paragraph">
<p><strong>Incl. custom Business Events:</strong> Process Boomi for SAP Business Events. (Boomi for SAP business events are events handled by handlers based on the <em>/IXBX/BI_EVENT_HANDLER_STATIC</em> interface. Only one realtime handler can be processed per objecttype/eventtype at a time. )</p>
</div>
<div class="paragraph">
<p><strong>Process batch events:</strong> Batch processing of events for batch subscriptions.</p>
</div>
<div class="paragraph">
<p><strong>Reproc failed real time events:</strong> Reprocess real time events which failed during original processing. Please note that the topic prefix used for real time <em>/TOPIC</em> is replaced with Path prefix provided here.</p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/EDA-SAP-processing-empty-canvas.png" alt="EDA-SAP-processing-empty-canvas" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p><strong>Max events:</strong> Number of event for processing</p>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p><strong>Include external value fields:</strong> Extend output data with external format values</p>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p><strong>Max Threads for parallel proc.</strong>: Max threads can be allocated for parallel subscriptions processing.</p>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p><strong>Server group:</strong> Server group for parallel subscriptions processing. Can be chosen from the list.</p>
</div>
<div class="paragraph">
<p><br></p>
</div>
</div>
<div class="sect2">
<h2 id="_process_event_clean_up" class="section-headline pb-4">Process Event Clean Up</h2>
<div class="paragraph">
<p><em>Transaction: /IXBX/ZBX012 or /IXBX/EVENT_CLEAN_UP</em></p>
</div>
<div class="paragraph">
<p>This transaction must be scheduled to run periodically as a clean up process to remove the events for which all receivers has been successfully processed. Can be compared to the clean up process for SAP standard change pointer events. If Deletion of obsolete EDA event dispatch data is executed with 'Delete older than (days)' = 0, the main event is deleted directly and not updated for deletion for the next deletion run.</p>
</div>
<div class="paragraph">
<p>When running the event clean up, something happens</p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/EDA-SAP-processing-delete.png" alt="EDA-SAP-processing-empty-canvas" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
</div>
<div class="sect2">
<h2 id="_process_dispatch_layer_clean_up" class="section-headline pb-4">Process Dispatch Layer Clean Up</h2>
<div class="paragraph">
<p><em>Transaction: /IXBX/ZBX013 - /IXBX/DISPATCH_CLEAN_UP</em></p>
</div>
<div class="paragraph">
<p>This transaction allows the removal of data generated for the dispatch layer which for some reason should not be sent. This can be due to a deletion of a Subscriber or Receiver, or for Change Pointer messages types for Boomi for SAP settings.</p>
</div>
<div class="sect3">
<h3 id="_parameters" class="section-headline pb-4">Parameters</h3>
<div class="paragraph">
<p><strong>Test:</strong> This allows for an overview of how many records are found for the filtering criteria. Table <em>/IXBX/BDCP2_LINK</em> can be analyzed via SAP standard transactions e.g. <em>SE16</em> for details.</p>
</div>
<div class="paragraph">
<p><strong>Message Type:</strong> Filtering on the message type the event is created for. For Boomi for SAP business event the message type used is : <em>/IXBX/ZBX_EVENT</em></p>
</div>
<div class="paragraph">
<p><strong>Subscription ID:</strong> Allows filtering on specific Subscriptions</p>
</div>
<div class="paragraph">
<p><strong>Receiver ID:</strong> Allows filtering on specific Receivers</p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/EDA-SAP-processing-dispatch-layer.png" alt="EDA-SAP-processing-empty-canvas" />
</div>
</div>
</div>
</div>
</div>
</div>
</div></template>
<script>export default {}; </script>