<template>
  <div class="subpage-wrapper">
    <div class="header-banner">
      <div class="image-wrapper">
        <v-img
          alt="Boomi for SAP"
          contain
          src="@/graphics/hands-globe.svg"
        />
      </div>
      <v-container class="content">
        <h1>{{ pageHeaderText }}</h1>
        <h3>{{ pageSubheadline }}</h3>
      </v-container>
    </div>
    <v-container class="content-section">
      <v-card width="100%" class="pa-10 mb-10">
        <DataWizards />

        <v-row class="buttons-wrapper" align="center">
          <v-col cols="12" sm="12">
            <div class="text-center">
              <div class="my-8">
                <v-btn
                  x-large
                  color="accent"
                  dark
                  to="/getting-started-with-axis/data-wizards/user-guide"
                >Data Wizard User Guide</v-btn>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import Vue from "vue";
import { Component } from "vue-property-decorator";
import DataWizards from "./_index.vue";

@Component({ components: { DataWizards } })
export default class index extends Vue {
  pageHeaderText = "";
  pageSubheadline = "";
  mounted() {
    const $pageHeader = document.getElementById("_data_wizard");
    const $pageSubheader = document.getElementsByClassName("paragraph")[1];
    $pageHeader.classList.add("hidden-content");
    $pageSubheader.classList.add("hidden-content");
    this.pageHeaderText = $pageHeader.innerText;
    this.pageSubheadline = $pageSubheader.innerText;
  }
}
</script>

<style scoped lang="scss">
.header-banner .image-wrapper ::v-deep .v-image__image--contain{
  height: 500px;
  transform: translate(-470px, -160px);
}
</style>