<template><div>
<div class="sect1">
<h2 class="section-headline pb-4" id="_abap_proxy">ABAP Proxy</h2>
<div class="sectionbody">
<div class="paragraph">
<p>ABAP Proxy is used as an Interface to transfer or receive from or to the SAP system. ABAP Proxy is using the well-known and widely used standards such as HTTP and SOAP.</p>
</div>
<div class="paragraph">
<p>It is important to note that no additional custom code is needed to make the ABAP Proxies work. We simply hook into the proxy framework as would SAP PO. This results in a correct execution of the proxy framework pipeline.</p>
</div>
<div class="sect2">
<h2 id="_abap_proxy_using_boomi_for_sap" class="section-headline pb-4">ABAP Proxy using Boomi for SAP</h2>
<div class="paragraph">
<p>ABAP Proxy is used as an Interface to transfer or receive from or to the SAP system. The goal of using ABAP Proxy by Boomi for SAP is to change middleware without disrupting the SAP Proxy framework pipeline.</p>
</div>
<div class="sect3">
<h3 id="_technical_information" class="section-headline pb-4">Technical Information</h3>
<div class="ulist">
<ul>
<li>
<p>Communication is handled using HTTP as transfer protocol and XI 3.0 as message protocol.</p>
</li>
<li>
<p>In SAP PO this is handled using the “SOAP Adapter”.</p>
</li>
<li>
<p>The message is sent with content-type “multipart/related” when using SAP PO.</p>
</li>
<li>
<p>Multipart messages opens the possibility to send 0..N attachments along with the actual "MainDocument".</p>
</li>
<li>
<p>Both asynchronous (EO / EOIO) and synchronous (BE) communication are supported.</p>
</li>
<li>
<p>All data delivered and received from the proxy framework is always expected to be XML, which underlines the use of SOAP. Any other format will not be accepted in the pipeline. For any other format to be used would require some pre- or post processing steps to be implemented.</p>
</li>
</ul>
</div>
</div>
<div class="sect3">
<h3 id="_important_notes" class="section-headline pb-4">Important Notes</h3>
<div class="ulist">
<ul>
<li>
<p>ABAP Proxy by Boomi for SAP only makes communication using proxy in- and outbound possible. It is only the technical connection that is covered here.</p>
</li>
<li>
<p>Only reuse of existing proxy classes is possible in ABAP Proxy by Boomi for SAP. New proxies or modification, of any form, is not supported! Creation or modification of proxies requires a SAP PO repository with metadata to work.</p>
</li>
<li>
<p>The entire SAP PO pipeline will not be covered here. Meaning that the transformation of the message, handling of attachments, mappings etc. will be seen as a prerequisite to working with this.</p>
</li>
</ul>
</div>
</div>
</div>
<div class="sect2">
<h2 id="_sap_configuration" class="section-headline pb-4">SAP configuration</h2>
<div class="sect3">
<h3 id="_outbound_communication" class="section-headline pb-4">Outbound Communication</h3>
<div class="paragraph">
<p>To start out you have two options. Default setup is that SAP PO is configured to be the receiver of any requests from the proxy framework. To change this a change needs to be done in either “SM59” or “SXMB_ADMIN”. A new HTTP endpoint needs to be set for the new middleware. For this example "SXMB_ADMIN" is used.</p>
</div>
<div class="paragraph">
<p>When entered SXMB_ADMIN choose "Integration Engine Configuration".</p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/SXMB_ADMIN.png" alt="SXMB_ADMIN" />
</div>
</div>
<div class="paragraph">
<p>Here you should choose which Role of Business System you would like to use and provide the HTTP endpoint for your communication to hit.</p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/Integration_engine_configuration.png" alt="Integration_engine_configuration" />
</div>
</div>
<div class="paragraph">
<p>All messages sent- or received is monitored using the transaction “SXI_MONITOR”.</p>
</div>
<div class="paragraph">
<p>Be aware that when using asynchronous communication the proxy framework expects an “Acknowledgement” returned. This is provided by default when using SAP PO and is handled in the SOAP adapter during “handshake”. When the middleware is changed, that responsibility is now moved here.</p>
</div>
<div class="paragraph">
<p>This is not a problem when using synchronous communication as the proxy framework just waits for the receiving system to send a response (This answer must of course comply with the XI 3.0 standard used for proxy communication)</p>
</div>
</div>
<div class="sect3">
<h3 id="_inbound_communication" class="section-headline pb-4">Inbound Communication</h3>
<div class="paragraph">
<p>For inbound communication to proxy, an ICF service is exposed at path “/sap/xi/engine”.</p>
</div>
<div class="paragraph">
<p>The following url is an example of an HTTP endpoint to use the proxy ICF service: “http://&lt;host&gt;:&lt;port&gt;/sap/xi/engine?type=entry”</p>
</div>
<div class="paragraph">
<p>This is the single point of entry for inbound proxy. The message header will determine the routing to the correct proxy class where the payload will be parsed, using XSLT, from XML to SAP DDIC format and finally processed.</p>
</div>
</div>
</div>
<div class="sect2">
<h2 id="_boomi_for_sap_ui" class="section-headline pb-4">Boomi for SAP UI</h2>
<div class="paragraph">
<p>In the Boomi for SAP UI you can see all the Proxies already created. You have some key information listed for you to use when sending inbound and outbound messages. You are able to filter on all parameters, which are the following:</p>
</div>
<div class="paragraph">
<p><strong>Object Name</strong> = Name of implementing class or interface</p>
</div>
<div class="paragraph">
<p><strong>IFR Name</strong> = Name of the Service interface</p>
</div>
<div class="paragraph">
<p><strong>IFR NSPCE</strong> = Service interface Namespace</p>
</div>
<div class="paragraph">
<p><strong>Direction</strong> = Either inbound or outbound, indicating which way the communication is.</p>
</div>
<div class="paragraph">
<p><strong>Documentation</strong> = Contains the generated template (multipart) for a test payload for each proxy in order to test integration brookers. (only for Inbound proxies)</p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/proxy-ui.png" alt="proxy-ui.png" />
</div>
</div>
<div class="sect3">
<h3 id="_proxy_test_payload" class="section-headline pb-4">Proxy test payload</h3>
<div class="paragraph">
<p>When the 'View documentation' button is clicked, a dialog box appears with the test payload of the specific proxy. The template can also be copied by clicking the button in the right corner. <strong>Important to mention that it is just a test payload template, therefore Receiver and Sender information have to be changed accordingly</strong>.</p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/proxy_template.png" alt="proxy-template" />
</div>
</div>
</div>
</div>
<div class="sect2">
<h2 id="_boomi_atomsphere_process" class="section-headline pb-4">Boomi AtomSphere Process</h2>
<div class="paragraph">
<p>Boomi for SAP Core and Boomi communicates in two different ways depending on which system initiates the communication.</p>
</div>
<div class="paragraph">
<p><strong>Send data from Boomi to ABAP Proxy</strong></p>
</div>
<div class="paragraph">
<p>Boomi connects to ABAP Proxies using The Boomi for SAP Connector.</p>
</div>
<div class="paragraph">
<p><strong>Browsing For the proxy</strong></p>
</div>
<div class="paragraph">
<p>The Boomi for SAP connector supports browsing for ABAP proxies using the import wizard. It is possible to use a filter on the name of the proxy. If no filter is applied the import wizard will display the first 500 hits. The user can then select the proxy needed and the connector will generate the request and response profile of the proxy if the user is running Boomi for SAP 1.6.
If the user is running Boomi for SAP 1.5 or earlier the request and the response profiles generated might not be the correct ones in which case a URL where the XSD can be downloaded will be displayed so the user manually can upload the correct profile.</p>
</div>
<div class="paragraph">
<p><strong>Manually downloading the XSD</strong></p>
</div>
<div class="paragraph">
<p>To download the XSD copy the URL and open a new tab in your browser. Paste the URL and right-click on the page and select “view page source”. Press CTRL+S to save the XSD, the file name should end with .xsd and the type should be Webpage, HTML Only (*.html;*.htm).</p>
</div>
<div class="paragraph">
<p><strong>Dynamic Properties</strong></p>
</div>
<div class="paragraph">
<p>When using the ABAP_PROXY_SEND operation remember to set the mandatory properties using the set properties shape. If you’re running Boomi for SAP 1.6 only ProxyReceiverService is mandatory, but if you’re running 1.5 or earlier both ProxyReceiverService and ProxyQualityOfService are mandatory.
ProxyQualityOfService:
Should be BestEffort for synchronous proxies and ExactlyOnce for asynchronous proxies.
ProxyReceiverService:
This should be set to [sId]_[sapClientId] of the receiving system.
In addition to the two mandatory document properties, it is also possible for the client to set the following properties:</p>
</div>
<div class="paragraph">
<p><strong>ProxyPreBodyMultipartOverwrite:</strong>
Used for overwriting the multipart that comes before the message body.</p>
</div>
<div class="paragraph">
<p><strong>ProxyPostBodyMultipartOverwrite:</strong>
Used for overwriting the multipart that comes after the message body.</p>
</div>
<div class="paragraph">
<p><strong>ProxySenderService:</strong>
Used for overwriting the sender service property of the multipart. It is set to be Boomi by default.</p>
</div>
<div class="paragraph">
<p><strong>Send</strong></p>
</div>
<div class="paragraph">
<p>Once the operation is set up with the correct request and response profiles and the mandatory properties, you are ready to send to the proxy.
In the connector shape go to the parameters tab and press the + to add a value to an element of the message.</p>
</div>
<div class="paragraph">
<p><strong>Receive ABAP Proxy data in Boomi from SAP</strong></p>
</div>
<div class="paragraph">
<p>REST services are created in Boomi using the standard Boomi Web Services component. Boomi for SAP Core is configured to send ABAP Proxy data to the Boomi REST service.</p>
</div>
</div>
</div>
</div>
</div></template>
<script>export default {}; </script>