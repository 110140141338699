<template><div>
<div class="sect1">
<h2 class="section-headline pb-4" id="_data_wizard">Data Wizard</h2>
<div class="sectionbody">
<div class="paragraph">
<p>Data Wizards are based on SAP&#8217;s own integrated BAPIs (Business API) which is a collection of maintained common business oriented API&#8217;s. Each BAPI consists of function modules that concern a specific business domain or business object. E.g., it is possible to get the address of a certain business partner. Data Wizards exposes the full functionality to Boomi for SAP users through an easy navigatable list on our interactive canvas.</p>
</div>
<div class="paragraph">
<p>Data wizard content is part of the UI installation and will automatically be updated and maintained by SAP with new releases as it is a visual reflection of native SAP functionality.</p>
</div>
</div>
</div>
</div></template>
<script>export default {}; </script>