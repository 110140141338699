<template><div>
<div class="sect1">
<h2 class="section-headline pb-4" id="_hyper_automation">Hyper Automation</h2>
<div class="sectionbody">
<div class="paragraph">
<p>Hyper Automation makes it easy to transport objects through SAP clients</p>
</div>
<div class="paragraph">
<p>Hyper Automations makes transports easy to create and add objects to. Through transaction <strong>/IXBX/hyper_auto</strong> Hyper Automation let&#8217;s the user easily exploits following features:</p>
</div>
<div class="ulist">
<ul>
<li>
<p>All services gathered in one place</p>
</li>
<li>
<p>Easy accessibility to all Boomi for SAP activated objects and possibility to add them all to one transport.</p>
</li>
<li>
<p>Automatic population of related objects to the transport. An example being, if subscriptions are chosen related tables and/or topics etc. are transfered without the user choosing them</p>
</li>
</ul>
</div>
</div>
</div>
</div></template>
<script>export default {}; </script>