<template>
    <div class="subpage-wrapper">
      <div class="header-banner">
        <div class="image-wrapper">
          <v-img
            alt="Boomi for SAP"
            contain
            src="@/graphics/birds.svg"
          />
        </div>
    <v-container class="content">
        <h1>Features</h1>
        </v-container>
      </div>
      <v-container class="content-section">
        <v-card
          width="100%"
          class="pa-10 mb-10"
        >
        <h2 class="feature-headline">Table Services</h2>
        <br>
        <v-expansion-panels popout focusable>
            <v-expansion-panel>
                <v-expansion-panel-header>Creating a Table Service</v-expansion-panel-header>
                <v-expansion-panel-content>
                    <div class="video-wrapper">
                    <video width="100%" controls>
                    <source src="../assets/LMSVideos/TableService/Creating a Table Service.mp4" type="video/mp4">
                    Sorry, your browser doesn't support embedded videos.
                    </video>
                    </div>
                </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
                <v-expansion-panel-header>Querying the Table Service</v-expansion-panel-header>
                <v-expansion-panel-content>
                    <div class="video-wrapper">
                    <video width="100%" controls>
                    <source src="../assets/LMSVideos/TableService/Table Service Boomi Query.mp4" type="video/mp4">
                    Sorry, your browser doesn't support embedded videos.
                    </video>
                    </div>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
        <br>
        <br>
        <h2 class="feature-headline">Table Services Extracts</h2>
        <br>
        <v-expansion-panels popout focusable>
            <v-expansion-panel>
                <v-expansion-panel-header>Table Service Extract Receiver Process</v-expansion-panel-header>
                <v-expansion-panel-content>
                    <div class="video-wrapper">
                    <video width="100%" controls>
                    <source src="../assets/LMSVideos/TableServiceExtracts/Table Service Extract Receiver Process.mp4" type="video/mp4">
                    Sorry, your browser doesn't support embedded videos.
                    </video>
                    </div>
                </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
                <v-expansion-panel-header>Send Table Service Extract to Boomi AtomSphere</v-expansion-panel-header>
                <v-expansion-panel-content>
                    <div class="video-wrapper">
                    <video width="100%" controls>
                    <source src="../assets/LMSVideos/TableServiceExtracts/Send Table Service Extracts to Boomi Atomsphere.mp4" type="video/mp4">
                    Sorry, your browser doesn't support embedded videos.
                    </video>
                    </div>
                </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
                <v-expansion-panel-header>Table Service Extract – Data Received in Boomi AtomSphere</v-expansion-panel-header>
                <v-expansion-panel-content>
                    <div class="video-wrapper">
                    <video width="100%" controls>
                    <source src="../assets/LMSVideos/TableServiceExtracts/Table Service Extract – Data Received in Boomi AtomSphere.mp4" type="video/mp4">
                    Sorry, your browser doesn't support embedded videos.
                    </video>
                    </div>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
        <br>
        <br>
        <h2 class="feature-headline">Events</h2>
        <br>
        <v-expansion-panels popout focusable>
            <v-expansion-panel>
                <v-expansion-panel-header>Activation of Business Events in SAP</v-expansion-panel-header>
                <v-expansion-panel-content>
                    <div class="video-wrapper">
                    <video width="100%" controls>
                    <source src="../assets/LMSVideos/Events/Activation of Business Events in SAP.mp4" type="video/mp4">
                    Sorry, your browser doesn't support embedded videos.
                    </video>
                    </div>
                </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
                <v-expansion-panel-header>Subscription of a Business Object Event in the Boomi for SAP core UI</v-expansion-panel-header>
                <v-expansion-panel-content>
                    <div class="video-wrapper">
                    <video width="100%" controls>
                    <source src="../assets/LMSVideos/Events/Subscription of a Business Object Event in the aXis for SAP UI.mp4" type="video/mp4">
                    Sorry, your browser doesn't support embedded videos.
                    </video>
                    </div>
                </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
                <v-expansion-panel-header>Event and Payload Filtering</v-expansion-panel-header>
                <v-expansion-panel-content>
                    <div class="video-wrapper">
                    <video width="100%" controls>
                    <source src="../assets/LMSVideos/Events/Event and Payload Filtering.mp4" type="video/mp4">
                    Sorry, your browser doesn't support embedded videos.
                    </video>
                    </div>
                </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
                <v-expansion-panel-header>Receiver Framework</v-expansion-panel-header>
                <v-expansion-panel-content>
                    <div class="video-wrapper">
                    <video width="100%" controls>
                    <source src="../assets/LMSVideos/Events/Receiver framework.mp4" type="video/mp4">
                    Sorry, your browser doesn't support embedded videos.
                    </video>
                    </div>
                </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
                <v-expansion-panel-header>Create Receiver in Boomi for a Business Object</v-expansion-panel-header>
                <v-expansion-panel-content>
                    <div class="video-wrapper">
                    <video width="100%" controls>
                    <source src="../assets/LMSVideos/Events/Create Receiver in Boomi for a Business Object.mp4" type="video/mp4">
                    Sorry, your browser doesn't support embedded videos.
                    </video>
                    </div>
                </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
                <v-expansion-panel-header>Create Changes in Data in SAP for a Business Object and Receive Data in Boomi</v-expansion-panel-header>
                <v-expansion-panel-content>
                    <div class="video-wrapper">
                    <video width="100%" controls>
                    <source src="../assets/LMSVideos/Events/Create changes in Data in SAP.mp4" type="video/mp4">
                    Sorry, your browser doesn't support embedded videos.
                    </video>
                    </div>
                </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
                <v-expansion-panel-header>Batch Processing in SAP for a Business Object</v-expansion-panel-header>
                <v-expansion-panel-content>
                    <div class="video-wrapper">
                    <video width="100%" controls>
                    <source src="../assets/LMSVideos/Events/Batch Processing in SAP for a Business Object.mp4" type="video/mp4">
                    Sorry, your browser doesn't support embedded videos.
                    </video>
                    </div>
                </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
                <v-expansion-panel-header>Activation of a Change Pointer Event in SAP</v-expansion-panel-header>
                <v-expansion-panel-content>
                    <div class="video-wrapper">
                    <video width="100%" controls>
                    <source src="../assets/LMSVideos/Events/Activation of a Change Pointer Event in SAP.mp4" type="video/mp4">
                    Sorry, your browser doesn't support embedded videos.
                    </video>
                    </div>
                </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
                <v-expansion-panel-header>Subscription of a Change Pointer Event in the Boomi for SAP core UI</v-expansion-panel-header>
                <v-expansion-panel-content>
                    <div class="video-wrapper">
                    <video width="100%" controls>
                    <source src="../assets/LMSVideos/Events/Subscription of a Change Pointer Event in the aXis for SAP UI.mp4" type="video/mp4">
                    Sorry, your browser doesn't support embedded videos.
                    </video>
                    </div>
                </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
                <v-expansion-panel-header>Boomi AtomSphere Process for a Change Pointer</v-expansion-panel-header>
                <v-expansion-panel-content>
                    <div class="video-wrapper">
                    <video width="100%" controls>
                    <source src="../assets/LMSVideos/Events/Boomi AtomSphere Process for a Change Pointer.mp4" type="video/mp4">
                    Sorry, your browser doesn't support embedded videos.
                    </video>
                    </div>
                </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
                <v-expansion-panel-header>Batch Processing in SAP for a Change Pointer</v-expansion-panel-header>
                <v-expansion-panel-content>
                    <div class="video-wrapper">
                    <video width="100%" controls>
                    <source src="../assets/LMSVideos/Events/Batch Processing in SAP for a Change Pointer.mp4" type="video/mp4">
                    Sorry, your browser doesn't support embedded videos.
                    </video>
                    </div>
                </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
                <v-expansion-panel-header>Receiving Data in Boomi AtomSphere for a Change Pointer</v-expansion-panel-header>
                <v-expansion-panel-content>
                    <div class="video-wrapper">
                    <video width="100%" controls>
                    <source src="../assets/LMSVideos/Events/Receiving Data in Boomi AtomSphere for a Change Pointer.mp4" type="video/mp4">
                    Sorry, your browser doesn't support embedded videos.
                    </video>
                    </div>
                </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
                <v-expansion-panel-header>Subscription of a Business Object Event using Solace in the Boomi for SAP core UI</v-expansion-panel-header>
                <v-expansion-panel-content>
                    <div class="video-wrapper">
                    <video width="100%" controls>
                    <source src="../assets/LMSVideos/Events/Subscription of a Business Object Event using Solace in the aXis for SAP UI.mp4" type="video/mp4">
                    Sorry, your browser doesn't support embedded videos.
                    </video>
                    </div>
                </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
                <v-expansion-panel-header>Boomi AtomSphere Process for a Business Object Using Solace</v-expansion-panel-header>
                <v-expansion-panel-content>
                    <div class="video-wrapper">
                    <video width="100%" controls>
                    <source src="../assets/LMSVideos/Events/Boomi AtomSphere Process for a Business Object Using Solace.mp4" type="video/mp4">
                    Sorry, your browser doesn't support embedded videos.
                    </video>
                    </div>
                </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
                <v-expansion-panel-header>Receiving Data in Boomi AtomSphere for a Business Object using Solace</v-expansion-panel-header>
                <v-expansion-panel-content>
                    <div class="video-wrapper">
                    <video width="100%" controls>
                    <source src="../assets/LMSVideos/Events/Receiving Data in Boomi AtomSphere for a Business Object using Solace.mp4" type="video/mp4">
                    Sorry, your browser doesn't support embedded videos.
                    </video>
                    </div>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
        <br>
        <br>
        <h2 class="feature-headline">IDoc</h2>
        <br>
        <v-expansion-panels popout focusable>
            <v-expansion-panel>
                <v-expansion-panel-header>Browsing the list of IDocs and getting an overview of your Boomi for SAP core IDoc setup in the Boomi for SAP core UI</v-expansion-panel-header>
                <v-expansion-panel-content>
                    <div class="video-wrapper">
                    <video width="100%" controls>
                    <source src="../assets/LMSVideos/IDoc/IDoc UI.mp4" type="video/mp4">
                    Sorry, your browser doesn't support embedded videos.
                    </video>
                    </div>
                </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
                <v-expansion-panel-header>IDoc setup in SAP</v-expansion-panel-header>
                <v-expansion-panel-content>
                    <div class="video-wrapper">
                    <video width="100%" controls>
                    <source src="../assets/LMSVideos/IDoc/IDoc setup in SAP.mp4" type="video/mp4">
                    Sorry, your browser doesn't support embedded videos.
                    </video>
                    </div>
                </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
                <v-expansion-panel-header>Outbound IDocs in XML Format</v-expansion-panel-header>
                <v-expansion-panel-content>
                    <div class="video-wrapper">
                    <video width="100%" controls>
                    <source src="../assets/LMSVideos/IDoc/Outbound XML.mp4" type="video/mp4">
                    Sorry, your browser doesn't support embedded videos.
                    </video>
                    </div>
                </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
                <v-expansion-panel-header>Outbound IDocs in JSON Format</v-expansion-panel-header>
                <v-expansion-panel-content>
                    <div class="video-wrapper">
                    <video width="100%" controls>
                    <source src="../assets/LMSVideos/IDoc/Outbound JSON.mp4" type="video/mp4">
                    Sorry, your browser doesn't support embedded videos.
                    </video>
                    </div>
                </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
                <v-expansion-panel-header>Receiving Outbound IDocs in Boomi</v-expansion-panel-header>
                <v-expansion-panel-content>
                    <div class="video-wrapper">
                    <video width="100%" controls>
                    <source src="../assets/LMSVideos/IDoc/Receiving Outbound IDoc in Boomi.mp4" type="video/mp4">
                    Sorry, your browser doesn't support embedded videos.
                    </video>
                    </div>
                </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
                <v-expansion-panel-header>Inbound IDoc</v-expansion-panel-header>
                <v-expansion-panel-content>
                    <div class="video-wrapper">
                    <video width="100%" controls>
                    <source src="../assets/LMSVideos/IDoc/Inbound IDoc.mp4" type="video/mp4">
                    Sorry, your browser doesn't support embedded videos.
                    </video>
                    </div>
                </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
                <v-expansion-panel-header>ABAP Proxy in the Boomi for SAP Connector</v-expansion-panel-header>
                <v-expansion-panel-content>
                    <div class="video-wrapper">
                    <video width="100%" controls>
                    <source src="../assets/LMSVideos/IDoc/Abap Proxy.mp4" type="video/mp4">
                    Sorry, your browser doesn't support embedded videos.
                    </video>
                    </div>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
        <br>
        <br>
        <h2 class="feature-headline">Function Modules and Data Wizards</h2>
        <br>
        <v-expansion-panels popout focusable>
            <v-expansion-panel>
                <v-expansion-panel-header>Boomi for SAP core Function Module</v-expansion-panel-header>
                <v-expansion-panel-content>
                    <div class="video-wrapper">
                    <video width="100%" controls>
                    <source src="../assets/LMSVideos/FunctionModulesAndDataWizards/aXis for SAP Function Module.mp4" type="video/mp4">
                    Sorry, your browser doesn't support embedded videos.
                    </video>
                    </div>
                </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
                <v-expansion-panel-header>Boomi for SAP core Data Wizard</v-expansion-panel-header>
                <v-expansion-panel-content>
                    <div class="video-wrapper">
                    <video width="100%" controls>
                    <source src="../assets/LMSVideos/FunctionModulesAndDataWizards/aXis for SAP Data Wizard.mp4" type="video/mp4">
                    Sorry, your browser doesn't support embedded videos.
                    </video>
                    </div>
                </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
                <v-expansion-panel-header>Calling a Function Module Service in Boomi AtomSphere</v-expansion-panel-header>
                <v-expansion-panel-content>
                    <div class="video-wrapper">
                    <video width="100%" controls>
                    <source src="../assets/LMSVideos/FunctionModulesAndDataWizards/Calling a Function Module Service in Boomi AtomSphere.mp4" type="video/mp4">
                    Sorry, your browser doesn't support embedded videos.
                    </video>
                    </div>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
        <br>
        <br>
        <h2 class="feature-headline">BW Data Extractor</h2>
        <br>
        <div class="conent-wrapper">
        <v-expansion-panels popout focusable>
            <v-expansion-panel>
                <v-expansion-panel-header>Activation of BW Data Extractor in Boomi for SAP core UI</v-expansion-panel-header>
                <v-expansion-panel-content>
                    <div class="video-wrapper">
                    <video width="100%" controls>
                    <source src="../assets/LMSVideos/BusinessWarehouseExtractors/Activation of BW Data Extractor in aXis UI.mp4" type="video/mp4">
                    Sorry, your browser doesn't support embedded videos.
                    </video>
                    </div>
                </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
                <v-expansion-panel-header>Activate BW Data Extractor in SAP</v-expansion-panel-header>
                <v-expansion-panel-content>
                    <div class="video-wrapper">
                    <video width="100%" controls>
                    <source src="../assets/LMSVideos/BusinessWarehouseExtractors/Activation of BW Extractor in SAP.mp4" type="video/mp4">
                    Sorry, your browser doesn't support embedded videos.
                    </video>
                    </div>
                </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
                <v-expansion-panel-header>BW Data Extractor Receiver Process</v-expansion-panel-header>
                <v-expansion-panel-content>
                    <div class="video-wrapper">
                    <video width="100%" controls>
                    <source src="../assets/LMSVideos/BusinessWarehouseExtractors/BW Receiver process.mp4" type="video/mp4">
                    Sorry, your browser doesn't support embedded videos.
                    </video>
                    </div>
                </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
                <v-expansion-panel-header>Sending a BW Event Data Extract</v-expansion-panel-header>
                <v-expansion-panel-content>
                    <div class="video-wrapper">
                    <video width="100%" controls>
                    <source src="../assets/LMSVideos/BusinessWarehouseExtractors/Sending a BW Event Data Extract.mp4" type="video/mp4">
                    Sorry, your browser doesn't support embedded videos.
                    </video>
                    </div>
                </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
                <v-expansion-panel-header>Receiving a BW Event</v-expansion-panel-header>
                <v-expansion-panel-content>
                    <div class="video-wrapper">
                    <video width="100%" controls>
                    <source src="../assets/LMSVideos/BusinessWarehouseExtractors/Receiving a BW event.mp4" type="video/mp4">
                    Sorry, your browser doesn't support embedded videos.
                    </video>
                    </div>
                </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
                <v-expansion-panel-header>BW Data Extractor Receiver Process with Delete</v-expansion-panel-header>
                <v-expansion-panel-content>
                    <div class="video-wrapper">
                    <video width="100%" controls>
                    <source src="../assets/LMSVideos/BusinessWarehouseExtractors/BW Data Extractor Receiver Process with Delete.mp4" type="video/mp4">
                    Sorry, your browser doesn't support embedded videos.
                    </video>
                    </div>
                </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
                <v-expansion-panel-header>Receiving BW Event and Deletion in Boomi AtomSphere</v-expansion-panel-header>
                <v-expansion-panel-content>
                    <div class="video-wrapper">
                    <video width="100%" controls>
                    <source src="../assets/LMSVideos/BusinessWarehouseExtractors/Receiving BW Event and Deletion in Boomi AtomSphere.mp4" type="video/mp4">
                    Sorry, your browser doesn't support embedded videos.
                    </video>
                    </div>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
    </div>
    </v-card>
    </v-container>
  </div>
</template>

<script>
import Vue from "vue";
import { Component } from "vue-property-decorator";
import Features from "./Features.vue";

@Component({ components: { Features } })
export default class features extends Vue {}
</script>

<style>
.feature-headline {
    color: #435563;
}
v-expansion-panel-header {
    color: #435563;
}
</style>
