<template><div>
<div class="paragraph">
<p>An SAP RFC Connection is required to send data to REST services in the following features:</p>
</div>
<div class="ulist">
<ul>
<li>
<p>Events</p>
</li>
<li>
<p>BW Extractors</p>
</li>
<li>
<p>Table Service Extracts</p>
</li>
</ul>
</div>
</div></template>
<script>export default {}; </script>