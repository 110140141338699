import Vue from "vue";
import { Component } from "vue-property-decorator";

@Component({})
export default class TypeTwoPanels extends Vue {
  panels = [
    {
      headline: "Line of Business",
      icon: "business",
      quote: "I know the relevant data that I want to extract from a business point of view",
      description: "Boomi for SAP delivers unique user interface for Line of Business to find SAP data to be delivered in open easy-readable formats"
    },
    {
      headline: "SAP Developer",
      icon: "sap-dev",
      quote: "I know how to extract data from SAP from a technical perspective",
      description: "Boomi for SAP delivers a framework to configure services from SAP (as opposed to developing each individual service)"
    },
    {
      headline: "Integration Developer",
      icon: "worker",
      quote: "I know how to convert data from SAP to external systems",
      description: "Boomi for SAP delivers a standardized way to get access to SAP data and handle them in a structured way in a modern hybrid integration platform"
    },
    {
      headline: "IT Architect",
      icon: "settings",
      quote: "I know how to define integration patterns and standards",
      description: "Boomi for SAP delivers unique technology to create Event Driven architecture with SAP"
    }
  ];
}