import Vue from "vue";
import Vuetify from "vuetify/lib";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#435563",
        secondary: "#4F868F",
        accent: "#F37C66",
        action: "#F2B800"
      }
    }
  },
  icons: {
    iconfont: "mdiSvg"
  }
});
