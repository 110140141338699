var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"sect1"},[_c('h2',{staticClass:"section-headline pb-4",attrs:{"id":"_user_guide"}},[_vm._v("User Guide")]),_c('div',{staticClass:"sectionbody"},[_c('div',{staticClass:"sect2"},[_c('h2',{staticClass:"section-headline pb-4",attrs:{"id":"_data_wizards"}},[_vm._v("Data Wizards")]),_vm._m(0),_vm._m(1),_vm._m(2),_c('div',{staticClass:"imageblock"},[_c('div',{staticClass:"content"},[_c('v-img',{attrs:{"contain":"","src":require("././graphics/data-wizards-list.png"),"alt":"data-wizards-list"}})],1)]),_vm._m(3),_vm._m(4),_c('div',{staticClass:"sect3"},[_c('h3',{staticClass:"section-headline pb-4",attrs:{"id":"_search_data_wizards"}},[_vm._v("Search Data Wizards")]),_vm._m(5),_vm._m(6),_c('div',{staticClass:"imageblock"},[_c('div',{staticClass:"content"},[_c('v-img',{attrs:{"contain":"","src":require("././graphics/data-wizard-list-searching.png"),"alt":"data-wizard-list-searching"}})],1)]),_vm._m(7),_vm._m(8)])]),_c('div',{staticClass:"sect2"},[_c('h2',{staticClass:"section-headline pb-4",attrs:{"id":"_using_the_data_wizard_tree"}},[_vm._v("Using the Data Wizard tree")]),_vm._m(9),_vm._m(10),_c('div',{staticClass:"imageblock"},[_c('div',{staticClass:"content"},[_c('v-img',{attrs:{"contain":"","src":require("././graphics/data-wizards-add-to-canvas.png"),"alt":"data-wizards-add-to-canvas"}})],1)]),_vm._m(11),_vm._m(12)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"paragraph"},[_c('p',[_vm._v("In the following section the "),_c('em',[_c('strong',[_vm._v("Data Wizards")])]),_vm._v(" will be described and visualized.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"paragraph"},[_c('p',[_vm._v("When you enter the front page, you will find the menu on the left side, where you can choose the "),_c('em',[_c('strong',[_vm._v("Data Wizards")])]),_vm._v(" under the "),_c('em',[_c('strong',[_vm._v("CREATE")])]),_vm._v(" section.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"paragraph"},[_c('p',[_c('br')])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"paragraph"},[_c('p',[_c('br')])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"paragraph"},[_c('p',[_vm._v("The data wizards page shows you all the SAP BAPI’s that can be used find Function Modules. The Function Modules can then be exposed as services. We refer the reader to the Function Module Feature description for more information about the technical details of Function Modules.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"paragraph"},[_c('p',[_vm._v("You can search for data wizards by using the search function in the upper left corner of the page. If you for example search on "),_c('em',[_vm._v("‘Business Partner’")]),_vm._v(", you will filter on all Function Modules that are contained within a BAPI with "),_c('em',[_vm._v("‘Business Partner’")]),_vm._v(" in the name or description.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"paragraph"},[_c('p',[_c('br')])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"paragraph"},[_c('p',[_c('br')])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"paragraph"},[_c('p',[_vm._v("Moreover, you can change the search language in the dropdown menu. The "),_c('em',[_vm._v("‘Include empty’")]),_vm._v(" checkbox allows you to find BAPI’s that does not contain any Function Modules. They are excluded by default.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"paragraph"},[_c('p',[_vm._v("The Data Wizard shall be thought of as a tree of folders. Each Folder is a BAPI and it can have none or multiple subfolders. Lastly a folder can have multiple Function Modules that are represented with an "),_c('em',[_vm._v("‘ f ’")]),_vm._v(" icon. To access a Function Module, you can simply drag or double click the Function Module item in the tree.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"paragraph"},[_c('p',[_c('br')])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"paragraph"},[_c('p',[_c('br')])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"paragraph"},[_c('p',[_vm._v("To understand how the Function Module that is added to the canvas works, we refer the reader to the Function Module feature description. The canvas part is identical to Function Modules. How to utilize the Function Module in Boomi is also described in the Function Module feature description.")])])
}]

export { render, staticRenderFns }