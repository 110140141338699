<template>
    <div class="subpage-wrapper">
      <div class="header-banner">
        <div class="image-wrapper">
          <v-img
            alt="Boomi for SAP"
            contain
            src="@/graphics/birds.svg"
          />
        </div>
        <v-container class="content">
          <h1>Installation Guide</h1>
        </v-container>
      </div>
      <v-container class="content-section">
        <v-card
          width="100%"
          class="pa-10 mb-10"
        >
        <InstallationGuide />
        </v-card>
      </v-container>
    </div>
  </template>

<style scoped lang="scss">
@import '~vuetify/src/styles/settings/_variables';

</style>

<script>
import Vue from "vue";
import { Component } from "vue-property-decorator";
import InstallationGuide from "./_InstallationGuide.vue";

@Component({ components: { InstallationGuide } })
export default class installationGuide extends Vue {
  mounted() {
    const tableOfContent = document.getElementById("toc");
    tableOfContent.remove();
    const htmlCollection = document.getElementsByTagName("h2");
    const headers = [...htmlCollection];
    headers.forEach(element => {
      const header = element.innerHTML;
      element.innerHTML = header
        .split(" ")
        .slice(1)
        .reduce((accumulator, currentvalue) => accumulator + " " + currentvalue);
    });
    const rfcLink = document.getElementById("RFCLink");
    const link = document.createElement("a");
    link.href = "/getting-started-with-axis/configuration/rfc-configuration";
    link.innerHTML = "RFC Connection User Guide";
    rfcLink.replaceChild(link, rfcLink.firstChild);
  }
}
</script>