<template><div>
<div class="sect1">
<h2 class="section-headline pb-4" id="_user_guide">User Guide</h2>
<div class="sectionbody">
<div class="paragraph">
<p>This section describes <em><strong>BW Data Extractors</strong></em>.</p>
</div>
<div class="sect2">
<h2 id="_prerequisites" class="section-headline pb-4">Prerequisites</h2>
<div class="ulist">
<ul>
<li>
<p>SAP RFC Connection to the Boomi runtime (Atom, Molecule, Cloud) and a Boomi process to receive the data</p>
</li>
</ul>
</div>
</div>
<div class="sect2">
<h2 id="_bw_data_extractor_in_sap" class="section-headline pb-4">BW Data Extractor in SAP</h2>
<div class="paragraph">
<p>To activate a BW Data Extractor, you have two options; Either you use the Boomi for SAP UI, or you logon to SAP. Both options are described in this user guide.</p>
</div>
<div class="sect3">
<h3 id="_activating_bw_extractors_in_boomi_for_sap" class="section-headline pb-4">Activating BW Extractors in Boomi for SAP</h3>
<div class="paragraph">
<p>From the BW Data Extractor menu you are able to search for the data source you are looking for. When found drag onto canvas to have information regarding extractor.</p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/BW-UI-canvas.png" alt="BW-UI-canvas" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p>Long description and specification are shown.</p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/BW-UI-canvas-filled.png" alt="BW-UI-canvas-filled" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p>To activate the extractor press the orange bottom in the top right corner. When entered the activation menu choose a transport and if you would like the extractor to get handled via costume "BADI" implementation instead of Boomi for SAP standard implementation, then press "Custom Extract".</p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/BW-activation.png" alt="BW-activation" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
</div>
<div class="sect3">
<h3 id="_activating_bw_extractors_in_sap_gui" class="section-headline pb-4">Activating BW Extractors in SAP GUI</h3>
<div class="paragraph">
<p>You should run transaction <em><strong>/IXBX/ZBX006</strong></em>: <em>'Send BW Data Extractor'</em>, and then
click on the <strong>'New Entries'</strong> button.</p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/SAP-active-extractors.png" alt="SAP-active-extractors" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p>You should then select your desired data source and add 'X' to the column Custom if the extraction will be handled in a custom BADI implementation and not the standard Boomi for SAP implementation. The BW Extractor should be sent via RFC which can be created for the URL previously used. After that click on the <strong>'Save'</strong> button.</p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/sap-search-datasource.png" alt="sap-search-datasource" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p>When you have saved the selected data source, the system will prompt you for which transport request to tie this change to (I.e. customizing request). You must then select the customizing request and click on the <strong>'green checkmark'</strong>.</p>
</div>
<div class="paragraph">
<p>You will now be able to see the data extractor in the menu <em><strong>BW Data Extractor</strong></em>.</p>
</div>
<div class="paragraph">
<p>You are able to send via ZBX007</p>
</div>
<div class="paragraph">
<p><br></p>
</div>
</div>
<div class="sect3">
<h3 id="_bw_extraction_delete" class="section-headline pb-4">BW Extraction Delete</h3>
<div class="paragraph">
<p>With <em><strong>BW Extraction Delete</strong></em> it is possible to delete the extractions that have been marked for deletion by Boomi as well as unprocessed extracts. It is recommended to create a periodical job that runs in specific iterations. Below the process of deleting extractions manually are shown.</p>
</div>
<div class="paragraph">
<p>To manually delete processed and unprocessed extractions, go to <em><strong>/IXBX/ZBX009 (BW extraction delete)</strong></em> to specify what should be deleted.</p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/SAP-delete.png" alt="SAP-delete" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p>The amount of days of when the extractions should be deleted must be defined. The parameters are described below:</p>
</div>
<div class="paragraph">
<p><strong>Processed, deletion marked</strong>: This parameter will delete extractions that has been marked for deletion by Boomi based on the age deletion criteria. If everything marked for deletion by Boomi should be deleted, this value should be set to 0. In the below example the value is set to 2, meaning that all extractions marked for deletion two days ago will be deleted.</p>
</div>
<div class="paragraph">
<p><strong>Unprocessed extractions</strong>: This parameter will delete unprocessed extractions. Unprocessed extractions, means that the extraction have not been picked up by Boomi. In the below example, this value is set to 7, meaning that all extractions that have been created but not collected by Boomi within the last seven days, will not be deleted. All extractions that have been created for more than seven days ago and not been collected by Boomi will be deleted.</p>
</div>
<div class="paragraph">
<p>When the values have been spcified and filled in the boxes, then click on <strong>'Execute'</strong>.</p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/parameters-delete.png" alt="parameters-delete" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p>When the specified extractions have been deleted, a message will show up in the left lower corner, saying at which date the processed and unprocessed extractions have been deleted.</p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/message-delete.png" alt="message-delete" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
</div>
<div class="sect3">
<h3 id="_list_manage" class="section-headline pb-4">List &amp; Manage</h3>
<div class="paragraph">
<p>You can see which <em><strong>BW Data extractors</strong></em> are activated, by clicking on <strong>‘BW Data Extractors’</strong> under the <em><strong>LIST</strong></em> in the menu on the left side of the homepage.</p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/bw-data.png" alt="bw-data" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p>This list shows SAP&#8217;s activated extractors, ie. which extractors can send data when they run.</p>
</div>
<div class="paragraph">
<p><strong>Data Source:</strong>: The data source name in SAP.</p>
</div>
<div class="paragraph">
<p><strong>Description:</strong>: A description of the data extractor in SAP.</p>
</div>
<div class="paragraph">
<p><strong>Boomi for SAP Active</strong>: Shows if the BW Extractor is activated for Boomi for SAP</p>
</div>
<div class="paragraph">
<p><strong>Custom Extract</strong>: Indicates if the BW Extractor is ready for custom extraction.</p>
</div>
<div class="paragraph">
<p><strong>Deactivation</strong>: Option to delete BW Extractor from UI</p>
</div>
<div class="paragraph">
<p>You can search for extractors by using the search function in the upper right corner of the page. Example, if you search for the name <em>‘FLEET’</em>, all extractors containing <em>'FLEET'</em> will appear.</p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/bw-data-extractors-search.png" alt="bw-data-extractors-search" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p>If you do not see the data source that you need on the list, then logon to <em><strong>SAP GUI</strong></em> to activate the data source by running /IXBX/ZBX006.</p>
</div>
</div>
</div>
<div class="sect2">
<h2 id="_boomi_atomsphere" class="section-headline pb-4">Boomi AtomSphere</h2>
<div class="paragraph">
<p>This section describes how to import the SAP data source format into Boomi, configure Boomi to receive notifications when extractions occur, extract the data, and then send it to another system.</p>
</div>
<div class="paragraph">
<p>If this is the first BW data extraction in your account, then download the example processes from Boomi&#8217;s process library. If there is already a BW extraction in your account, then follow the instructions to configure <a href="#bw-additional">Additional BW Processes</a>.</p>
</div>
<div class="sect3">
<h3 id="_download_from_process_library" class="section-headline pb-4">Download from Process Library</h3>
<div class="olist arabic">
<ol class="arabic">
<li>
<p>Create a new folder to store the example process</p>
</li>
<li>
<p>Click on the <strong>'Browse Process Library'</strong> button in the lower left hand side of the screen.</p>
<div class="ulist">
<ul>
<li>
<p><span class="image"><v-img contain src="./graphics/bw-process-library.png" alt="bw-process-library" /></span></p>
</li>
</ul>
</div>
</li>
<li>
<p>Search for <em>'Boomi for SAP'</em> in the process library</p>
<div class="ulist">
<ul>
<li>
<p><span class="image"><v-img contain src="./graphics/boomi-bw-search-process-library.png" alt="boomi-bw-search-process-library" /></span></p>
</li>
</ul>
</div>
</li>
<li>
<p>Select the <em><strong>Boomi for SAP Examples</strong></em> process and click on the <strong>'Install'</strong> button.</p>
</li>
<li>
<p>Select the installation location you chose earlier and click on the <strong>'Install'</strong> button.</p>
<div class="ulist">
<ul>
<li>
<p><span class="image"><v-img contain src="./graphics/boomi-bw-install-process-library.png" alt="boomi-bw-install-process-library" /></span></p>
</li>
</ul>
</div>
</li>
<li>
<p>You should now see 5 processes in the new folder in your account</p>
<div class="ulist">
<ul>
<li>
<p><span class="image"><v-img contain src="./graphics/boomi-bw-new-processes-from-library.png" alt="boomi-bw-new-processes-from-library" /></span></p>
</li>
</ul>
</div>
</li>
<li>
<p>You can then double click on the <strong>'BW Event'</strong> process and use that as a basis for your new BW extraction listener as described in <a href="#configure-bw-event">Configure BW Extractor Process</a></p>
<div class="ulist">
<ul>
<li>
<p><span class="image"><v-img contain src="./graphics/boomi-bw-extractor-example.png" alt="boomi-bw-extractor-example" /></span></p>
</li>
</ul>
</div>
</li>
</ol>
</div>
</div>
<div class="sect3">
<h3 id="bw-additional" class="section-headline pb-4">Additional BW Processes</h3>
<div class="paragraph">
<p>If there is already a process that receives BW extractions in your Boomi account, then open that process and add a process route after start shape.</p>
</div>
<div class="paragraph">
<p>The process route should route by the datasource on the <em><strong>SAP ZBX_BW_EVENT</strong></em> profile. You can then copy the rest of the original process and save it into a new process. Afterwards add two keys to the process route, the first with the name of the datasource from the original process which should route to the copied process, and the second with the name of your new data source and which should route to a new process as described in <a href="#configure-bw-event">Configure BW Extractor Process</a>.</p>
</div>
<div class="paragraph">
<p>Note: You should only have one BW LISTENER process per runtime.</p>
</div>
</div>
<div class="sect3">
<h3 id="configure-bw-event" class="section-headline pb-4">Configure BW Extractor Process</h3>
<div class="paragraph">
<p>If you are starting from the example process you can first select all the notification steps and click on the <strong>'Remove'</strong> button.</p>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/bw-boomi-select-notification.png" alt="bw-boomi-select-notification" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p>You now must click on the <strong>'OK'</strong> button.</p>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/bw-boomi-remove-notifications.png" alt="bw-boomi-remove-notifications" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p>You can then click on the unconnected endpoint next to the start shape and drag it to the <em><strong>Boomi for SAP connection</strong></em>. The endpoint should turn into a green arrow, showing that it is connected.</p>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/bw-boomi-unconnected.png" alt="bw-boomi-unconnected" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/bw-boomi-connected.png" alt="bw-boomi-connected" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p>You can then click on the <strong>'arrange'</strong> button and arrange without saving.</p>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/bw-boomi-arrange-without-saving.png" alt="bw-boomi-arrange-without-saving" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p>Now you have a smaller process.</p>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/bw-boomi-smaller-process.png" alt="bw-boomi-smaller-process" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p>Then double click on the <strong>Boomi for SAP connection</strong>.</p>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/bw-boomi-connector-shape.png" alt="bw-boomi-connector-shape" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p>If a Boomi for SAP connection has already been configured, then click the <strong>'x'</strong> in the connection field to remove the Boomi for SAP Example, and select the connection created previously.</p>
</div>
<div class="paragraph">
<p>Boomi licenses are based on consumed connections by runtime (ATOM/MOLECULE), so reuse the configured connection as much as possible per runtime.</p>
</div>
<div class="paragraph">
<p>If there isn&#8217;t an existing Boomi for SAP connection, then click the <strong>'pencil icon'</strong> next to the Boomi for SAP Example.</p>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/pencil-icon.png" alt="pencil-icon" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p>You must then fill in the different fields shown on the connection page. If you don&#8217;t know the answers, reach out to the SAP team within your organization. When all the fields have been filled out you must click on the <strong>'Save and Close'</strong> button to get back.</p>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/boomi-connector-username.png" alt="boomi-connector-username" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
</div>
<div class="sect3">
<h3 id="_operation" class="section-headline pb-4">Operation</h3>
<div class="paragraph">
<p>You now must configure the connector operator. Click on the <strong>'pencil icon'</strong> next to the BW Get extraction operation.</p>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/boomi-operater.png" alt="boomi-operater" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p>You will then be redirected to the page where you can configure the operation.</p>
</div>
<div class="paragraph">
<p>To easily find the operation in the future, you should replace BW Get extraction with the name of the extractor you created earlier. In this example we named the operation <em>'Get extraction for 0PM_FLEET'</em> because it imports the extractor named <em>'0PM_FLEET'</em>.</p>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/boomi-bw-operator-import.png" alt="boomi-operator-import" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p>Then click on the <strong>'Import'</strong> button to query the objects. A pop-up window will then appear.</p>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/boomi-bw-operator-import-page.png" alt="boomi-operator-import-page" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p>Then choose an <em><strong>Atom</strong></em> to run the operation by clicking on the drop-down.</p>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/boomi-bw-operator-import-atom.png" alt="boomi-bw-operator-import-atom" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p>Now choose a connection. You should select the connection that you configured earlier.</p>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/boomi-bw-operator-import-connector.png" alt="boomi-bw-operator-import-connector" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p>When you have selected the Atom and the connection, you must click on the <strong>'Next'</strong> button to query the objects. The Atom will then connect you to object types. You must then click on the drop-down and click on the desired <em><strong>Object Type</strong></em>.</p>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/boomi-bw-operator-import-objects.png" alt="boomi-bw-operator-import-objects" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p>Alternatively, if you click on the <strong>'Back'</strong> button you can use filters with wildcard characters to reduce the number of returned objects.</p>
</div>
<div class="paragraph">
<p>The filter allows you to limit the objects (Services) by searching for a specific value in the service name.</p>
</div>
<div class="paragraph">
<p>You can use * to search for any value. If you want to find tables ending in <em>'FLEET'</em> you should search on <em>'*FLEET'</em>. Or if you want to find all services that contain <strong>'FLEET'</strong> somewhere in the title search on
<em>'*FLEET*'</em>.</p>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/boomi-bw-operator-object-filter.png" alt="boomi-bw-operator-object-filter" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p>When you have entered the desired filter for example <strong>*FLEET*</strong> you must click on the <strong>'Next'</strong> button. The object type shown will then contain the word <em>'FLEET'</em>.</p>
</div>
<div class="paragraph">
<p>When you have selected the desired object type you should click on the <strong>'Next'</strong> button. A new page will then appear showing the object type and the associated response profile. You should then click on the <strong>'Finish'</strong> button to get back.</p>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/boomi-bw-fleet-finish.png" alt="boomi-bw-fleet-finish" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p>You will now see that your operator imports the extractor selected with an associated response profile. You should then click on the <strong>'Save and Close'</strong> button to get back to the connector page. To finish the configuration, you should click on the <strong>'OK'</strong> button.</p>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p>Follow the same procedure to create a <strong>'BW_DELETE'</strong> Connector Operation by choosing the <strong>'BW_DELETE'</strong> action.</p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/boomi-bw-operation-delete.png" alt="boomi-bw-operation-delete" />
</div>
</div>
</div>
</div>
</div>
</div>
</div></template>
<script>export default {}; </script>