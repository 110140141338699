var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._m(0),_c('div',{staticClass:"sect1"},[_c('h2',{staticClass:"section-headline pb-4",attrs:{"id":"_adding_services_to_a_transport"}},[_vm._v("Adding services to a Transport")]),_c('div',{staticClass:"sectionbody"},[_vm._m(1),_c('div',{staticClass:"imageblock"},[_c('div',{staticClass:"content"},[_c('v-img',{attrs:{"contain":"","src":require("./graphics/hyper_automation_choose_feature.png"),"alt":"Choose-Feature"}})],1)]),_vm._m(2),_vm._m(3),_c('div',{staticClass:"imageblock"},[_c('div',{staticClass:"content"},[_c('v-img',{attrs:{"contain":"","src":require("./graphics/hyper_automation_choose_services.png"),"alt":"Choose-services"}})],1)]),_c('div',{staticClass:"imageblock"},[_c('div',{staticClass:"content"},[_c('v-img',{attrs:{"contain":"","src":require("./graphics/hyper_automation_choose_transport.png"),"alt":"Choose-transport"}})],1)]),_vm._m(4),_vm._m(5),_c('div',{staticClass:"imageblock"},[_c('div',{staticClass:"content"},[_c('v-img',{attrs:{"contain":"","src":require("./graphics/hyper_automation_request_status.png"),"alt":"Request-status"}})],1)]),_vm._m(6),_vm._m(7),_c('div',{staticClass:"imageblock"},[_c('div',{staticClass:"content"},[_c('v-img',{attrs:{"contain":"","src":require("./graphics/hyper_automation_request_status_subscr.png"),"alt":"Add-Transport"}})],1)]),_vm._m(8),_vm._m(9),_vm._m(10),_c('div',{staticClass:"imageblock"},[_c('div',{staticClass:"content"},[_c('v-img',{attrs:{"contain":"","src":require("./graphics/hyper_automation_successful_addition.png"),"alt":"Added-successfully"}})],1)]),_c('div',{staticClass:"imageblock"},[_c('div',{staticClass:"content"},[_c('v-img',{attrs:{"contain":"","src":require("./graphics/hyper_automation_error_messages.png"),"alt":"Error-messages"}})],1)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"preamble"}},[_c('div',{staticClass:"sectionbody"},[_c('div',{staticClass:"paragraph"},[_c('p',[_vm._v("The following guide will run through the options you will have to set up a transport through Hyper Automation.")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"paragraph"},[_c('p',[_vm._v("Use transaction "),_c('strong',[_vm._v("/IXBX/HYPER_AUTO")]),_vm._v(" or "),_c('strong',[_vm._v("/IXBX/ZBX002")]),_vm._v(" to get to the main menu of Hyper Automation where you can see all features available and choose one to add services to a transport. There is also the possibility to select the deployment mode for the desired services. ("),_c('em',[_vm._v("It is adviced to create separate transports for the LOCAL and PROD object to avoid any confusion between production and development/test systems")]),_vm._v(")")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"paragraph"},[_c('p',[_c('br')])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"paragraph"},[_c('p',[_vm._v("Choose the services you would like to be put in a transport. When ready choose \"Add to transport\". You will then be asked for the transport request. You can either "),_c('strong',[_vm._v("create a new transport")]),_vm._v(" or "),_c('strong',[_vm._v("choose an existing one")]),_vm._v(". ("),_c('em',[_vm._v("To see all active transports and what they include, use transaction "),_c('strong',[_vm._v("SE10")]),_vm._v(".")]),_vm._v(")")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"paragraph"},[_c('p',[_c('br')])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"paragraph"},[_c('p',[_vm._v("An information box appears that informs about the number of chosen services and asks if you want to proceed or not.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"paragraph"},[_c('p',[_c('br')])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"paragraph"},[_c('p',[_vm._v("In case \"Subscriptions\" were chosen in the main menu, then the information box will look like below, informing that all the objects (table service, topic, receiver) related to the chosen subscriptions are automatically added to the transport too.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"paragraph"},[_c('p',[_c('br')])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"paragraph"},[_c('p',[_vm._v("If 'No' is chosen then the action will be cancelled and you will get back to the list of services.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"paragraph"},[_c('p',[_vm._v("If 'Yes' is chosen then you will either get a successful message and the services will be added to the transport, otherwise you will get an error box explaining what the issue is.")])])
}]

export { render, staticRenderFns }