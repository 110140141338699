import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/getting-started-with-axis/",
    name: "Getting started with Boomi for SAP",
    component: () => import("../views/GettingStartedWithAxis/index.vue")
  },
  {
    path: "/getting-started-with-axis/table-service",
    name: "Table service - Getting started with Boomi for SAP",
    component: () =>
      import("../views/GettingStartedWithAxis/SingleService/index.vue")
  },
  {
    path: "/getting-started-with-axis/table-service/user-guide",
    name: "Table Service - User Guide",
    component: () =>
      import("../views/GettingStartedWithAxis/SingleService/UserGuide.vue")
  },
  // { // keeping this to keep SEO
  //   path: "/getting-started-with-axis/join-service",
  //   name: "Join service - Getting started with aXis",
  //   component: () =>
  //     import("../views/GettingStartedWithAxis/JoinService/index.vue")
  // },
  // { // posterity NEW page? TODO
  //   path: "/getting-started-with-axis/join-service/user-guide",
  //   name: "Join service - User Guide",
  //   component: () =>
  //     import("../views/GettingStartedWithAxis/JoinService/UserGuide.vue")
  // },
  {
    path: "/getting-started-with-axis/idoc",
    name: "IDoc - Getting started with Boomi for SAP",
    component: () =>
      import("../views/GettingStartedWithAxis/idoc/index.vue")
  },
  {
    path: "/getting-started-with-axis/idoc/user-guide",
    name: "IDoc - User Guide",
    component: () =>
      import("../views/GettingStartedWithAxis/idoc/UserGuide.vue")
  },
  {
    path: "/getting-started-with-axis/function-modules",
    name: "Function modules - Getting started with Boomi for SAP",
    component: () =>
      import("../views/GettingStartedWithAxis/FunctionModules/index.vue")
  },
  {
    path: "/getting-started-with-axis/function-modules/user-guide",
    name: "Function modules - User Guide",
    component: () =>
      import("../views/GettingStartedWithAxis/FunctionModules/UserGuide.vue")
  },
  {
    path: "/getting-started-with-axis/data-wizards",
    name: "Data wizards",
    component: () =>
      import("../views/GettingStartedWithAxis/DataWizards/index.vue")
  },
  {
    path: "/getting-started-with-axis/data-wizards/user-guide",
    name: "Data wizards - User Guide",
    component: () =>
      import("../views/GettingStartedWithAxis/DataWizards/UserGuide.vue")
  },
  {
    path: "/getting-started-with-axis/bw-data-extractor",
    name: "BW Data Extractor",
    component: () =>
      import("../views/GettingStartedWithAxis/BWDataExtractor/index.vue")
  },
  {
    path: "/getting-started-with-axis/bw-data-extractor/user-guide",
    name: "BW Data Extractor - User Guide",
    component: () =>
      import("../views/GettingStartedWithAxis/BWDataExtractor/UserGuide.vue")
  },
  {
    path: "/getting-started-with-axis/bw-data-extractor/configuration",
    name: "BW Data Extractor - Configuration",
    component: () =>
      import(
        "../views/GettingStartedWithAxis/BWDataExtractor/Configuration.vue"
      )
  },
  // { // keeping for SEO
  //   path: "/getting-started-with-axis/simple-events",
  //   name: "Simple Events",
  //   component: () => import("../views/GettingStartedWithAxis/SimpleEvents/index.vue")
  // },
  // { // posterity, new page? TODO
  //   path: "/getting-started-with-axis/simple-events/user-guide",
  //   name: "Simple Events - User Guide",
  //   component: () =>
  //     import("../views/GettingStartedWithAxis/SimpleEvents/UserGuide.vue")
  // },
  {
    path: "/getting-started-with-axis/events",
    name: "Events",
    component: () => import("../views/GettingStartedWithAxis/Events/index.vue")
  },
  {
    path: "/getting-started-with-axis/events/user-guide",
    name: "Events - User Guide",
    component: () =>
      import("../views/GettingStartedWithAxis/Events/UserGuide.vue")
  },
  {
    path: "/getting-started-with-axis/events/appendix",
    name: "Appendix",
    component: () =>
      import("../views/GettingStartedWithAxis/Events/Appendix.vue")
  },
  {
    path: "/getting-started-with-axis/table-service-extract",
    name: "Table service extract",
    component: () =>
      import("../views/GettingStartedWithAxis/TableServiceExtract/index.vue")
  },
  {
    path: "/getting-started-with-axis/table-service-extract/user-guide",
    name: "Table service extract - User Guide",
    component: () =>
      import("../views/GettingStartedWithAxis/TableServiceExtract/UserGuide.vue")
  },
  {
    path: "/getting-started-with-axis/configuration",
    name: "Configuration",
    component: () =>
      import("../views/GettingStartedWithAxis/Configuration/index.vue")
  },
  {
    path: "/getting-started-with-axis/configuration/rfc-configuration",
    name: "Configuration - RFC",
    component: () =>
      import("../views/GettingStartedWithAxis/Configuration/RfcConfiguration.vue")
  },
  {
    path: "/request-trial",
    name: "Request trial",
    component: () => import("../views/RequestTrial.vue")
  },
  {
    path: "/request-demo",
    name: "Request demo",
    component: () => import("../views/RequestDemo.vue")
  },
  {
    path: "/Learning-Hub",
    name: "Learning-Hub",
    component: () => import("../views/E-learning/index.vue")
  },
  {
    path: "/Learning-Hub/Features",
    name: "Features",
    component: () => import("../views/E-learning/Features/Features.vue")
  },
  {
    path: "/Learning-Hub/InstallationGuide",
    name: "InstallationGuide",
    component: () => import("../views/E-learning/InstallationGuide/InstallationGuide.vue")
  },
  {
    path: "/Learning-Hub/ConfigurationGuide",
    name: "ConfigurationGuide",
    component: () => import("../views/E-learning/ConfigurationGuide/ConfigurationGuide.vue")
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/About.vue")
  },
  {
    path: "/about/release-notes",
    name: "Release Notes",
    component: () =>
      import("../views/releaseNotes/index.vue")
  },
  {
    path: "/getting-started-with-axis/hyper-automation",
    name: "Hyper Automation - Getting started with Boomi for SAP",
    component: () =>
      import("../views/GettingStartedWithAxis/HyperAutomation/index.vue")
  },
  {
    path: "/getting-started-with-axis/hyper-automation/user-guide",
    name: "Hyper Automation - User Guide",
    component: () =>
      import("../views/GettingStartedWithAxis/HyperAutomation/UserGuide.vue")
  },
  {
    path: "/getting-started-with-axis/receiver-framework",
    name: "Receiver Framework - Getting started with Boomi for SAP",
    component: () =>
      import("../views/GettingStartedWithAxis/ReceiverFramework/index.vue")
  },
  {
    path: "/getting-started-with-axis/receiver-framework/user-guide",
    name: "Receiver Framework - User Guide",
    component: () =>
      import("../views/GettingStartedWithAxis/ReceiverFramework/UserGuide.vue")
  },
  {
    path: "/getting-started-with-axis/abap-proxy",
    name: "ABAP Proxy - Getting started with Boomi for SAP",
    component: () =>
      import("../views/GettingStartedWithAxis/abapProxy/index.vue")
  },
  {
    path: "/getting-started-with-axis/abap-proxy/user-guide",
    name: "ABAP Proxy - User Guide",
    component: () =>
      import("../views/GettingStartedWithAxis/abapProxy/UserGuide.vue")
  },
  // 404 Not Found
  { path: "/404", alias: "*", component: Home }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    window.scrollTo(0, 0);
  }
});

export default router;
