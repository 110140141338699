<template><div>
<div class="sect1">
<h2 class="section-headline pb-4" id="_user_guide">User Guide</h2>
<div class="sectionbody">
<div class="paragraph">
<p>The following describes how to create a <em><strong>Table Service</strong></em> in <a href="#table-service-sap-ui">SAP</a> and <a href="#table-service-boomi">Boomi AtomSphere</a>.</p>
</div>
<div class="sect2">
<h2 id="table-service-sap-ui" class="section-headline pb-4">Create Table Service in SAP</h2>
<div class="paragraph">
<p>When you enter the front page, you will find the create menu on the left side, where you choose "Table Service". The table service functionality gives you the possibility to create Table Services for use by external systems.</p>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="sect3">
<h3 id="_search_table_or_use_the_table_wizard" class="section-headline pb-4">Search Table or use the Table Wizard</h3>
<div class="paragraph">
<p>You can search for tables by using the search function in the upper left corner of the page. For example, if you search for the name ‘SKAT’, all table names containing 'SKAT' will appear.</p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/skat-search.png" alt="single-table-search" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p>You have the possibility of searching for the description of your wanted table. The asterisk symbol is usable as wildcard enabling you to search for specific text as the description.</p>
</div>
<div class="paragraph">
<p>If you want to ensure specific search, the "Strict Search option" can help you.</p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/description-search.png" alt="single-table-search-description" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p>For the 1.7 release all Tables are presented as the standard SAP tree structure in the Component View: Database Tables. This means you are able to find the table you want by unfolding the tree structure.</p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/Table-wizard.png" alt="table-wizard" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
</div>
<div class="sect3">
<h3 id="_select" class="section-headline pb-4">Select</h3>
<div class="paragraph">
<p>To select the tables, you want to use/join you must first <em><strong>drag and drop</strong></em> the desired table onto the canvas. You can now see the table&#8217;s fields.</p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/table_select.png" alt="single-table-select" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p>If you click on the <strong>'garbage can icon'</strong> in the upper right corner on the individual table you can delete the table from the canvas.</p>
</div>
<div class="paragraph">
<p>Table index information are available for some fields if you hover over the index sign.</p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/field-hover.png" alt="index-table-service" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
</div>
<div class="sect3">
<h3 id="_filter" class="section-headline pb-4">Filter</h3>
<div class="paragraph">
<p>To remove any fields from the chosen tables, click on the <strong>'filter icon'</strong> to filter the fields. It is recommended to do so to minimize the document size to Boomi.</p>
</div>
<div class="paragraph">
<p>When you click on the <strong>'filter icon'</strong> you will be redirected to a page showing the details of the chosen table. You can then de-select all fields by clicking the <strong>upper most checkbox</strong>. If you want to de-select just one or a few you can click on the <strong>'check box'</strong> to the left of the individual fields. When you have de-selected the fields you do not want to include in the table you must press on the <strong>'Confirm'</strong> button to return to the canvas.</p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/table-service-filter-fields-page.png" alt="join-service-filter-fields-page" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p>If you have de-selected any fields from the individual table, the number of fields included in the table will be shown in the upper right corner of the table when you return to the canvas.</p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/tables-multiple-filters.png" alt="join-tables-multiple-filters" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
</div>
<div class="sect3">
<h3 id="_join_types" class="section-headline pb-4">Join Types</h3>
<div class="paragraph">
<p>For the 1.7 release you are able to choose between Inner- and Outer Joins. Default the join you are making will be an Inner Join, but by pressing the joint circles, and then the Information symbol, a pop-up will appear with the options you have for join types.</p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/Join-types.png" alt="join-types" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p>If you choose outer join the position of the main Table in regards to the joint tables will dictate the direction of your outer join; left or right.</p>
</div>
<div class="paragraph">
<p>If you want to see the SQL statement Boomi for SAP are producing, then hit the "View Statement" at the bottom left corner of your screen.</p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/Outer-join.png" alt="outer-join" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
</div>
<div class="sect3">
<h3 id="_aliases" class="section-headline pb-4">Aliases</h3>
<div class="paragraph">
<p>For the 1.7 release of Boomi for SAP, joining the same table multiple times creating alias tables is an option. When adding the same table more than once, an alias of the table will be shown as depicted on the following picture.</p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/Alias.png" alt="alias" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
</div>
<div class="sect3">
<h3 id="_canvas_options" class="section-headline pb-4">Canvas Options</h3>
<div class="paragraph">
<p>In the top of the canvas you have two options:</p>
</div>
<div class="paragraph">
<p><strong>View Data</strong> = Let you preview the data you want to create a service on. You can preview data from your local system but also have the possibility to get shown data from other systems via an RFC connection.</p>
</div>
<div class="paragraph">
<p><strong>Configure</strong> = Configuring a Service</p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/canvas-options.png" alt="table-service-options" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p>For creating a single table service simply configure your service now. If you want to join tables continue through the user guide.</p>
</div>
<div class="sect4">
<h5 id="_view_data_configuration">View Data Configuration</h5>
<div class="paragraph">
<p>You are able select data from remote systems via RFC connections, but these needs to be setup.</p>
</div>
<div class="paragraph">
<p>The following guide shows how to set up connection to a remote SAP system:</p>
</div>
<div class="paragraph">
<p>These transaction er used to setup the connection:</p>
</div>
<div class="ulist">
<ul>
<li>
<p>Create a ABAP Connection in SM59 to a remote system</p>
</li>
<li>
<p>/n/ixbx/ZBX014 - Is for setting up the data system to be allowed to be called.</p>
</li>
<li>
<p>/n/ixbx/ZBX015 - Is for the calling system to setup which RFC connection that can be called</p>
</li>
</ul>
</div>
</div>
</div>
<div class="sect3">
<h3 id="_view_data_display" class="section-headline pb-4">View Data Display</h3>
<div class="paragraph">
<p>You have two option on which data you want to see:</p>
</div>
<div class="ulist">
<ul>
<li>
<p><strong>Test Local</strong> - You see the data locally (system and client you are connected to)</p>
</li>
<li>
<p><strong>Test Remote</strong> - You see the data according to the RFC connection you choose</p>
</li>
</ul>
</div>
<div class="paragraph">
<p>You can also select which specific fields or tables you want to see, or the number of rows.</p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/view-data.png" alt="view-data" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
</div>
<div class="sect3">
<h3 id="_joining_tables" class="section-headline pb-4">Joining Tables</h3>
<div class="paragraph">
<p>This section describes how to join the desired tables after they have been dragged and dropped to the canvas.</p>
</div>
<div class="paragraph">
<p>Start by clicking on the <strong>'field'</strong> you want to join on, this will cause the fields on the other table(s) to turn green or yellow.</p>
</div>
<div class="paragraph">
<p><strong>Color scale:</strong>
The color indicates whether it is possible to join and how good the join is:</p>
</div>
<div class="paragraph">
<p><strong>Green</strong> 🟢: Technical good match</p>
</div>
<div class="paragraph">
<p><strong>Yellow</strong> 🟡: Technical possible - but not a good match</p>
</div>
<div class="paragraph">
<p><strong>Note:</strong> The field you click on first will always turn red, so you should use the second field selected to determine if it is a good join.
<br></p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/join-colors.png" alt="join-tables-color-scale" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p>To join the desired fields, you must first click on one field and then click on the field you want to join it with. You will then see a grey line connecting the two fields.</p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/joined-tables.png" alt="join-table-three-joined-tables" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p>If you want to remove your join, simply click the join string and then click the left button.</p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/delete-join.png" alt="join-table-three-joined-tables-remove" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
</div>
<div class="sect3">
<h3 id="_create_service" class="section-headline pb-4">Create Service</h3>
<div class="paragraph">
<p>This section describes how to create a service.</p>
</div>
<div class="paragraph">
<p>After the desired fields are joined, click the <strong>'Configure Service’</strong> button in the upper right hand corner, and pop-up window will appear. You must then fill out the information needed to create the service. The information needed will be described below.</p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/configure-service.png" alt="service-configuration" />
</div>
</div>
<div class="paragraph">
<p><strong>Service name:</strong> To create a service you must fill in a service name.</p>
</div>
<div class="paragraph">
<p><strong>Mode:</strong> This is the mode indicating whether the service created is transportable. By selecting <em><strong>Production</strong></em> the service can be transported, meaning that you must select a transport for the service before the service can be created. By selecting <em><strong>Local</strong></em> the service created can not be transported, and therefore no transport can be selected.</p>
</div>
<div class="paragraph">
<p><strong>Transport:</strong> This is the transport that will be assigned to the service. It is possible to promote from local to production after the table service has been created.</p>
</div>
<div class="paragraph">
<p><strong>Authorization:</strong> The authorization object for the service you are about to configure with the corresponding authorization fields and values. Press on the drop down to choose the authorization object and thereafter fill out the values of the authorization fields.</p>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/service_authorization.png" alt="service-configuration" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p>If no authorization objects are assigned, no authorization is checked when calling the service. It is thereby also possible to configure the service without selecting any authorization objects.</p>
</div>
<div class="paragraph">
<p><strong>Test as user:</strong> By entering a user name it is possible to test the authorization check for a specific user. If the user exist and have the correct authorization, a green checkmark will show up.</p>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/service_auth_check.png" alt="test-user" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p>If not, an error message will appear.</p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/service-auth-fail.png" alt="test-user-no" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p><strong>Configure Services:</strong> After filling in the information explained above, you generate the service by pressing the <strong>‘Create Service’</strong> button. If the creation of the services was successful, a temporary message saying <em>‘All services were successfully created’</em> will be displayed. If you would like to return to the canvas you can click on the <strong>‘Close’</strong> button.</p>
</div>
<div class="paragraph">
<p>When the service is created you will be redirected to the <em><strong>Table Services</strong></em> page where you can find the service(s) you just created.</p>
</div>
<div class="paragraph">
<p><br></p>
</div>
</div>
</div>
<div class="sect2">
<h2 id="_active_services" class="section-headline pb-4">Active Services</h2>
<div class="paragraph">
<p>This section describes how to find active services.</p>
</div>
<div class="paragraph">
<p>By clicking on <em><strong>Table Services</strong></em> you will be directed to the <em><strong>Table Services list</strong></em>, where configured services are shown.</p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/table-services.png" alt="table-services" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="sect3">
<h3 id="_search" class="section-headline pb-4">Search</h3>
<div class="paragraph">
<p>You can search for table services by using the search function in the upper right corner of the page. If you for example search for the name <em>‘SKAT’</em>, all table services containing <em>'SKAT'</em> will appear.</p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/table-services-search.png" alt="table-services-search" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p><strong>Table Service:</strong> The table service is the service configured containing one or more tables.</p>
</div>
<div class="paragraph">
<p><strong>Table name:</strong> The table name is the name of the table(s) included in the service.</p>
</div>
<div class="paragraph">
<p><strong>Fields:</strong> The fields indicate how many fields that are included in the table service. The number is thereby a sum of all the fields included in the service.</p>
</div>
<div class="paragraph">
<p><strong>Authorization:</strong> Shows the authorization object if it was specified during the service configuration.</p>
</div>
<div class="paragraph">
<p><strong>Details:</strong> If you want to see the details of the table(s) and fields in the service, you can click on the table icon. A pop-up window will then appear showing the details of each table(s) and the fields related to the table(s).</p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/table-service-details.png" alt="table-service-details" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p>In the above example, the table service includes the table <em>'SKAT'</em>. It is also possible to search through the fields inside the table service, by using the search field in the upper right corner. To go back to the <strong>'Table Services'</strong> page you must click on the <strong>'Close'</strong> button.</p>
</div>
<div class="paragraph">
<p><strong>View/Edit:</strong> By clicking the view service icon, you are brought to a page where you can edit and update your table service, by selecting and de-selecting fields and reconfigure how the tables should be joined.</p>
</div>
<div class="paragraph">
<p><strong>Mode:</strong> This is the mode indicating whether the service created is transportable or local. <em><strong>Production</strong></em> means that the service can be transported. If the mode is <em><strong>Local</strong></em>, the service cannot be transported.</p>
</div>
<div class="paragraph">
<p><strong>Created on:</strong> The date the table services were created on. You can hover your cursor over the date to see by which user, and at what time the creation took place.</p>
</div>
<div class="paragraph">
<p><strong>Changelog:</strong> Clicking the <em>changelog</em> icon opens a pop-up window with information about the creation and edits made to the Table Service. An example can be seen below.</p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/table-services-changelog.png" alt="table-services-changelog" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p><strong>Remove:</strong> If you click on the garbage can icon the table service will be deleted from the list.</p>
</div>
<div class="paragraph">
<p>After finding or creating the service in Boomi for SAP you can use Boomi AtomSphere and the <em><strong>Boomi for SAP</strong></em> connector to import, map and share the service with other systems.</p>
</div>
<div class="paragraph">
<p>To do this log onto <a href="https://platform.boomi.com">Boomi AtomSphere</a> and follow the instructions below.</p>
</div>
</div>
</div>
<div class="sect2">
<h2 id="table-service-boomi" class="section-headline pb-4">Boomi AtomSphere</h2>
<div class="paragraph">
<p>This section describes how to import the services created above into Boomi.</p>
</div>
<div class="paragraph">
<p>The <em><strong>Boomi for SAP connector</strong></em> can either be used in a start shape to begin a process, or as a connection shape later in a process by searching for the connector and dragging it onto the canvas. The following sections show how to create a new process using the <em><strong>Boomi for SAP</strong></em> in a start shape. However, the options are the same in both cases.</p>
</div>
<div class="paragraph">
<p>When you enter the Boomi platform you should click on the <strong>'Process'</strong> button in the canvas or create a <em><strong>New Component</strong></em> within a folder.</p>
</div>
<div class="paragraph">
<p>You must then fill out the different fields shown on the start shape page.</p>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/boomi.png" alt="boomi" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p>Start out by writing a <em>'Display Name'</em>. After that, you should click on the drop-down arrow to select the connector <em><strong>Boomi for SAP</strong></em>. The action will then change because of the chosen connection.</p>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/boomi-connector-new.png" alt="boomi-connector-new" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p>By choosing <em><strong>Boomi for SAP</strong></em>, the action should by default be set to <em><strong>QUERY</strong></em>.</p>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/boomi-connector-query-new.png" alt="boomi-connector-query-new" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="sect3">
<h3 id="_connection" class="section-headline pb-4">Connection</h3>
<div class="paragraph">
<p>This section describes the process of how to connect to <em><strong>Boomi for SAP</strong></em>.</p>
</div>
<div class="paragraph">
<p>You must create a new connector or search for and select an existing connector.</p>
</div>
<div class="paragraph">
<p>Boomi licenses are based on consumed connections by runtime (ATOM/MOLECULE) used per atom, so reuse the configured connection as much as possible per runtime.</p>
</div>
<div class="paragraph">
<p>If a connection has already been created, click on the field and select the connection placed in your folder.
If it is not visible you can search for the connection in the text field.</p>
</div>
<div class="paragraph">
<p>To create a new connection you must click on the small <strong>'green plus icon'</strong>.</p>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/boomi-connection-green-icon.png" alt="boomi-connection-green-icon" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p>Then fill in the different fields shown on the connection page. If you don&#8217;t know the answers, reach out to the SAP team within your organization. When all the fields have been filled out you must click on the <strong>'Save and Close'</strong> button to get back.</p>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/boomi-connector-username.png" alt="boomi-connector-username" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
</div>
<div class="sect3">
<h3 id="_operation" class="section-headline pb-4">Operation</h3>
<div class="paragraph">
<p>You now must select, create, or search for the connector operator.</p>
</div>
<div class="paragraph">
<p>If an operation has already been created, the connector operation on the specific account, you can just click on the field and select the operator placed in your folder.</p>
</div>
<div class="paragraph">
<p>If it is not visible you can search for the connector operation in the text field.</p>
</div>
<div class="paragraph">
<p>To create a new connector operation, you should click on the small <strong>'green plus icon'</strong> within the operation field.</p>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/boomi-operator.png" alt="boomi-operator" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p>You will then be redirected to the page where you can create the connector operation.</p>
</div>
<div class="paragraph">
<p>To easily find the operation in the future, you should add the name of the service you created earlier in the operation name field in the upper left corner. In this example we named the operation <em>'Get SKAT'</em> because it imports the table named <em>'SKAT'</em>. The service was previously created in Boomi for SAP.</p>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/boomi-operator-import-page-new.png" alt="boomi-operator-import-page-new" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p>Then click on the <strong>'Import'</strong> button to query the objects. A pop-up window will then appear.</p>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/boomi-operator-import-wizard.png" alt="boomi-operator-import-wizard" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p>You must then choose an <em><strong>Atom</strong></em> to run the operation by clicking on the drop-down.</p>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/boomi-operator-import-atom.png" alt="boomi-operator-import-atom" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p>After that you must choose a connection. You can either create a new one, search for one or select a recently used connection.</p>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/boomi-operator-import-connection.png" alt="boomi-operator-import-connection" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p>When you have selected the atom and the connection, click on the <strong>'Next'</strong> button to query the objects. The atom will then connect you to object types. You must then click on the drop-down and click on the desired <em><strong>Object Type</strong></em>.</p>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/boomi-operator-import-objecttype.png" alt="boomi-operator-import-objecttype" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p>Alternatively, if you click on the <strong>'Back'</strong> button you can use filters with wildcard characters to reduce the number of returned objects.</p>
</div>
<div class="paragraph">
<p>The filter allows you to limit the objects (Services) by searching for a specific value in the service name. You can use * to search for any value. If you want to find tables ending in <em>'SKAT'</em> you should search on *SKAT. Or if you want to find all services that contain <em>'SKAT'</em> somewhere in the title search on: *SKAT*.</p>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/boomi-operator-import-skat-filter.png" alt="boomi-operator-import-skat-filter-" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p>When you have entered the desired filter for example <strong>*SKAT*</strong> click on the <strong>'Next'</strong> button. The object type shown will then contain the word <em>'SKAT'</em>.</p>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/boomi-operator-import-skat-filter-list.png" alt="boomi-operator-import-skat-filter-list" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p>When you have selected the desired object type click on the <strong>'Next'</strong> button. A new page will then appear showing the object type and the associated response profile. You should then click on the <strong>'Finish'</strong> button to get back.</p>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/boomi-operator-import-skat-finish.png" alt="boomi-operator-import-skat-finish" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p>You will now see that your operator imports the service selected with an associated response profile. Click on the <strong>'Save and Close'</strong> button to get back to the start shape page. To finish the configuration, click on the <strong>'OK'</strong> button.</p>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/boomi-operator-get-skat-object.png" alt="boomi-operator-get-skat-object" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p>When you have created the operation, you can pick and choose fields, add a filter and/or sort criteria, which will be applied when the service loads SAP data into Boomi.
<strong>Note:</strong> the filtering happens on the SAP side, so you are not exhausting the ATOM with the filtering task.</p>
</div>
<div class="paragraph">
<p>In the bottom of the page where you just imported the SKAT object you will find <em><strong>Fields</strong></em>, <em><strong>Filters</strong></em> and <em><strong>Sorts</strong></em>. The following sections describe the fields, filters and sort criteria.</p>
</div>
</div>
<div class="sect3">
<h3 id="_fields" class="section-headline pb-4">Fields</h3>
<div class="paragraph">
<p>Clicking on the <em>'plus'</em> icon next to the previously chosen Table Service will unfold it and show the fields inside, as shown in the example below.</p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/boomi-operator-fields.png" alt="boomi-operator-fields" />
</div>
</div>
<div class="paragraph">
<p>In the <em><strong>Fields</strong></em> tab you can select and de-select each field using the checkboxes, and thereby specify the fields that should be returned from SAP backend.</p>
</div>
</div>
<div class="sect3">
<h3 id="_filter_2" class="section-headline pb-4">Filter</h3>
<div class="paragraph">
<p>The <em><strong>QueryFilter</strong></em> supports up to 10 levels of nesting, where the groupings will be AND&#8217;ed and OR’ed together. Note: Nested OR statements must have same field name in all fields, within each Logical OR group. The following describes how to filter by adding an AND&#8217;ed expression group.</p>
</div>
<div class="paragraph">
<p>Click on the <strong>'arrow'</strong> under filter to <strong>'Add Logical Sub-Group'</strong>.</p>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/boomi-operator-filter-add.png" alt="boomi-operator-filter-add" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p>A new field will appear where you can click on the <strong>'arrow'</strong> and choose <em><strong>Add Expression</strong></em>. The operator to the right will by default be <em><strong>AND</strong></em>.</p>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/boomi-operator-filter-add-expression.png" alt="boomi-operator-filter-add-expression" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p>To add an expression, choose a field by using the drop-down. Click on the desired field and click then on the <strong>'OK'</strong> button.</p>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/boomi-operator-filter-field.png" alt="boomi-operator-filter-field" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p>After that, a new operator field will appear. You should then click on the drop-down to choose the operator. In this example <em><strong>SKAT.KTOPL</strong></em> is chosen with the operator <em><strong>Greater Than</strong></em>.</p>
</div>
<div class="paragraph">
<p>Then you must ensure to write a <em>'Filter Name'</em> that reflects the chosen field and operator. This will make it easier to find the expression later in the process.</p>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/boomi-operator-filter-operator-greaterthan.png" alt="boomi-operator-filter-operator-greaterthan" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p>If you want to add a second expression click on the <strong>'arrow'</strong> beside the expression you already made and after that you should just follow the steps described above.</p>
</div>
<div class="paragraph">
<p>After you have created one or more expressions click on <strong>'Save and Close'</strong>.</p>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/boomi-operator-filter-second.png" alt="boomi-operator-filter-second" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p>You will be redirected to the canvas, then click on <strong>'(Parameters 0 of 2 set)'</strong></p>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/boomi-parameters-single.png" alt="boomi-parameters-single" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p>A new page will then open to the right, click on the small <strong>'green plus icon'</strong> to set the parameters.</p>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/boomi-filter-parameters-plus-icon-new.png" alt="boomi-filter-parameters-plus-icon-new" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p>A pop-up window will then appear where you must choose the <em><strong>Input</strong></em> you just created as an expression.</p>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/boomi-filter-parameters-input.png" alt="boomi-filter-parameters-input" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p>You can then compare your filter criteria with a variety of different values, for example the current date, a static value, or a process property. For a full list visit <a href="https://help.boomi.com/bundle/integration/page/c-atm-Parameter_Values.html">parameter values.</a></p>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/boomi-parameter-value-type.png" alt="boomi-parameter-value-type" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p>In this example two different type have been chosen:</p>
</div>
<div class="paragraph">
<p><em><strong>Dynamic Process Property</strong></em> and <em><strong>Static</strong></em>. You will need to do different things based on which type you choose before clicking the <strong>'OK'</strong> button to return.</p>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/boomi-parameter-value.png" alt="boomi-parameter-value" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/boomi-parameter-value-second.png" alt="boomi-parameter-value-second" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p>When you are back at the start shape page click on the <strong>'OK'</strong> button to return to the canvas.</p>
</div>
</div>
<div class="sect3">
<h3 id="_sorts" class="section-headline pb-4">Sorts</h3>
<div class="paragraph">
<p>Click on <strong>'Sorts'</strong> beside the <strong>'Filters'</strong> in the bottom of the operation page. Two big boxes will then show up.</p>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/boomi-sorts.png" alt="boomi-sorts" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p>Click on the <strong>'plus icon'</strong> to be able to select the field you want to sort. By clicking on the field, you will be able to select the field you want to sort. After you have chosen the desired field click the <strong>'OK'</strong> button.</p>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/boomi-sorts-field.png" alt="boomi-sorts-field" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p>When you have chosen the desired field, you will be able to select an order which can either be <em><strong>Ascending</strong></em> or <em><strong>Descending</strong></em></p>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/boomi-sorts-order.png" alt="boomi-sorts-order" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p>You can add as many fields you want to sort. When you are done adding fields to sort click the <strong>'Save and Close'</strong> button.</p>
</div>
<div class="paragraph">
<p><strong>Note:</strong> From the canvas you will be able to configure the shape. If you want to make changes in the connection or the operation you can do that directly from the canvas. For example, if you click the <strong>'Get SKAT'</strong> you will be redirected to the configuration of the operation.</p>
</div>
</div>
<div class="sect3">
<h3 id="_number_of_rows" class="section-headline pb-4">Number of Rows</h3>
<div class="paragraph">
<p>If the process you&#8217;re using is residing on a Boomi cloud atom, please keep the number of returned documents per connector operation below 1000 and keep the returned data below 1 MB. If you&#8217;re using an on-premise atom with more processing power, this number can be increased.</p>
</div>
<div class="paragraph">
<p>The <em><strong>Max Returned Rows</strong></em> setting in the Connector Operation is useful if you always at most need a fixed number of rows, e.g., the first result, or want to limit the number of rows returned during development and testing.</p>
</div>
</div>
<div class="sect3">
<h3 id="_document_properties" class="section-headline pb-4">Document Properties</h3>
<div class="paragraph">
<p>The <em><strong>QUERY</strong></em> operation for Table Services has two related Document Properties. The properties are <em><strong>legacy properties</strong></em> and their usefulness has been replaced by new features (Table Service Extract).</p>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/boomi-document-properties.png" alt="boomi-document-properties" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p><em><strong>Boomi for SAP - Count</strong></em> Overrides the operation&#8217;s <em><strong>Max Returned Rows</strong></em> setting. It can be used to dynamically limit the number of returned rows.</p>
</div>
<div class="paragraph">
<p><em><strong>Boomi for SAP - Offset</strong></em> Allows a developer to get results after a set number of rows.</p>
</div>
<div class="paragraph">
<p>Please note that while these two properties will allow you to page through Table Service results, it is not recommended for both Boomi and SAP performance reasons. There&#8217;s also a risk of skipping data if data is updated or created during an extract. For large queries that require paging, use the Table Service Extract functionality which both provides better performance and no risk of skipping data.</p>
</div>
</div>
</div>
</div>
</div>
</div></template>
<script>export default {}; </script>