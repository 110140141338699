<template>
  <div class="subpage-wrapper">
    <div class="header-banner">
      <div class="image-wrapper">
        <v-img alt="Boomi for SAP core" contain src="@/graphics/birds.svg" />
      </div>
      <v-container class="content">
        <h1>Hyper Automation User Guide</h1>
      </v-container>
    </div>
    <v-container class="content-section">
      <v-card width="100%" class="pa-10 mb-10">
        <UserGuide />
        <boomiProcess />
      </v-card>
    </v-container>
  </div>
</template>

<script>
import Vue from "vue";
import { Component } from "vue-property-decorator";
import UserGuide from "./_UserGuide.vue";
@Component({ components: { UserGuide } })
export default class userGuide extends Vue {}
</script>

<style scoped lang="scss">
.header-banner .image-wrapper ::v-deep .v-image__image--contain {
  height: 750px;
  transform: translate(-580px, -140px);
}
</style>
