<template>
  <div class="subpage-wrapper">
    <div class="header-banner">
      <div class="image-wrapper">
        <v-img
          alt="Boomi for SAP"
          contain
          src="@/graphics/birds.svg"
        />
      </div>
      <v-container class="content">
        <h1>BW Data Extractor Configuration</h1>
      </v-container>
    </div>
    <v-container class="content-section">
      <v-card
        width="100%"
        class="pa-10 mb-10"
      >
        <Configuration />
      </v-card>
    </v-container>
  </div>
</template>

<script>
import Vue from "vue";
import { Component } from "vue-property-decorator";
import Configuration from "./_Configuration.vue";
@Component({ components: { Configuration } })
export default class index extends Vue {
}
</script>

<style scoped lang="scss">
.header-banner .image-wrapper ::v-deep .v-image__image--contain {
  height: 750px;
  transform: translate(-580px, -140px);
}
</style>