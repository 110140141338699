<template><div>
<div id="toc" class="toc">
<div id="toctitle">Table of Contents</div>
<ul class="sectlevel1">
<li><a href="#_introduction">1. Introduction</a></li>
<li><a href="#_system_check">2. System check</a></li>
<li><a href="#_connectivity">3. Connectivity</a></li>
<li><a href="#_creation_of_users">4. Creation of users</a>
<ul class="sectlevel2">
<li><a href="#_wf_batch_user">4.1. WF-batch User</a></li>
<li><a href="#_system_runtime">4.2. System Runtime</a></li>
<li><a href="#_dialog_users">4.3. Dialog User(s)</a></li>
</ul>
</li>
<li><a href="#_verification_of_ui">5. Verification of UI</a></li>
<li><a href="#_check_development_system_is_set_for_development">6. Check Development System is set for development</a></li>
<li><a href="#_poc_challenges">7. POC challenges</a></li>
<li><a href="#_sap_installation">8. SAP installation</a>
<ul class="sectlevel2">
<li><a href="#_connectivity_to_sap_from_boomi">8.1. Connectivity to SAP from Boomi</a></li>
<li><a href="#notfound">8.2. 404 Not Found</a></li>
<li><a href="#advanceddebugging">8.3. Advanced Connection Debugging</a></li>
</ul>
</li>
<li><a href="#_connectivity_to_boomi_from_sap">9. Connectivity to Boomi from SAP</a></li>
<li><a href="#_browser_if_loading_of_lists_fails_in_boomi_from_sap_ui">10. Browser: If loading of lists fails in Boomi from SAP UI</a></li>
</ul>
</div>
<div id="preamble">
<div class="sectionbody">
<div id="expansionPanelContainer" class="paragraph">
<p>placeholder</p>
</div>
</div>
</div>
<div class="sect1">
<h2 class="section-headline pb-4" id="_introduction">1. Introduction</h2>
<div class="sectionbody">
<div class="paragraph">
<p>This document is meant to be a loose get started document, as to make sure all roles and prerequisites are in place for a successful Boomi for SAP POC/Pilot.</p>
</div>
<div class="paragraph">
<p>Readers of this document could be, but are not limited to:</p>
</div>
<div class="ulist">
<ul>
<li>
<p>SAP Basis team</p>
</li>
<li>
<p>Firewall admins</p>
</li>
<li>
<p>Functional Consultants</p>
</li>
<li>
<p>Boomi Developers / Atom Admins</p>
</li>
</ul>
</div>
</div>
</div>
<div class="sect1">
<h2 class="section-headline pb-4" id="_system_check">2. System check</h2>
<div class="sectionbody">
<div class="paragraph">
<p>(Skip to connectivity section if you have already Boomi for SAP installed)</p>
</div>
<div class="paragraph">
<p>To ensure the system requirements are meet for Boomi for SAP installation, we would like to receive a screenshot of:</p>
</div>
<div class="ulist">
<ul>
<li>
<p>SAP_BASIS</p>
</li>
<li>
<p>SAP_ABA</p>
</li>
<li>
<p>SAP_GWFND</p>
</li>
<li>
<p>SAP_UI</p>
</li>
</ul>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/SapSystemStatus.png" alt="SapSystemStatus" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p>Product version:</p>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/SapSystemData.png" alt="SapSystemData" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p>Provide us a screenshot of this:</p>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/SapSystemScreenShot.png" alt="SapSystemScreenShot" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
</div>
</div>
<div class="sect1">
<h2 class="section-headline pb-4" id="_connectivity">3. Connectivity</h2>
<div class="sectionbody">
<div class="paragraph">
<p>All connectivity is using the HTTP protocol.</p>
</div>
<div class="paragraph">
<p>When SAP is initiating the connection, it uses RFC (type G) connections, and sends to a web service deployed on Boomi Atom(s).</p>
</div>
<div class="paragraph">
<p>SAP &#8594; Boomi Atom (SM59)</p>
</div>
<div class="ulist">
<ul>
<li>
<p>Ports (usually port 9090 on the Atom)</p>
</li>
<li>
<p>Certificates may need to be trusted in STRUST (if not trusted)</p>
</li>
</ul>
</div>
<div class="paragraph">
<p>When Boomi is initiating the connection, it connects to the HTTP(S) ports of SAP.</p>
</div>
<div class="paragraph">
<p>Boomi Atom &#8594; SAP</p>
</div>
<div class="ulist">
<ul>
<li>
<p>SAP Ports (check in SMICM &#8594; services for http(s) ports)</p>
</li>
<li>
<p>Certificates (if not trusted by Atom, can be added in Boomi for SAP SAP connection as Server Certificates)</p>
</li>
</ul>
</div>
<div class="paragraph">
<p>Open FW both ways between SAP and Boomi Atoms.</p>
</div>
</div>
</div>
<div class="sect1">
<h2 class="section-headline pb-4" id="_creation_of_users">4. Creation of users</h2>
<div class="sectionbody">
<div class="sect2">
<h2 id="_wf_batch_user" class="section-headline pb-4">4.1. WF-batch User</h2>
<div class="paragraph">
<p>Create <strong>WF-batch User</strong> if not already created.</p>
</div>
<div class="paragraph">
<p>Check in SWU3 if the Workflow user is configured.</p>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/WFBatchUser.png" alt="WFBatchUser" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
</div>
<div class="sect2">
<h2 id="_system_runtime" class="section-headline pb-4">4.2. System Runtime</h2>
<div class="paragraph">
<p>Create a Boomi <strong>Runtime</strong> user in data client for access from Boomi atoms, granting needed roles.</p>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/RuntimeUser.png" alt="RuntimeUser" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
</div>
<div class="sect2">
<h2 id="_dialog_users" class="section-headline pb-4">4.3. Dialog User(s)</h2>
<div class="paragraph">
<p>Create <strong>Dialog User(s)</strong> with SAP_ALL (only recommended in Development)</p>
</div>
<div class="paragraph">
<p>+ Boomi for SAP roles = add /IXBX/BOOMI_FOR_SAP_ALL:</p>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/DialogUser.png" alt="DialogUser" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
</div>
</div>
</div>
<div class="sect1">
<h2 class="section-headline pb-4" id="_verification_of_ui">5. Verification of UI</h2>
<div class="sectionbody">
<div class="paragraph">
<p>Run transaction /n/ixbx/zbx001 or /n/ixbx/start_UI from the development client.</p>
</div>
<div class="paragraph">
<p>This will open a browser the user can use to configure Boomi for SAP services. Log in with your normal SAP user.</p>
</div>
</div>
</div>
<div class="sect1">
<h2 class="section-headline pb-4" id="_check_development_system_is_set_for_development">6. Check Development System is set for development</h2>
<div class="sectionbody">
<div class="paragraph">
<p>Ensure the development client setting is correct to run Boomi for SAP UI.</p>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/VerificationOfUI.png" alt="VerificationOfUI" />
</div>
</div>
</div>
</div>
<div class="sect1">
<h2 class="section-headline pb-4" id="_poc_challenges">7. POC challenges</h2>
<div class="sectionbody">
<div class="paragraph">
<p>Beneath you will find earlier experiences and solutions when setting up Boomi for SAP.</p>
</div>
</div>
</div>
<div class="sect1">
<h2 class="section-headline pb-4" id="_sap_installation">8. SAP installation</h2>
<div class="sectionbody">
<div class="sect2">
<h2 id="_connectivity_to_sap_from_boomi" class="section-headline pb-4">8.1. Connectivity to SAP from Boomi</h2>
<div class="paragraph">
<p>Observed error messages when testing the connection in Boomi to SAP:</p>
</div>
<div class="ulist">
<ul>
<li>
<p>401: Unauthorized.</p>
</li>
<li>
<p>Resolution: <a href="#authentication">Force Basic authentication from Boomi</a></p>
</li>
<li>
<p>Unexpected Character: Unexpected character ('&lt;'&#8230;&#8203;)</p>
</li>
<li>
<p>Resolution: <a href="#authentication">Force Basic authentication from Boomi</a></p>
</li>
<li>
<p>404: Not found Error</p>
</li>
<li>
<p>Resolution: <a href="#notfound">404 Not Found</a></p>
</li>
</ul>
</div>
<div class="paragraph">
<p>If none of the resolutions work, perform advanced debugging: <a href="#advanceddebugging">Advanced Connection Debugging</a>.</p>
</div>
<div class="sect3">
<h3 id="authentication" class="section-headline pb-4">Force Basic authentication from Boomi</h3>
<div class="paragraph">
<p>Error messages:</p>
</div>
<div class="ulist">
<ul>
<li>
<p>401: Unauthorized</p>
</li>
<li>
<p>Unexpected Character: Unexpected character ('&lt;'&#8230;&#8203;)</p>
</li>
</ul>
</div>
<div class="paragraph">
<p>Both messages have been observed to happen due to misconfiguration of authentication.</p>
</div>
<div class="paragraph">
<p>In case of the "Unexpected Character" error message, Boomi is most likely receiving HTML when it&#8217;s expecting JSON.</p>
</div>
<div class="paragraph">
<p>This can be caused by the SSO set-up expecting interactive log in.</p>
</div>
<div class="paragraph">
<p>The possible solutions below should be attempted one at a time. The solutions are list in order of increasing complexity.</p>
</div>
<div class="paragraph">
<p>If SAP is set-up to use other Logon Procedures than Basic Authentication, they might take precedence in SAP. <a href="https://help.sap.com/doc/329ac769552a411b97bc7adb991b6197/3.0.12/en-US/713a1ed2bb6142b097338abd38dae1ad.html">SAP Documentation of the issue</a>.</p>
</div>
<div class="paragraph">
<p>Try to force SAP to accept Basic authentication by enabled the following options one at a time:</p>
</div>
<div class="olist arabic">
<ol class="arabic">
<li>
<p>Enable Preemptive authentication</p>
</li>
<li>
<p>Add the following parameter in the Boomi connector: spnego = disabled</p>
</li>
<li>
<p>Add the following parameter in the Boomi connector: saml2 = disabled</p>
</li>
</ol>
</div>
<div class="imageblock">
<div class="content">
<img src="./graphics/boomi-connector-disable-spnego.png" alt="boomi connector disable spnego">
</div>
</div>
</div>
<div class="sect3">
<h3 id="_prioritize_authentication_mode_in_sap" class="section-headline pb-4">Prioritize Authentication Mode in SAP</h3>
<div class="paragraph">
<p>The authentication type must be set to Basic.</p>
</div>
<div class="paragraph">
<p>Modify the SICF service 'ZBX' in the BR2 system in order to prioritize Basic authentication.</p>
</div>
<div class="imageblock">
<div class="content">
<img src="./graphics/service-config.png" alt="service config">
</div>
</div>
<div class="paragraph">
<p>Move 'Basic Authentication' option to the top of the list or delete the other options.</p>
</div>
<div class="imageblock">
<div class="content">
<img src="./graphics/service-config-order.png" alt="service config order">
</div>
</div>
</div>
</div>
<div class="sect2">
<h2 id="notfound" class="section-headline pb-4">8.2. 404 Not Found</h2>
<div class="paragraph">
<p>Example of an error message:</p>
</div>
<div class="listingblock">
<div class="content">
<pre class="highlight"><code>Connection Failed; Caused by: [404] Not found Error; host/url misconfiguration or mismatch between connector version and backend version.
 Connector version:171-d558055-v1.1-Hanne Backend version: -(-/-)</code></pre>
</div>
</div>
<div class="paragraph">
<p>The error message is different depending on the root cause.</p>
</div>
<div class="paragraph">
<p>Error message variants and possible causes:</p>
</div>
<div class="ulist">
<ul>
<li>
<p>[404] <strong>Extension</strong> Not found Error &#8230;&#8203;</p>
<div class="ulist">
<ul>
<li>
<p>Incorrect Boomi for SAP installation. Does Boomi for SAP UI work? Open transaction /n/IXBX/ZBX001 or append /zbx to the SAP URL and open it in a browser. If you get an error, verify that Boomi for SAP is installed correct (e.g., correct SICF handler)</p>
</li>
</ul>
</div>
</li>
<li>
<p>[404] Not found Error &#8230;&#8203; Backend version: <strong>-(-/-)</strong></p>
<div class="ulist">
<ul>
<li>
<p>No connection to Boomi for SAP. If the back end version is blank, -(-/-), Boomi is not connecting to Boomi for SAP. Check host/port/url.</p>
</li>
</ul>
</div>
</li>
<li>
<p>[404] Not found Error &#8230;&#8203; Backend version: <strong>(NOT blank)</strong>.</p>
<div class="ulist">
<ul>
<li>
<p>Incompatible Boomi for SAP core and Boomi for SAP connector versions. Update Boomi for SAP Core and/or the connector</p>
</li>
</ul>
</div>
</li>
</ul>
</div>
</div>
<div class="sect2">
<h2 id="advanceddebugging" class="section-headline pb-4">8.3. Advanced Connection Debugging</h2>
<div class="paragraph">
<p>(Not possible for Boomi Cloud infrastructure.)</p>
</div>
<div class="paragraph">
<p>To see the raw http traffic between Boomi and SAP, follow these steps:</p>
</div>
<div class="olist arabic">
<ol class="arabic">
<li>
<p>Enable Connector Trace Logging <a href="https://help.boomi.com/bundle/integration/page/t-atm-Enabling_trace_logging_for_various_connectors.html">Boomi guide</a></p>
<div class="olist loweralpha">
<ol class="loweralpha" type="a">
<li>
<p>Requires atom restart.</p>
</li>
</ol>
</div>
</li>
<li>
<p>Make a connection test to SAP with the Boomi for SAP connector.</p>
</li>
<li>
<p>Download the container log and inspect the http traffic.</p>
<div class="olist loweralpha">
<ol class="loweralpha" type="a">
<li>
<p>For molecules, the container log of the head node normally contains the data from the connection test.</p>
</li>
</ol>
</div>
</li>
</ol>
</div>
</div>
</div>
</div>
<div class="sect1">
<h2 class="section-headline pb-4" id="_connectivity_to_boomi_from_sap">9. Connectivity to Boomi from SAP</h2>
<div class="sectionbody">
<div class="paragraph">
<p>The result of a 'Connection Test' in the SM59 RFC does not have to result in a 200 OK to be successful.</p>
</div>
<div class="paragraph">
<p>The first step should be to look in the Shared Server Logs in Boomi to see if the connection was established.</p>
</div>
<div class="paragraph">
<p>Possibly successful results:</p>
</div>
<div class="olist arabic">
<ol class="arabic">
<li>
<p>404/405 Not Found/Method not allowed: No Boomi process is deployed to the Atom at the URL with a GET method (Boomi for SAP receiver processes use POST)</p>
</li>
</ol>
</div>
<div class="paragraph">
<p>Unsuccessful results and their possible solutions:</p>
</div>
<div class="olist arabic">
<ol class="arabic">
<li>
<p>400 Bad Request</p>
<div class="olist loweralpha">
<ol class="loweralpha" type="a">
<li>
<p>Verify that the RFC SSL active/inactive status matches the port and setting in Boomi.</p>
</li>
<li>
<p>E.g., an RFC on port 443 with SSL inactive connecting to an SSL port on a Boomi atom can result in http code 400.</p>
</li>
</ol>
</div>
</li>
<li>
<p>401 Unauthorized</p>
<div class="olist loweralpha">
<ol class="loweralpha" type="a">
<li>
<p>Verify that the Boomi web service user name and password used in the RFC are correct.</p>
</li>
<li>
<p>Verify that the Boomi web service user is allowed to call the web service in question (Use Process Filtering section in Boomi)</p>
</li>
<li>
<p>Verify that the Boomi web service user is allowed to call the web service from the SAP IP (IP Filter section in Boomi)</p>
</li>
</ol>
</div>
</li>
</ol>
</div>
</div>
</div>
<div class="sect1">
<h2 class="section-headline pb-4" id="_browser_if_loading_of_lists_fails_in_boomi_from_sap_ui">10. Browser: If loading of lists fails in Boomi from SAP UI</h2>
<div class="sectionbody">
<div class="olist arabic">
<ol class="arabic">
<li>
<p>If browser keeps loading the Business Object list</p>
<div class="olist loweralpha">
<ol class="loweralpha" type="a">
<li>
<p>Try to clear local storage in browser, Press 'F12', choose the network tab, find local storage, right click and press clear.</p>
</li>
<li>
<p>Verify that list loads corretly after a purge of the local storage.</p>
</li>
</ol>
</div>
</li>
</ol>
</div>
</div>
</div>
</div></template>
<script>export default {}; </script>