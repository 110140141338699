<template><div>
<div id="toc" class="toc">
<div id="toctitle"></div>
<ul class="sectlevel1">
<li><a href="#_boomi_for_sap_v1_7">Boomi for SAP v1.7</a></li>
<li><a href="#_boomi_for_sap_v1_6">Boomi for SAP v1.6</a></li>
<li><a href="#_axis_for_sap_v1_5">aXis for SAP v1.5</a></li>
<li><a href="#_boomi_axis_v1_4">Boomi aXis v1.4</a></li>
<li><a href="#_boomi_axis_v1_3">Boomi aXis v1.3</a></li>
<li><a href="#_boomi_axis_v1_2">Boomi aXis  v1.2</a></li>
<li><a href="#_boomi_axis_v1_1">Boomi aXis v1.1</a></li>
<li><a href="#_boomi_axis_v1_0">Boomi aXis v1.0</a></li>
</ul>
</div>
<div id="preamble">
<div class="sectionbody">
<div class="paragraph">
<p><br></p>
</div>
<div style="page-break-after: always;"></div>
</div>
</div>
<div class="sect1">
<h2 class="section-headline pb-4" id="_boomi_for_sap_v1_7">Boomi for SAP v1.7</h2>
<div class="sectionbody">
<div class="sect2">
<h2 id="_major_enhancements" class="section-headline pb-4">Major Enhancements</h2>
<div class="sect3">
<h3 id="_ability_to_use_outer_joins" class="section-headline pb-4">Ability to use Outer Joins</h3>
<div class="paragraph">
<p>Inner and outer Joins are now supported in Boomi for SAP. The UI has been updated to support the use of outer joins and Aliases. Plus a new SQL statement viewer makes the statement Boomi for SAP generate more transparent.</p>
</div>
</div>
<div class="sect3">
<h3 id="_table_service_wizard" class="section-headline pb-4">Table Service Wizard</h3>
<div class="paragraph">
<p>Just as the Function Module wizard you are now able to search through the SAP standard tree structure of Tables to better visualize the Functional grouping of tables.</p>
</div>
</div>
</div>
<div class="sect2">
<h2 id="_minor_enhancements_and_fixes" class="section-headline pb-4">Minor Enhancements and Fixes</h2>
<div class="paragraph">
<p>Backend and performance Enhancements to the Receiver Framework</p>
</div>
<div class="sect3">
<h3 id="_connector" class="section-headline pb-4">Connector</h3>
<div class="ulist">
<ul>
<li>
<p>New operation: LISTEN</p>
</li>
</ul>
</div>
<div class="paragraph">
<p>&#8201;&#8212;&#8201;Allows the connector to browse websocket EDA subscriptions, build response profiles, and connect to corresponding websocket servers identified through browse. Supports JSON and XML schemas.</p>
</div>
<div class="paragraph">
<p>&#8201;&#8212;&#8201;Once connected, the connector listens to any message sent to the subscription&#8217;s receiver and simply submits to downstream processes. If connection fails, one error is submitted and an indefinite retry loop is started.</p>
</div>
<div class="paragraph">
<p>&#8201;&#8212;&#8201;Important: SAP will send events to the websocket server regardless of listening connectors. Ie if no connector is listening, the event may be lost.</p>
</div>
<div class="paragraph">
<p>&#8201;&#8212;&#8201;Please refer to installation and user guides for further details.</p>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div style="page-break-after: always;"></div>
</div>
</div>
</div>
</div>
<div class="sect1">
<h2 class="section-headline pb-4" id="_boomi_for_sap_v1_6">Boomi for SAP v1.6</h2>
<div class="sectionbody">
<div class="sect2">
<h2 id="_major_enhancements_2" class="section-headline pb-4">Major Enhancements</h2>
<div class="paragraph">
<p><strong>Receiver Framework</strong></p>
</div>
<div class="paragraph">
<p>Introducing the Receiver Framework in this latest release, seamlessly integrated into the Boomi for SAP UI for enhanced operational convenience.
This feature empowers users with the ability to create custom receivers, offering a personalized touch to your data management.</p>
</div>
<div class="paragraph">
<p>With the inclusion of a custom data formatter, you now have the flexibility to dictate the output format of your data, tailoring it to your specific preferences.
This level of customization ensures that your data is sent exactly the way you want it.</p>
</div>
<div class="paragraph">
<p>To streamline your transition, we have incorporated a Migration Tool designed to effortlessly migrate your existing receivers from version 1.5.2 or older to the new framework.
This tool simplifies the upgrade process, ensuring a smooth and efficient transition.</p>
</div>
<div class="paragraph">
<p>Additionally, users can now take advantage of the option to set timelines and parameters for their receivers.
This feature enables automatic changes over time, providing a dynamic and responsive approach to data management.</p>
</div>
</div>
<div class="sect2">
<h2 id="_minor_enhancements_and_fixes_2" class="section-headline pb-4">Minor Enhancements and Fixes</h2>
<div class="sect3">
<h3 id="_connector_2" class="section-headline pb-4">Connector</h3>
<div class="sect4">
<h5 id="_feb_2024">Feb 2024</h5>
<div class="paragraph">
<p>Enhanced operation to send to SAP through ABAP Proxies.</p>
</div>
<div class="paragraph">
<p>Adding the possibility to browse for ABAP proxies and send data to SAP through them using the Boomi for SAP connector. The connector will generate the request and response profile for the selected proxy as well as the SOAP multipart wrapping the XI message.
The connector is also able to unwrap the XI message of the response from SAP when sending to a synchronous proxy.</p>
</div>
<div class="paragraph">
<p>In addition to the major enhancements, this release includes miscellaneous minor enhancements and fixes to improve the overall stability and functionality of Boomi for SAP.</p>
</div>
</div>
</div>
</div>
<div class="sect2">
<h2 id="_boomi_for_sap_1_6_1_core" class="section-headline pb-4">Boomi for SAP 1.6.1 (Core)</h2>
<div class="ulist">
<ul>
<li>
<p>Rebranding to Boomi for SAP</p>
</li>
<li>
<p>Performance optimizations for Event, payload</p>
</li>
<li>
<p>Bug-fixes</p>
</li>
</ul>
</div>
<div class="paragraph">
<p>Delivered as a transport:</p>
</div>
<div class="ulist">
<ul>
<li>
<p>Supporting CDS view with parameters</p>
</li>
</ul>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div style="page-break-after: always;"></div>
</div>
</div>
</div>
<div class="sect1">
<h2 class="section-headline pb-4" id="_axis_for_sap_v1_5">aXis for SAP v1.5</h2>
<div class="sectionbody">
<div class="sect2">
<h2 id="_major_enhancements_3" class="section-headline pb-4">Major Enhancements</h2>
<div class="paragraph">
<p>The Event feature has been updated to include the ability to filter on events.</p>
</div>
<div class="paragraph">
<p>The event type payload has been added for change pointers, as is filtering for payloads.</p>
</div>
<div class="paragraph">
<p>Editing of Services functionality has been added, allowing it to be easier to configure and manage your services.</p>
</div>
</div>
<div class="sect2">
<h2 id="_minor_enhancements_and_fixes_3" class="section-headline pb-4">Minor Enhancements and Fixes</h2>
<div class="paragraph">
<p>In addition to the major enhancements, this release includes miscellaneous minor enhancements and fixes to improve the overall stability and functionality of aXis for SAP.</p>
</div>
</div>
<div class="sect2">
<h2 id="_patches" class="section-headline pb-4">Patches</h2>
<div class="sect4">
<h5 id="_1_5_1_axis_core">1.5.1 (aXis Core)</h5>
<div class="ulist">
<ul>
<li>
<p>Event performance enhancements.</p>
</li>
<li>
<p>WSDL and XSD improved/corrected format</p>
</li>
<li>
<p>Support http 2XX respond codes</p>
</li>
<li>
<p>Removed empty response from Payload Events</p>
</li>
<li>
<p>IDOC; extended names to support 8+ characters for JSON</p>
</li>
</ul>
</div>
</div>
<div class="sect4">
<h5 id="_1_5_2_axis_core">1.5.2 (aXis Core)</h5>
<div class="ulist">
<ul>
<li>
<p>Fix for error on Business Object Events, Realtime, Payloads being incorrect.</p>
</li>
</ul>
</div>
</div>
<div class="sect4">
<h5 id="_1_5_3_axis_core">1.5.3 (aXis Core)</h5>
<div class="ulist">
<ul>
<li>
<p>Added configuration option to be able to always return all tables to a call to a function module, so the client doesn&#8217;t have to ask for them in the request. (see t-code /IXBX/ZBX010)</p>
</li>
</ul>
</div>
<div class="paragraph">
<p><em>We hope these enhancements improve your experience with aXis for SAP and help you achieve your integration goals more efficiently.</em></p>
</div>
<div class="paragraph">
<p><em>If you have any feedback or questions, please contact our support team.</em></p>
</div>
</div>
<div class="sect3">
<h3 id="_connector_3" class="section-headline pb-4">Connector</h3>
<div class="sect4">
<h5 id="_sep_2023">Sep 2023</h5>
<div class="paragraph">
<p>New Operation: PROXY_SEND which can utilize SAP ABAP Proxies
The connection to the backend now has new timeout settings, which will trigger if the connection is slow, or unresponsive, default timeout is 30 seconds.</p>
</div>
<div style="page-break-after: always;"></div>
<div class="paragraph">
<p><br></p>
</div>
</div>
</div>
</div>
</div>
</div>
<div class="sect1">
<h2 class="section-headline pb-4" id="_boomi_axis_v1_4">Boomi aXis v1.4</h2>
<div class="sectionbody">
<div class="paragraph">
<p>Release Note, Public
INVIXO Consulting Group A/S</p>
</div>
<div class="sect2">
<h2 id="_major_release" class="section-headline pb-4">Major Release</h2>
<div class="sect3">
<h3 id="_idoc" class="section-headline pb-4">IDoc</h3>
<div class="ulist">
<ul>
<li>
<p>The IDoc feature gives the user the possibility of exposing IDocs&#8217;s directly from the aXis UI.</p>
</li>
<li>
<p>aXis IDoc utilizes IDoc inbound and outbound functionality via aXis core.</p>
</li>
<li>
<p>New operation to send iDocs to SAP</p>
</li>
</ul>
</div>
</div>
<div class="sect3">
<h3 id="_abap_proxy" class="section-headline pb-4">ABAP Proxy</h3>
<div class="ulist">
<ul>
<li>
<p>Abap Proxy makes it possible to exchange middleware without disrupting the SAP Proxy framework pipeline.</p>
</li>
<li>
<p>Abap Proxy by aXis utilizes Abap Proxy inbound and outbound functionality via aXis core.</p>
</li>
</ul>
</div>
</div>
<div class="sect3">
<h3 id="_hyper_automation" class="section-headline pb-4">Hyper Automation</h3>
<div class="ulist">
<ul>
<li>
<p>It is now possible to transport existing services to other systems.</p>
</li>
</ul>
</div>
</div>
</div>
<div class="sect2">
<h2 id="_feature_updates" class="section-headline pb-4">Feature, updates</h2>
<div class="sect3">
<h3 id="_events" class="section-headline pb-4">Events</h3>
<div class="ulist">
<ul>
<li>
<p>Optimization of Event clean-up</p>
</li>
<li>
<p>New bundle size option possible when creating subscription
<strong>Please note the schema of the events subscription type have changed slightly to accommodate for bundling of events</strong></p>
</li>
<li>
<p>Now the user can create a subscription without topic</p>
</li>
<li>
<p>Now the user can see the name of the handler</p>
</li>
</ul>
</div>
</div>
<div class="sect3">
<h3 id="_table_service" class="section-headline pb-4">Table Service</h3>
<div class="ulist">
<ul>
<li>
<p>"Join Tables" and "Single Tables" now merged into "Table Service"</p>
</li>
<li>
<p>Now possible to promote table service from Local to Production</p>
</li>
<li>
<p>Now possible to preview data set in development client</p>
</li>
</ul>
</div>
</div>
<div class="sect3">
<h3 id="_table_service_extract" class="section-headline pb-4">Table Service Extract</h3>
<div class="ulist">
<ul>
<li>
<p>"Initial Load" rebranded as "Table Service Extract"</p>
</li>
<li>
<p>Path is no longer required for Table Service Extract</p>
</li>
<li>
<p>Topic has been added to Table Service Extract to allow the output paths to be identical to the data which can be extracted via events with payload option</p>
</li>
</ul>
</div>
</div>
<div class="sect3">
<h3 id="_function_module" class="section-headline pb-4">Function Module</h3>
<div class="ulist">
<ul>
<li>
<p>Filtering has been changed so that the filtering is done after the function module call, allowing use of parameters in call and then have dem filtered out and not returned.</p>
</li>
<li>
<p>New RFC enabled version of /IXBX/ZBX_BUILD_WHERE have been created and replaces the old function module.</p>
</li>
</ul>
</div>
</div>
<div class="sect3">
<h3 id="_bw_data_extractors" class="section-headline pb-4">BW Data Extractors</h3>
<div class="ulist">
<ul>
<li>
<p>Activation and de-activation of BW extractors is now possible in the aXis UI.</p>
</li>
</ul>
</div>
</div>
</div>
<div class="sect2">
<h2 id="_minor_enhancements_and_fixes_4" class="section-headline pb-4">Minor Enhancements and fixes</h2>
<div class="ulist">
<ul>
<li>
<p>When entering a prefix in Events topic with a trailing '/', it will result in a warning to ensure that the user is aware of this.</p>
</li>
<li>
<p>More information in Event JSON now possible</p>
</li>
<li>
<p>UI has been updated with more usability.</p>
</li>
</ul>
</div>
<div class="sect3">
<h3 id="_connector_4" class="section-headline pb-4">Connector</h3>
<div class="ulist">
<ul>
<li>
<p>New operation to send iDocs to SAP</p>
</li>
<li>
<p>New option to trust specific server side certificates, even if they are self-signed</p>
</li>
<li>
<p>New option to use client side certificate authentication</p>
</li>
<li>
<p>New option to send preemptive authentication</p>
</li>
<li>
<p>Added possibility to add static query parameters to requests</p>
</li>
<li>
<p>Some errors are reduced in severity from FAILURE to APPLICATION_ERROR to allow processes to act on errors instead of hard failing</p>
</li>
</ul>
</div>
</div>
</div>
<div class="sect2">
<h2 id="_patches_2" class="section-headline pb-4">Patches</h2>
<div class="sect3">
<h3 id="_core" class="section-headline pb-4">Core</h3>

</div>
<div class="sect3">
<h3 id="_1_4_1" class="section-headline pb-4">1.4.1</h3>
<div class="ulist">
<ul>
<li>
<p>Business object payload subscription had an issue when mapping a composit key. This has now been solved.</p>
</li>
<li>
<p>Function module with untyped parameters has these parameters specified as strings by aXis to allow use of the function modules. These parameters fixed when calling this function modules.</p>
</li>
</ul>
</div>
<div class="sect4">
<h5 id="_utf_8_encoding_update">UTF-8 encoding update</h5>
<div class="ulist">
<ul>
<li>
<p>Output from BW in non UTF-8 systems are now converted to UTF-8</p>
</li>
<li>
<p>Output from Function modules in non UTF-8 systems are now converted to UTF-8</p>
</li>
<li>
<p>Output from Table services in non UTF-8 systems are now converted to UTF-8</p>
</li>
</ul>
</div>
</div>
</div>
<div class="sect3">
<h3 id="_connector_5" class="section-headline pb-4">Connector</h3>
<div class="ulist">
<ul>
<li>
<p>1.4.1 (Anja): Fix for UTF-8 encoding of special characters</p>
</li>
</ul>
</div>
<div style="page-break-after: always;"></div>
<div class="paragraph">
<p><br></p>
</div>
</div>
</div>
</div>
</div>
<div class="sect1">
<h2 class="section-headline pb-4" id="_boomi_axis_v1_3">Boomi aXis v1.3</h2>
<div class="sectionbody">
<div class="paragraph">
<p>Release Note, Public
INVIXO Consulting Group A/S</p>
</div>
<div class="sect2">
<h2 id="_major_release_2" class="section-headline pb-4">Major Release</h2>
<div class="sect3">
<h3 id="_eda" class="section-headline pb-4">EDA</h3>
<div class="ulist">
<ul>
<li>
<p>The Event feature have been enhanced;</p>
</li>
<li>
<p>Subscribtion to data changes in SAP from the aXis UI via a wizard</p>
</li>
<li>
<p>Real time event processing for your event</p>
</li>
<li>
<p>Batch processing with various set-up possibilities</p>
</li>
<li>
<p>Remove main events where all subscriptions for these have been processed successfully</p>
</li>
<li>
<p>Deletion for events/subscriptions in the Dispatch layer, which should not be processed.</p>
</li>
<li>
<p>Events send program can be run in parallel for different message types. Locking is done pr. message types.</p>
</li>
<li>
<p>Three handlers are now available for Business Object events:</p>
<div class="literalblock">
<div class="content">
<pre>_/IXBX/CL_EVENT_HANDLER - For Batch processing_
_/IXBX/CL_EVENT_HANDLER_R - For Real time processing without path prefix_
_/IXBX/CL_EVENT_HANDLER_R_TOPIC - For Real time processing with Topic path prefix_</pre>
</div>
</div>
</li>
</ul>
</div>
</div>
</div>
<div class="sect2">
<h2 id="_feature_updates_2" class="section-headline pb-4">Feature, updates</h2>
<div class="ulist">
<ul>
<li>
<p>Search for function Module and Table Service now enabled without need of uppercase letters</p>
</li>
<li>
<p>Function modules with undefined parameters are now supported. The undefined parameters are defined as string as no type defined in SAP.</p>
</li>
<li>
<p>Fix for missing description for tables</p>
</li>
<li>
<p>Fix to include description for strict table search</p>
</li>
</ul>
</div>
<div class="sect3">
<h3 id="_connector_6" class="section-headline pb-4">Connector</h3>
<div class="ulist">
<ul>
<li>
<p>No changes to the connector for Core version 1.3.0</p>
</li>
</ul>
</div>
</div>
</div>
<div class="sect2">
<h2 id="_patches_3" class="section-headline pb-4">Patches</h2>
<div class="sect3">
<h3 id="_core_2" class="section-headline pb-4">Core</h3>

</div>
<div class="sect3">
<h3 id="_1_3_1" class="section-headline pb-4">1.3.1</h3>
<div class="ulist">
<ul>
<li>
<p>Enabling unassigned BOR objects for EDA</p>
</li>
</ul>
</div>
</div>
<div class="sect3">
<h3 id="_1_3_2" class="section-headline pb-4">1.3.2</h3>
<div class="ulist">
<ul>
<li>
<p>Handling of no Parameters for function modules and handling default value for non provided parameters</p>
</li>
<li>
<p>More flexible handling of joins</p>
</li>
</ul>
</div>
</div>
<div class="sect3">
<h3 id="_connector_7" class="section-headline pb-4">Connector</h3>
<div class="ulist">
<ul>
<li>
<p>N/A</p>
</li>
</ul>
</div>
<div style="page-break-after: always;"></div>
<div class="paragraph">
<p><br></p>
</div>
</div>
</div>
</div>
</div>
<div class="sect1">
<h2 class="section-headline pb-4" id="_boomi_axis_v1_2">Boomi aXis  v1.2</h2>
<div class="sectionbody">
<div class="paragraph">
<p>Release Note, Public
INVIXO Consulting Group A/S</p>
</div>
<div class="sect2">
<h2 id="_major_release_3" class="section-headline pb-4">Major Release</h2>
<div class="sect3">
<h3 id="_down_port" class="section-headline pb-4">Down Port</h3>
<div class="ulist">
<ul>
<li>
<p>Sourcecode downported to support 7.31 SP21 SAP_UI v2</p>
</li>
</ul>
</div>
</div>
<div class="sect3">
<h3 id="_data_wizards" class="section-headline pb-4">Data Wizards</h3>
<div class="ulist">
<ul>
<li>
<p>Reworked Data Wizards to use the SAP BAPI (Business API) which exposes stable function modules in a navigable tree.</p>
</li>
<li>
<p>Data Wizard has been moved to the interactive canvas</p>
</li>
</ul>
</div>
</div>
</div>
<div class="sect2">
<h2 id="_feature_updates_3" class="section-headline pb-4">Feature, updates</h2>
<div class="ulist">
<ul>
<li>
<p>Viewing of active services in non-development clients and systems now possible</p>
</li>
<li>
<p>Strict search for tables and function modules</p>
</li>
<li>
<p>Search descriptions for all service creations</p>
</li>
<li>
<p>Tables now have size approximation indicators</p>
</li>
<li>
<p>Remove condense in text data</p>
</li>
<li>
<p>Additional information for Business Events and Change Pointers</p>
</li>
<li>
<p>Performance enhancements towards backend</p>
</li>
<li>
<p>Initial load; fix wait for lack of resources</p>
</li>
<li>
<p>Function module; table parameters structure type references corrected</p>
</li>
</ul>
</div>
<div class="sect3">
<h3 id="_connector_8" class="section-headline pb-4">Connector</h3>
<div class="ulist">
<ul>
<li>
<p>No changes to the connector for Core version 1.2.0</p>
</li>
</ul>
</div>
</div>
</div>
<div class="sect2">
<h2 id="_patches_4" class="section-headline pb-4">Patches</h2>
<div class="sect3">
<h3 id="_core_3" class="section-headline pb-4">Core</h3>
<div class="ulist">
<ul>
<li>
<p>N/A</p>
</li>
</ul>
</div>
</div>
<div class="sect3">
<h3 id="_connector_9" class="section-headline pb-4">Connector</h3>
<div class="ulist">
<ul>
<li>
<p>N/A</p>
</li>
</ul>
</div>
<div style="page-break-after: always;"></div>
<div class="paragraph">
<p><br></p>
</div>
</div>
</div>
</div>
</div>
<div class="sect1">
<h2 class="section-headline pb-4" id="_boomi_axis_v1_1">Boomi aXis v1.1</h2>
<div class="sectionbody">
<div class="paragraph">
<p>Release Note, Public
INVIXO Consulting Group A/S</p>
</div>
<div class="sect2">
<h2 id="_major_release_4" class="section-headline pb-4">Major Release</h2>
<div class="sect3">
<h3 id="_function_modules" class="section-headline pb-4">Function Modules</h3>
<div class="paragraph">
<p>Function Module Services enables citizen integrator users via aXis UI to easily expose function modules from SAP, so the functionality can be utilized in Boomi processes via aXis Connector.</p>
</div>
</div>
<div class="sect3">
<h3 id="_extended_initial_load" class="section-headline pb-4">Extended initial load</h3>
<div class="paragraph">
<p>Extended initial load is updated so that selection screen for the selected table service is available to allow filtering on data extraction. Also this functionality is made available in a function module with allows the processing to be triggered via this.</p>
</div>
</div>
</div>
<div class="sect2">
<h2 id="_feature_updates_4" class="section-headline pb-4">Feature, updates</h2>
<div class="ulist">
<ul>
<li>
<p>Enhanced table service where clause filtering to allow client complexity of where clause. This allows multilevel combinations of AND/OR combinations of where clause parts. Note: This is not compatible with version 1.0.x of backend</p>
</li>
<li>
<p>Enabling of deletion of aXis staging of BW extracts.</p>
</li>
<li>
<p>In the Event send feature it is now enabled to filter on which message types to process.</p>
</li>
<li>
<p>Enabling editing of Table Service both for Single and Joins. Futhermore change log is avaiable Table Services.</p>
</li>
</ul>
</div>
</div>
<div class="sect2">
<h2 id="_enhancements_connector" class="section-headline pb-4">Enhancements, Connector</h2>
<div class="sect3">
<h3 id="_max_count_batch" class="section-headline pb-4">Max Count &amp; Batch</h3>
<div class="ulist">
<ul>
<li>
<p>Data is read from SAP as a whole, and returned in 1 document already, any splitting of data will have to be done in the process</p>
</li>
<li>
<p><strong>Maxcount</strong> Maximum records to be retrieved from backend, 0 for all.</p>
</li>
<li>
<p><strong>Batch size</strong> for the connector. It is possible to allow the connector to return multiple documents up to maxcount is reached. E.g maxcount=10000, batch=1000 would return 10 documents with 1000 records in each. If a user sets the ‘offset’ document property, the batch size would be ignored, since the user seems to have implemented paging in the process itself.</p>
</li>
</ul>
</div>
</div>
<div class="sect3">
<h3 id="_columns_selection_for_connector" class="section-headline pb-4">Columns selection for connector</h3>
<div class="ulist">
<ul>
<li>
<p>Make use of FIELDS when querying table services, apparently it omits the FIELDS field, giving all columns back always.</p>
</li>
</ul>
</div>
</div>
<div class="sect3">
<h3 id="_allow_follow_redirects" class="section-headline pb-4">Allow follow redirects</h3>
<div class="ulist">
<ul>
<li>
<p>Allow connector to follow http redirects from backend</p>
</li>
</ul>
</div>
</div>
</div>
<div class="sect2">
<h2 id="_patches_5" class="section-headline pb-4">Patches</h2>
<div class="sect3">
<h3 id="_core_4" class="section-headline pb-4">Core</h3>
<div class="ulist">
<ul>
<li>
<p>N/A</p>
</li>
</ul>
</div>
</div>
<div class="sect3">
<h3 id="_connector_10" class="section-headline pb-4">Connector</h3>
<div class="ulist">
<ul>
<li>
<p>N/A</p>
</li>
</ul>
</div>
<div style="page-break-after: always;"></div>
<div class="paragraph">
<p><br></p>
</div>
</div>
</div>
</div>
</div>
<div class="sect1">
<h2 class="section-headline pb-4" id="_boomi_axis_v1_0">Boomi aXis v1.0</h2>
<div class="sectionbody">
<div class="paragraph">
<p>Release Note, Public
INVIXO Consulting Group A/S</p>
</div>
<div class="paragraph">
<p>First official version of Boomi aXis for SAP.</p>
</div>
<div class="sect2">
<h2 id="_major_release_5" class="section-headline pb-4">Major Release</h2>
<div class="sect3">
<h3 id="_table_services" class="section-headline pb-4">Table Services</h3>
<div class="paragraph">
<p>Table Services enables citizen integrator users via aXis UI to easily expose tables and views from SAP, so the data can be utilized in Boomi processes via aXis Connector.</p>
</div>
</div>
<div class="sect3">
<h3 id="_table_join" class="section-headline pb-4">Table Join</h3>
<div class="paragraph">
<p>Table Join enables citizen integrator users via aXis UI to easily join tables and expose these from SAP, so the data can be utilized in Boomi processes via aXis Connector.</p>
</div>
</div>
<div class="sect3">
<h3 id="_events_2" class="section-headline pb-4">Events</h3>
<div class="paragraph">
<p>Boomi aXis for SAP can be an event sender when reacting to events inside SAP. This means, that if a scenario requires reacting to create events, update events, delete events etc., then an event is triggered via Boomi Accelerated Integration for SAP and sent out of SAP to Boomi, which then consumes and reacts to this event. Events are sent to Boomi, which are caught via Boomi Accelerated Integration for SAP listener.</p>
</div>
</div>
<div class="sect3">
<h3 id="_initial_load_extented" class="section-headline pb-4">Initial Load + extented</h3>
<div class="paragraph">
<p>The Initial load enables SAP useres to triggers mass events out of SAP.</p>
</div>
<div class="paragraph">
<p>After having developed a process utilizing Boomi aXis for SAP events, it is possible to use the initial load feature to trigger mass events out of SAP. This is useful in a replication scenario to get all data triggered out of SAP into the target system. Initial load based on tables whereas Initial Load Extented is based in a table Service.</p>
</div>
</div>
<div class="sect3">
<h3 id="_data_wizards_2" class="section-headline pb-4">Data Wizards</h3>
<div class="paragraph">
<p>Data Wizards supports the Citizen Integrator with a stored collection of suggested tables to be expose as services, when a user wants to expose specific business object or domain via aXis UI. These collections are based on experience, where the team has stored which tables are used for various extractions of data such as chart of accounts, purchase orders, HR personnel information etc.</p>
</div>
</div>
<div class="sect3">
<h3 id="_bw_data_extractor" class="section-headline pb-4">BW Data Extractor</h3>
<div class="paragraph">
<p>The BW Data extractor feature are built on SAP side for sending data via pre-configured BW Data Extractors to SAP BW systems or other data sources. Active BW Data Extractors can be viewed through aXis UI and consumed via aXis for SAP Connector</p>
</div>
</div>
<div class="sect3">
<h3 id="_authorization" class="section-headline pb-4">Authorization</h3>
<div class="paragraph">
<p>To utilize the features in Boomi aXis for SAP a set of transactions are build as a part of the installation. Furthermore a recommandance and some pre-build roles are provided.</p>
</div>
</div>
<div class="sect3">
<h3 id="_monitoring" class="section-headline pb-4">Monitoring</h3>
<div class="paragraph">
<p>The monitoring feature enables the SAP user to be able to monitor events going out of SAP.</p>
</div>
</div>
</div>
<div class="sect2">
<h2 id="_patches_6" class="section-headline pb-4">Patches</h2>
<div class="sect3">
<h3 id="_1_0_1" class="section-headline pb-4">1.0.1</h3>
<div class="ulist">
<ul>
<li>
<p>Fixed bug causing connector to only work on cloud atoms.</p>
</li>
</ul>
</div>
</div>
<div class="sect3">
<h3 id="_1_0_2" class="section-headline pb-4">1.0.2</h3>
<div class="ulist">
<ul>
<li>
<p>Bugfix, transports</p>
</li>
<li>
<p>Adding BW deletion of extracts functionality</p>
</li>
</ul>
</div>
</div>
</div>
</div>
</div>
</div></template>
<script>export default {}; </script>