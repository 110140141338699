<template><div>
<div class="sect1">
<h2 class="section-headline pb-4" id="_abap_proxy">ABAP Proxy</h2>
<div class="sectionbody">
<div class="paragraph">
<p>With the ABAP Proxy feature, it&#8217;s possible to use ABAP Proxies just as SAP PO would.</p>
</div>
<div class="paragraph">
<p>The ABAP Proxy feature enables exposing ABAP Proxies as REST services (in Boomi for SAP Core) and sending ABAP Proxy data to REST services (in Boomi).</p>
</div>
<div class="paragraph">
<p>Use case examples:</p>
</div>
<div class="ulist">
<ul>
<li>
<p>Receive updates to a data object</p>
<div class="ulist">
<ul>
<li>
<p>When a Business Partner is updated, send the update to Boomi and update other applications</p>
</li>
</ul>
</div>
</li>
<li>
<p>Update a data object</p>
<div class="ulist">
<ul>
<li>
<p>Update a Material in SAP with master data from an external application</p>
</li>
</ul>
</div>
</li>
<li>
<p>Query a (complex) data object</p>
<div class="ulist">
<ul>
<li>
<p>Retrieve a Business Partner object</p>
</li>
</ul>
</div>
</li>
</ul>
</div>
</div>
</div>
</div></template>
<script>export default {}; </script>