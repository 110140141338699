<template>
  <div class="subpage-wrapper">
    <div class="header-banner">
      <div class="image-wrapper">
        <v-img
          alt="Boomi for SAP Core"
          contain
          src="@/graphics/hand-paper-airplanes.svg"
        />
      </div>
      <v-container class="content">
        <h1>Release information for Boomi for SAP Core</h1>
        <h3>{{ pageSubheadline }}</h3>
      </v-container>
    </div>
    <v-container class="content-section">
      <v-card
        width="100%"
        class="pa-10 mb-10"
      >
        <Events />

      </v-card>
    </v-container>
  </div>
</template>

<style scoped lang="scss">
.header-banner .image-wrapper ::v-deep .v-image__image--contain {
  height: 750px;
  transform: translate(-630px, -40px) rotate(10deg);
}
</style>

<script>
import Vue from "vue";
import { Component } from "vue-property-decorator";
import Events from "./_index.vue";

@Component({ components: { Events } })
export default class index extends Vue {
  pageHeaderText = "";
  pageSubheadline = "";
  mounted() {
    const $pageHeader = document.getElementById("_eda");
    const $pageSubheader = document.getElementsByClassName("paragraph")[0];
    $pageHeader.classList.add("hidden-content");
    $pageSubheader.classList.add("hidden-content");
    this.pageHeaderText = $pageHeader.innerText;
    this.pageSubheadline = $pageSubheader.innerText;
  }
}
</script>