<template><div>
<div class="sect1">
<h2 class="section-headline pb-4" id="_boomi_for_sap_receiver_framework">Boomi for SAP Receiver Framework</h2>
<div class="sectionbody">
<div class="paragraph">
<p><strong>Receiver Framework for Boomi for SAP</strong></p>
</div>
<div class="paragraph">
<p>The Boomi for SAP Receiver Framework enables different receivers and different ways of sending data. A framework has been built to extend the existing functionality of the receivers regarding the types of receivers and the format in which the data can be sent.</p>
</div>
<div class="sect2">
<h2 id="_receivers" class="section-headline pb-4">Receivers</h2>
<div class="paragraph">
<p>The supported receiver types are:</p>
</div>
<div class="ulist">
<ul>
<li>
<p>HTTPS RFC - Classic http rfc receiver type. Possible parameters:</p>
</li>
</ul>
</div>
<table class="tableblock frame-all grid-all stretch">
<colgroup>
<col style="width: 33.3333%;">
<col style="width: 33.3333%;">
<col style="width: 33.3334%;">
</colgroup>
<thead>
<tr>
<th class="tableblock halign-left valign-top"><strong>Field name</strong></th>
<th class="tableblock halign-left valign-top"><strong>Technical name</strong></th>
<th class="tableblock halign-left valign-top"><strong>Notes</strong></th>
</tr>
</thead>
<tbody>
<tr>
<td class="tableblock halign-left valign-top"><p class="tableblock">HTTP Method</p></td>
<td class="tableblock halign-left valign-top"><p class="tableblock">MV_HTTP_METHOD</p></td>
<td class="tableblock halign-left valign-top"><p class="tableblock">Required. Default value 'POST'</p></td>
</tr>
<tr>
<td class="tableblock halign-left valign-top"><p class="tableblock">RFC Destination</p></td>
<td class="tableblock halign-left valign-top"><p class="tableblock">MV_RFC_DEST</p></td>
<td class="tableblock halign-left valign-top"><p class="tableblock">Required. It is possible to choose a value from the drop-down list or input value by hand</p></td>
</tr>
</tbody>
</table>
<div class="paragraph">
<p><br></p>
</div>
<div class="ulist">
<ul>
<li>
<p>Confluent REST - Send data to Confluent by RFC dest</p>
</li>
</ul>
</div>
<table class="tableblock frame-all grid-all stretch">
<colgroup>
<col style="width: 33.3333%;">
<col style="width: 33.3333%;">
<col style="width: 33.3334%;">
</colgroup>
<thead>
<tr>
<th class="tableblock halign-left valign-top"><strong>Field name</strong></th>
<th class="tableblock halign-left valign-top"><strong>Technical name</strong></th>
<th class="tableblock halign-left valign-top"><strong>Notes</strong></th>
</tr>
</thead>
<tbody>
<tr>
<td class="tableblock halign-left valign-top"><p class="tableblock">HTTP Method</p></td>
<td class="tableblock halign-left valign-top"><p class="tableblock">MV_HTTP_METHOD</p></td>
<td class="tableblock halign-left valign-top"><p class="tableblock">Required. Default value 'POST'</p></td>
</tr>
<tr>
<td class="tableblock halign-left valign-top"><p class="tableblock">RFC Destination</p></td>
<td class="tableblock halign-left valign-top"><p class="tableblock">MV_RFC_DEST</p></td>
<td class="tableblock halign-left valign-top"><p class="tableblock">Required. It is possible to choose a value from the drop-down list or input value by hand</p></td>
</tr>
<tr>
<td class="tableblock halign-left valign-top"><p class="tableblock">Value schema ID</p></td>
<td class="tableblock halign-left valign-top"><p class="tableblock">MV_VALUE_SCHEMA_ID</p></td>
<td class="tableblock halign-left valign-top"><p class="tableblock">Optional. Id of the json records schema in confluent</p></td>
</tr>
<tr>
<td class="tableblock halign-left valign-top"><p class="tableblock">Key schema ID</p></td>
<td class="tableblock halign-left valign-top"><p class="tableblock">MV_KEY_SCHEMA_ID</p></td>
<td class="tableblock halign-left valign-top"><p class="tableblock">Optional. Id of the schema for the key field</p></td>
</tr>
<tr>
<td class="tableblock halign-left valign-top"><p class="tableblock">Key</p></td>
<td class="tableblock halign-left valign-top"><p class="tableblock">MV_KEY</p></td>
<td class="tableblock halign-left valign-top"><p class="tableblock">Optional: Key (can be complex json type)</p></td>
</tr>
</tbody>
</table>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p>It is possible to maintain parameter values dependent on date.</p>
</div>
<div class="paragraph">
<p>Parameters values validation: Critical errors - if the user input parameter value doesn&#8217;t match with the parameter data type. Warning errors - if the user input parameter value is not in the possible value list. It&#8217;s not allowed to save the receiver if there are some critical errors in parameter values.</p>
</div>
<div class="paragraph">
<p>Deployment mode sets on the receiver level for receiver and its parameter values.</p>
</div>
<div class="paragraph">
<p>The receiver will use the parameter framework to allow the reuse of receiver object where the customizing enable different settings.</p>
</div>
<div class="paragraph">
<p><br></p>
</div>
</div>
<div class="sect2">
<h2 id="_data_formats" class="section-headline pb-4">Data formats</h2>
<div class="paragraph">
<p>Supported Output data formats:</p>
</div>
<table class="tableblock frame-all grid-all stretch">
<colgroup>
<col style="width: 33.3333%;">
<col style="width: 33.3333%;">
<col style="width: 33.3334%;">
</colgroup>
<thead>
<tr>
<th class="tableblock halign-left valign-top"><strong>Data format name</strong></th>
<th class="tableblock halign-left valign-top"><strong>Technical name</strong></th>
<th class="tableblock halign-left valign-top"><strong>Notes</strong></th>
</tr>
</thead>
<tbody>
<tr>
<td class="tableblock halign-left valign-top"><p class="tableblock">v1.5 JSON Format</p></td>
<td class="tableblock halign-left valign-top"><p class="tableblock">LEGACY</p></td>
<td class="tableblock halign-left valign-top"><p class="tableblock">Legacy JSON format, used for &lt; 1.5.2 Release. Mostly used for special format rules for PAYLOAD subscriptions</p></td>
</tr>
<tr>
<td class="tableblock halign-left valign-top"><p class="tableblock">v1.6 JSON</p></td>
<td class="tableblock halign-left valign-top"><p class="tableblock">STD_JSON</p></td>
<td class="tableblock halign-left valign-top"><p class="tableblock">Brand new JSON format. Data would be converted as "it is" without any special rules for PAYLOAD. Empty fields would be skipped</p></td>
</tr>
<tr>
<td class="tableblock halign-left valign-top"><p class="tableblock">XML char format</p></td>
<td class="tableblock halign-left valign-top"><p class="tableblock">XML_C</p></td>
<td class="tableblock halign-left valign-top"><p class="tableblock">XML format. Data would be converted as "it is" without any special rules for PAYLOAD. Empty fields would be skipped</p></td>
</tr>
</tbody>
</table>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p>Possible data formats for receiver types are maintained at transaction /IXBX/DCONV_RECV_MAP(/IXBX/ZBX019). Mapping for Boomi for SAP provided Data Formats and Receiver types:</p>
</div>
<table class="tableblock frame-all grid-all stretch">
<colgroup>
<col style="width: 10%;">
<col style="width: 20%;">
<col style="width: 30%;">
<col style="width: 40%;">
</colgroup>
<thead>
<tr>
<th class="tableblock halign-left valign-top"><strong>Receiver type</strong></th>
<th class="tableblock halign-left valign-top"><strong>Receiver Description</strong></th>
<th class="tableblock halign-left valign-top"><strong>Data Format</strong></th>
<th class="tableblock halign-left valign-top"><strong>Data Format Description</strong></th>
</tr>
</thead>
<tbody>
<tr>
<td class="tableblock halign-left valign-top"><p class="tableblock">/IXBX/CL_EDA_REC_TYPE_CONFL</p></td>
<td class="tableblock halign-left valign-top"><p class="tableblock">Receiver type Confluent</p></td>
<td class="tableblock halign-left valign-top"><p class="tableblock">STD_JSON</p></td>
<td class="tableblock halign-left valign-top"><p class="tableblock">v1.6 JSON</p></td>
</tr>
<tr>
<td class="tableblock halign-left valign-top"><p class="tableblock">/IXBX/CL_EDA_REC_TYPE_HTTP_RFC</p></td>
<td class="tableblock halign-left valign-top"><p class="tableblock">Receiver type Http RFC</p></td>
<td class="tableblock halign-left valign-top"><p class="tableblock">STD_JSON</p></td>
<td class="tableblock halign-left valign-top"><p class="tableblock">v1.6 JSON</p></td>
</tr>
<tr>
<td class="tableblock halign-left valign-top"><p class="tableblock">/IXBX/CL_EDA_REC_TYPE_HTTP_RFC</p></td>
<td class="tableblock halign-left valign-top"><p class="tableblock">Receiver type Http RFC</p></td>
<td class="tableblock halign-left valign-top"><p class="tableblock">LEGACY</p></td>
<td class="tableblock halign-left valign-top"><p class="tableblock">v1.5 JSON Format</p></td>
</tr>
<tr>
<td class="tableblock halign-left valign-top"><p class="tableblock">/IXBX/CL_EDA_REC_TYPE_HTTP_RFC</p></td>
<td class="tableblock halign-left valign-top"><p class="tableblock">Receiver type Http RFC</p></td>
<td class="tableblock halign-left valign-top"><p class="tableblock">XML_C</p></td>
<td class="tableblock halign-left valign-top"><p class="tableblock">XML char format</p></td>
</tr>
</tbody>
</table>
<div class="paragraph">
<p><br></p>
</div>
</div>
<div class="sect2">
<h2 id="_1_7_enhancements" class="section-headline pb-4">1.7 Enhancements</h2>

</div>
</div>
</div>
<div class="sect1">
<h2 class="section-headline pb-4" id="_custom_enhancements">Custom enhancements</h2>
<div class="sectionbody">
<div class="paragraph">
<p>It is possible to implement custom-specific receiver types or data formats.</p>
</div>
<div class="sect2">
<h2 id="_receiver_type" class="section-headline pb-4">Receiver type</h2>
<div class="paragraph">
<p>The main Boomi for SAP receiver type behavior is implemented in an abstract class /IXBX/CL_EDA_RECEIVER_TYPE. To implement custom-specific logic just inherit this class (or suitable child class), define attributes, and redefine the methods you need.
It is recommended to define Documentation for a class, to give the end user more information about the receiver type.
The parameters that the receiver can set to control the processing logic of the receiver type are created as normal protected attributes on the class. It is recommended to use data elements for attribute typization. The parameter description, search help values, or allowed domain values would be read from the Data element.</p>
</div>
<div class="paragraph">
<p>Methods are required for implementation:</p>
</div>
<table class="tableblock frame-all grid-all stretch">
<colgroup>
<col style="width: 33.3333%;">
<col style="width: 66.6667%;">
</colgroup>
<thead>
<tr>
<th class="tableblock halign-left valign-top"><strong>Method</strong></th>
<th class="tableblock halign-left valign-top"><strong>Notes</strong></th>
</tr>
</thead>
<tbody>
<tr>
<td class="tableblock halign-left valign-top"><p class="tableblock">send</p></td>
<td class="tableblock halign-left valign-top"><p class="tableblock">implementation for sending. is_data - contains data, subscription and topic path</p></td>
</tr>
</tbody>
</table>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p>Methods are optional for implementation:</p>
</div>
<table class="tableblock frame-all grid-all stretch">
<colgroup>
<col style="width: 33.3333%;">
<col style="width: 66.6667%;">
</colgroup>
<thead>
<tr>
<th class="tableblock halign-left valign-top"><strong>Method</strong></th>
<th class="tableblock halign-left valign-top"><strong>Notes</strong></th>
</tr>
</thead>
<tbody>
<tr>
<td class="tableblock halign-left valign-top"><p class="tableblock">check_combination</p></td>
<td class="tableblock halign-left valign-top"><p class="tableblock">Additional parameters check. Can be used to determine if the values of the parameters are allowed together. Be aware that errors were raised by this method ALLOW tho create\save receiver by it would be an error during processing subscription</p></td>
</tr>
<tr>
<td class="tableblock halign-left valign-top"><p class="tableblock">process_init_helper</p></td>
<td class="tableblock halign-left valign-top"><p class="tableblock">Reimplement in receiver type classes to allow additional logic before processing starts</p></td>
</tr>
<tr>
<td class="tableblock halign-left valign-top"><p class="tableblock">set_parameters</p></td>
<td class="tableblock halign-left valign-top"><p class="tableblock">Set parameters if needed. Use add_method for setting parameters for user input</p></td>
</tr>
<tr>
<td class="tableblock halign-left valign-top"><p class="tableblock">wrap</p></td>
<td class="tableblock halign-left valign-top"><p class="tableblock">Implement if additional fields are needed in output data</p></td>
</tr>
</tbody>
</table>
<div class="paragraph">
<p><br></p>
</div>
</div>
<div class="sect2">
<h2 id="_data_format" class="section-headline pb-4">Data Format</h2>
<div class="paragraph">
<p>Data conversion Format - is a rule, on how the output data should look like.
To define a user-specific data format:</p>
</div>
<div class="paragraph">
<p>1: Go to transaction /IXBX/DATA_CONV (/IXBX/ZBX005) Custom data conversion filter. Define Data Conversion format ID and Description. Custom-defined Data conversion types must begin with either Z or Y;</p>
</div>
<div class="paragraph">
<p>2: Implement BADI /IXBX/BADI_EVENT_DATA_CONV, and set the Data Conversion format ID from the previous step as Badi filter;</p>
</div>
<div class="paragraph">
<p>3 Methods that need to be implemented</p>
</div>
<table class="tableblock frame-all grid-all stretch">
<colgroup>
<col style="width: 33.3333%;">
<col style="width: 66.6667%;">
</colgroup>
<thead>
<tr>
<th class="tableblock halign-left valign-top"><strong>Method</strong></th>
<th class="tableblock halign-left valign-top"><strong>Notes</strong></th>
</tr>
</thead>
<tbody>
<tr>
<td class="tableblock halign-left valign-top"><p class="tableblock">convert</p></td>
<td class="tableblock halign-left valign-top"><p class="tableblock">Conversion rule implementation for output</p></td>
</tr>
<tr>
<td class="tableblock halign-left valign-top"><p class="tableblock">get_schema</p></td>
<td class="tableblock halign-left valign-top"><p class="tableblock">Getting schema, according to Conversion rule</p></td>
</tr>
<tr>
<td class="tableblock halign-left valign-top"><p class="tableblock">get_content_type</p></td>
<td class="tableblock halign-left valign-top"><p class="tableblock">MIME type for output data</p></td>
</tr>
</tbody>
</table>
<div class="paragraph">
<p>4: Define which Receiver types can work with the new user-defined Data Conversion format. Go to transaction /IXBX/DCONV_RECV_MAP (/IXBX/ZBX019).</p>
</div>
<div class="paragraph">
<p>5: After those steps new Data Conversion format would be available for selection and use.</p>
</div>
<div class="paragraph">
<p><br></p>
</div>
</div>
<div class="sect2">
<h2 id="_websocket_receiver_type" class="section-headline pb-4">Websocket Receiver Type</h2>
<div class="paragraph">
<p>By version 1.7, The websocket receiver type allows continous event driven messaging over websocket communication.
For a particular subscription, a websocket receiver connects to a websocket server on path "/sap/bc/apc/ixbx/websocket?SUB_ID={subscription_id}"
and forwards events to the server regardless if any boomi connector is listening. The Boomi for SAP Core Connector can browse said subscriptions and listen
for events on the appropriate websocket server accordingly. Note importantly that this assumes and requires a websocket server endpoint for each subscription;</p>
</div>
</div>
</div>
</div>
</div></template>
<script>export default {}; </script>