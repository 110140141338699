import Vue from "vue";
import { Component } from "vue-property-decorator";

@Component({})
export default class TypeOnePanels extends Vue {
  panels = [
    {
      headline: "Standard",
      subHeadline: "SAP Technology",
      icon: "standard",
      items: [
        "Use open-APIs compared to SAP proprietary protocols",
        "Leverage SAP application layer for high output",
        "Expose SAP functionality as REST services",
        "Data table indexing for high download efficiency"
      ]
    },
    {
      headline: "UI",
      subHeadline: "User Friendly",
      icon: "delivery",
      items: [
        "Uses non-proprietary APIs",
        "Easy access to SAP data",
        "Intuitive web based user-interface",
        "Wizard with pre-defined templates"
      ]
    },
    {
      headline: "ABAP",
      subHeadline: "Development",
      icon: "package",
      items: [
        "Automated data extraction based on SAP business event",
        "Optimized data access via existing SAP functionality",
        "Updates data to and from external systems to SAP",
        "Data extraction from initial or delta data load"
      ]
    }
  ];
}