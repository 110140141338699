<template><div>
<div class="sect1">
<h2 class="section-headline pb-4" id="_function_modules">Function Modules</h2>
<div class="sectionbody">
<div class="paragraph">
<p>With Function Module Services, it&#8217;s easy to expose SAP Function Modules and use them in Boomi processes.</p>
</div>
<div class="paragraph">
<p>The Function Module Service feature enables exposing Function Modules as REST services.</p>
</div>
<div class="paragraph">
<p>The Function Module Service can be configured to expose all Function Module Service including non-RFC enabled Function Module Service&#8217;s on the use-case.</p>
</div>
<div class="paragraph">
<p>Use case examples:</p>
</div>
<div class="ulist">
<ul>
<li>
<p>Update a data object</p>
<div class="ulist">
<ul>
<li>
<p>Update a Business Partner object</p>
</li>
</ul>
</div>
</li>
<li>
<p>Query a (complex) data object</p>
<div class="ulist">
<ul>
<li>
<p>Retrieve a Business Partner object</p>
</li>
</ul>
</div>
</li>
<li>
<p>Expose a custom Function Module</p>
<div class="ulist">
<ul>
<li>
<p>Allow integrations to call custom Function Module</p>
</li>
</ul>
</div>
</li>
<li>
<p>Trigger a Table Service Extract</p>
<div class="ulist">
<ul>
<li>
<p>Expose and a call the Boomi for SAP Function Module</p>
</li>
</ul>
</div>
</li>
</ul>
</div>
</div>
</div>
</div></template>
<script>export default {}; </script>