<template><div>
<div id="preamble">
<div class="sectionbody">
<div class="paragraph">
<p>The following guide will run through the options you will have to set up a transport through Hyper Automation.</p>
</div>
</div>
</div>
<div class="sect1">
<h2 class="section-headline pb-4" id="_adding_services_to_a_transport">Adding services to a Transport</h2>
<div class="sectionbody">
<div class="paragraph">
<p>Use transaction <strong>/IXBX/HYPER_AUTO</strong> or <strong>/IXBX/ZBX002</strong> to get to the main menu of Hyper Automation where you can see all features available and choose one to add services to a transport. There is also the possibility to select the deployment mode for the desired services. (<em>It is adviced to create separate transports for the LOCAL and PROD object to avoid any confusion between production and development/test systems</em>)</p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/hyper_automation_choose_feature.png" alt="Choose-Feature" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p>Choose the services you would like to be put in a transport. When ready choose "Add to transport". You will then be asked for the transport request. You can either <strong>create a new transport</strong> or <strong>choose an existing one</strong>. (<em>To see all active transports and what they include, use transaction <strong>SE10</strong>.</em>)</p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/hyper_automation_choose_services.png" alt="Choose-services" />
</div>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/hyper_automation_choose_transport.png" alt="Choose-transport" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p>An information box appears that informs about the number of chosen services and asks if you want to proceed or not.</p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/hyper_automation_request_status.png" alt="Request-status" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p>In case "Subscriptions" were chosen in the main menu, then the information box will look like below, informing that all the objects (table service, topic, receiver) related to the chosen subscriptions are automatically added to the transport too.</p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/hyper_automation_request_status_subscr.png" alt="Add-Transport" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p>If 'No' is chosen then the action will be cancelled and you will get back to the list of services.</p>
</div>
<div class="paragraph">
<p>If 'Yes' is chosen then you will either get a successful message and the services will be added to the transport, otherwise you will get an error box explaining what the issue is.</p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/hyper_automation_successful_addition.png" alt="Added-successfully" />
</div>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/hyper_automation_error_messages.png" alt="Error-messages" />
</div>
</div>
</div>
</div>
</div></template>
<script>export default {}; </script>