<template>
  <div class="subpage-wrapper">
    <div class="header-banner">
      <div class="image-wrapper">
        <v-img
          alt="Boomi for SAP"
          contain
          src="@/graphics/birds.svg"
        />
      </div>
      <v-container class="content">
        <h1>{{pageHeaderText}}</h1>
      </v-container>
    </div>
    <v-container class="content-section">
      <v-card
        width="100%"
        class="pa-10 mb-10"
      >
        <UserGuide />

        <v-row class="buttons-wrapper" align="center">
          <v-col cols="12" sm="12">
            <div class="text-center">
              <div class="my-8">
                <v-btn
                  x-large
                  color="accent"
                  dark
                  to="/getting-started-with-axis/events/appendix"
                >Appendix</v-btn>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-card>
    </v-container>
  </div>
</template>

<style scoped lang="scss">
.header-banner .image-wrapper ::v-deep .v-image__image--contain {
  height: 750px;
  transform: translate(-580px, -140px);
}
</style>

<script>
import Vue from "vue";
import { Component } from "vue-property-decorator";
import UserGuide from "./_UserGuide.vue";

@Component({ components: { UserGuide } })
export default class userGuide extends Vue {
  pageHeaderText = "Events User Guide";
  mounted() {
    if (document.querySelectorAll("[href=\"../configuration/rfc-configuration.adoc\"]")[0]) {
      let configurationReplacementHref = "/getting-started-with-axis/configuration/rfc-configuration";
      if (location.href.indexOf("axis-staging.invixo.com") !== -1) {
        const URL = location.href.split("/");
        configurationReplacementHref = `${window.location.origin}/${URL[URL.indexOf("axis-staging.invixo.com") + 1] + configurationReplacementHref}`;
      }
      document.querySelectorAll("[href=\"../configuration/rfc-configuration.adoc\"]")[0].setAttribute("href", configurationReplacementHref);
    }
  }
}
</script>