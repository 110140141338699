var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._m(0),_c('div',{staticClass:"sect1"},[_c('h2',{staticClass:"section-headline pb-4",attrs:{"id":"_boomi_configuration"}},[_vm._v("Boomi Configuration")]),_c('div',{staticClass:"sectionbody"},[_vm._m(1),_c('div',{staticClass:"sect2"},[_c('h2',{staticClass:"section-headline pb-4",attrs:{"id":"_boomi_shared_web_server"}},[_vm._v("Boomi Shared Web Server")]),_vm._m(2),_vm._m(3),_c('div',{staticClass:"imageblock"},[_c('div',{staticClass:"content"},[_c('v-img',{attrs:{"contain":"","src":require("./graphics/token.png"),"alt":"token"}})],1)])]),_c('div',{staticClass:"sect2"},[_c('h2',{staticClass:"section-headline pb-4",attrs:{"id":"_boomi_web_service_processes"}},[_vm._v("Boomi Web Service Processes")]),_vm._m(4),_vm._m(5),_vm._m(6),_c('div',{staticClass:"imageblock"},[_c('div',{staticClass:"content"},[_c('v-img',{attrs:{"contain":"","src":require("./graphics/boomi-web-server-operation.png"),"alt":"operation"}})],1),_c('div',{staticClass:"title"},[_vm._v("Figure 1. Example of Web Services Server Operation that receives Table Service Extracts.")])])])])]),_c('div',{staticClass:"sect1"},[_c('h2',{staticClass:"section-headline pb-4",attrs:{"id":"_sap_rfc_configuration"}},[_vm._v("SAP RFC Configuration")]),_c('div',{staticClass:"sectionbody"},[_vm._m(7),_vm._m(8),_vm._m(9),_vm._m(10),_c('div',{staticClass:"imageblock"},[_c('div',{staticClass:"content"},[_c('v-img',{attrs:{"contain":"","src":require("./graphics/SM59_1.png"),"alt":"SM59_1"}})],1)]),_vm._m(11),_vm._m(12),_c('div',{staticClass:"imageblock"},[_c('div',{staticClass:"content"},[_c('v-img',{attrs:{"contain":"","src":require("./graphics/SM59_2.png"),"alt":"SM59_2"}})],1)]),_vm._m(13),_vm._m(14),_vm._m(15)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sect1"},[_c('h2',{staticClass:"section-headline pb-4",attrs:{"id":"_introduction"}},[_vm._v("Introduction")]),_c('div',{staticClass:"sectionbody"},[_c('div',{staticClass:"paragraph"},[_c('p',[_vm._v("This user guide explains how to set up an RFC connection in SAP.")])]),_c('div',{staticClass:"paragraph"},[_c('p',[_vm._v("This guide will describe how to set up an RFC Connection in SAP that connects to a Boomi Atom via Basic authentication. Your setup might differ if you use another type of authentication, a reverse proxy, or a load balancer.")])]),_c('div',{staticClass:"paragraph"},[_c('p',[_vm._v("The user guide follows these steps:")])]),_c('div',{staticClass:"ulist"},[_c('ul',[_c('li',[_c('p',[_vm._v("Find Atom information in Boomi Atomsphere")])]),_c('li',[_c('p',[_vm._v("Set-up Web service listener operation in Boomi")])]),_c('li',[_c('p',[_vm._v("Create external connection in SM59 in SAP")])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"paragraph"},[_c('p',[_vm._v("To receive SAP data in Boomi, configure the Boomi Shared Web Server and develop and deploy Boomi web services processes.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"paragraph"},[_c('p',[_vm._v("On the Boomi platform:")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"olist arabic"},[_c('ol',{staticClass:"arabic"},[_c('li',[_c('p',[_vm._v("Navigate to the the Shared Web Server settings on the runtime you want to connect to")])]),_c('li',[_c('p',[_vm._v("On the General tab take note of the hostname and port as they will be used in the SAP configuration")])]),_c('li',[_c('p',[_vm._v("On the User Management tab, create a new user or verify that an existing user can be used")]),_c('div',{staticClass:"ulist"},[_c('ul',[_c('li',[_c('p',[_vm._v("Take note of the Username and Token as they will be used in the SAP configuration")])]),_c('li',[_c('p',[_vm._v("'Token' corresponds to 'Password' in SAP")])])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"paragraph"},[_c('p',[_vm._v("Set up Boomi Web Service processes for the features you want to use. The only requirement is:")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ulist"},[_c('ul',[_c('li',[_c('p',[_vm._v("The Web Server Service listener must be configured to receive JSON via the POST method.")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"paragraph"},[_c('p',[_vm._v("Deploy the process(es) to a Boomi Atom.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"paragraph"},[_c('p',[_vm._v("Set up an RFC connection to connect to the corresponding Boomi Atom by using transaction SM59 with the Atom Web Server details including:")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ulist"},[_c('ul',[_c('li',[_c('p',[_vm._v("Target Host: The base url for the atom")])]),_c('li',[_c('p',[_vm._v("Service No: The port (defaults to 80 for http and 443 for https)")]),_c('div',{staticClass:"ulist"},[_c('ul',[_c('li',[_c('p',[_vm._v("Standard ports for the Boomi Web Service are 9090 and 9093 for http and https, respectively.")])])])])]),_c('li',[_c('p',[_vm._v("User and Password (Token)")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"paragraph"},[_c('p',[_vm._v("on the Technical Settings tab, set the Target host and Service No:")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"paragraph"},[_c('p',[_c('br')])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"paragraph"},[_c('p',[_c('br')])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"paragraph"},[_c('p',[_vm._v("On the Logon & Security page, set the User and Password. If using SSL, set the SSL certificate.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"paragraph"},[_c('p',[_c('br')])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"paragraph"},[_c('p',[_vm._v("Perform a Connection Test to verify that the connection works. Note that the connection test will only be successful if the Boomi Atom Web Server is running. This requires at least one web service listener to be deployed to the Atom.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"paragraph"},[_c('p',[_vm._v("Take note of the RFC Destination Name since this will be used later.")])])
}]

export { render, staticRenderFns }