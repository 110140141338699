<template><div>
<div class="sect1">
<h2 class="section-headline pb-4" id="_introduction">Introduction</h2>
<div class="sectionbody">
<div class="paragraph">
<p>This guide will describe how to request Table Service Extracts in SAP.</p>
</div>
<div class="paragraph">
<p>In Boomi, the Function Module Service <strong><em>/IXBX/BATCH_TAB_SERV_EXTRACT</em></strong> can be used to achieve the same result.</p>
</div>
<div class="sect2">
<h2 id="_prerequisites" class="section-headline pb-4">Prerequisites</h2>
<div class="ulist">
<ul>
<li>
<p>SAP RFC Connection to the Boomi runtime (Atom, Molecule, Cloud) and a Boomi process to receive the data</p>
<div class="ulist">
<ul>
<li>
<p><a href="../configuration/rfc-configuration.adoc">RFC Configuration User Guide</a></p>
</li>
</ul>
</div>
</li>
<li>
<p>The Table Service to be used has been created</p>
<div class="ulist">
<ul>
<li>
<p><a href="tableservice-userguide.adoc">Table Service User Guide</a></p>
</li>
</ul>
</div>
</li>
</ul>
</div>
</div>
</div>
</div>
<div class="sect1">
<h2 class="section-headline pb-4" id="_guide">Guide</h2>
<div class="sectionbody">
<div class="paragraph">
<p>To start a Table Service Extract, log on to SAP and run transaction <em><strong>/IXBX/ZBX008</strong></em> to get to the Table Service Extract page.</p>
</div>
<div class="paragraph">
<p>Fill out the below listed fields:</p>
</div>
<div class="paragraph">
<p><strong>Table Service</strong>: which table service definition to use for extracting data. Click on the <strong>search icon</strong> to be able to choose the table service in a drop down menu.</p>
</div>
<div class="paragraph">
<p><strong>Topic</strong>: Used to allow the output paths to be identical, if the data has been extracted via events via the payload option.</p>
</div>
<div class="paragraph">
<p><strong>RFC Destination</strong>: The destination of the atom. Click to the right in the RFC destination box to be able to choose the RFC destination in a drop down menu.</p>
</div>
<div class="paragraph">
<p><strong>Max Records</strong>: Maximum records to send. In the below example max records is set to 10.000 records. If max records is set to 0 it means that the amount of records are unlimited.</p>
</div>
<div class="paragraph">
<p><strong>Path</strong>: The path is appended to the RFC Destination. The path must match the path configured in the receiving Boomi web service.</p>
</div>
<div class="ulist">
<ul>
<li>
<p><strong>Path Not Required if</strong>: Path is not required for a Table Service Extract. But note that RFC or Topic must handle the path if not provided in the execution of the Table Service Extract.</p>
</li>
</ul>
</div>
<div class="paragraph">
<p><strong>Bundle size</strong>: How many objects in each bundle. In our example it is set to 1.000, which means the 10.000 max records would be sent in 10 bundles with 1.000 records in each.</p>
</div>
<div class="paragraph">
<p><strong>Server Group</strong>: Choose server group in the drop down menu</p>
</div>
<div class="paragraph">
<p><strong>Max Threads</strong>: Choose how many simultaneous workers</p>
</div>
<div class="paragraph">
<p><strong>Delay, each message</strong>: How many seconds to sleep a thread after a bundle has been sent, in case receiver has trouble processing</p>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/filled_initial_load_startscreen.png" alt="initial-load-startscreen" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p>To limit the data that will be send to Boomi it is possible to define filter values. Click on the <strong>'Dynamic where criterias'</strong> button to filter the values. You will then be redirected to a new page showing the fields in the table selected previously, which in this example is the <em>'SKAT'</em> table. Double click on the folder shown in the upper left-hand corner to see the fields included in the <em>'SKAT'</em> table. Then double click on the field you want to filter and fill in the values in the boxes that shows up. If you want to remove one of the filters, then double click on the field you want to remove in the menu on the left.</p>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/dynamic_selections.png" alt="dynamic-selections" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p>When the values are filled in click on the <strong>'Save'</strong> button. You will then return to the Boomi for SAP Table Service Extract page.</p>
</div>
<div class="paragraph">
<p>When the information has been filled in and you have filtered the fields if relevant, press on the <strong>'Execute'</strong> button. If the extraction is successful, a message will inform regarding the number of extracted messages. In case of errors, an error message will be shown.</p>
</div>
<div class="paragraph">
<p>In the above <em>'SKAT'</em> example <em>Max Records</em> were specified as <em>10.000 Max Records</em>, but after applying a filter there is only <em>6601</em> that meets our Dynamic Criteria. These are then successfully sent as seen below.</p>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/successful_run.png" alt="successful_run" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
</div>
</div>
</div></template>
<script>export default {}; </script>