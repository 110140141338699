<template>
  <div class="subpage-wrapper">
    <div class="header-banner">
      <div class="image-wrapper">
        <v-img
          alt="Boomi for SAP"
          contain
          src="@/graphics/birds.svg"
        />
      </div>
      <v-container class="content">
        <h1>Boomi for SAP Learning Hub</h1>
      </v-container>
    </div>
    <v-container class="content-section">
      <v-card
        width="100%"
        class="pa-10 mb-10"
      >
        <v-row class="buttons-wrapper" align="center">
          <v-col cols="12" sm="12">
            <div class="text-center">
              <v-row class="buttons-wrapper" align="center">
                <v-col cols="12" sm="6">
                  <div class="text-center">
                    <div class="aXisColum">
                      <h2 class="section-headline pb-4">Boomi for SAP Learning Hub</h2>
                      <div>
                        <v-btn
                        min-width="60%"
                        style="color: white"
                        color="accent"
                        class="ml-4"
                        to="/Learning-Hub/Features">Feature videos</v-btn>
                      </div>
                      <br>
                      <div>
                        <v-btn
                        min-width="60%"
                        style="color: white"
                        color="accent"
                        class="ml-4"
                        to="/Learning-Hub/InstallationGuide">Installation guide</v-btn>
                      </div>
                      <br>
                      <div>
                        <v-btn
                        min-width="60%"
                        style="color: white"
                        class="ml-4"
                        color="accent"
                        to="/Learning-Hub/ConfigurationGuide">Configuration Guide</v-btn>
                      </div>
                    </div>
                  </div>
                  <br>
                  <br>
                </v-col>
                <v-divider vertical></v-divider>
                <v-col cols="12" sm="6">
                  <div class="text-center">
                    <div class="boomiColum">
                      <h2 class="section-headline pb-4">BoomiVerse</h2>
                      <div>
                        <v-btn
                        min-width="60%"
                        style="color: white"
                        color="accent"
                        class="ml-4"
                        target="_blank"
                        href="https://community.boomi.com/s/learning-plan-detail-standard?ltui__urlRecordId=aOM6S0000008OJXWA2&ltui__urlRedirect=learning-plan-detail-standard"
                        >Boomi for SAP certification</v-btn>
                      </div>
                      <br>
                      <div>
                        <v-btn
                        min-width="60%"
                        style="color: white"
                        class="ml-4"
                        color="accent"
                        target="_blank"
                        href="https://community.boomi.com/s/global-search/%40uri#q=axis&t=All&sort=relevancy&f:@objecttype=[Knowledge_Article]"
                        >Recipes</v-btn>
                      </div>
                      <br>
                      <div>
                        <v-btn
                        min-width="60%"
                        style="color: white"
                        class="ml-4"
                        color="accent"
                        target="_blank"
                        href="https://platform.boomi.com/AtomSphere.html#build;processLibrary=1f704c78-fd01-42d7-9735-e2dccdeb0d27"
                        >Boomi Process Library </v-btn>
                      </div>
                    </div>
                  </div>
                  <br>
                  <br>
                </v-col>
              </v-row>
            </div>
          </v-col>
        </v-row>
        <br/>
        <v-row no-gutters class="hexagon-row" align="center">
          <v-col class="hexagon-col-left" align="center">
            <img
              style="position:relative; top:0; left:0;" width="200px" height="200px"
              alt="invixo"
              src="@/assets/axis-hexagon.svg" />
            <img
              style="position:absolute; top:48%; left: 50%; transform: translate(-50%, -50%); max-width: 115px;"
              alt="invixo"
              src="@/assets/boomi_for_sap.svg" />
          </v-col>
          <v-col class="hexagon-col-right" align="center">
            <v-img
              style="position:relative; top:0; left:0;" width="200px" height="200px"
              alt="invixo"
              src="@/assets/axis-hexagon.svg" />
            <v-img
              style="position:absolute; top:47%; left: 50%; transform: translate(-50%, -50%); max-width: 100px;"
              alt="invixo"
              src="@/assets/Boomi-logo-color-positive.svg" />
          </v-col>
        </v-row>
      </v-card>
    </v-container>
  </div>
</template>

<style scoped lang="scss">
@import '~vuetify/src/styles/settings/_variables';

.hexagon-row {
  position: relative;
  margin: auto;
  width: 70%
}
.hexagon-col-left {
  position: relative;
  margin-left: -23%;
  margin-top: -5px;
}
.hexagon-col-right {
  position: relative;
  margin-right: -25%;
}

</style>
<script>
import Vue from "vue";
import { Component } from "vue-property-decorator";
import Elearning from "./index.vue";

@Component({ components: { Elearning } })
export default class index extends Vue {}
</script>