<template>
  <footer>
    <v-container class="py-10 d-flex fill-height">
      <div class="logo-wrapper d-flex fill-height align-end">
        <router-link class="logo-link" to="/">
          <v-img
            v-on:click="scrollToTop"
            alt="Boomi for SAP"
            contain
            src="@/assets/boomi_for_sap.svg"
            height="70"
          />
        </router-link>
      </div>
    </v-container>
  </footer>
</template>

<script>
export default {
  methods: {
    scrollToTop: function() {
      window.scroll(0, 0);
    }
  }
};
</script>

<style scoped lang="scss">
footer {
  color: white;
  background-color: #435563;

  .logo-wrapper {
    width: 100%;
    text-align: center;

    .logo-link {
      margin: auto;
    }
  }
  .footer-column {
    .headline {
      font-weight: 500;
      color: #F2B803;
      padding-bottom: 14px;
    }

    div {
      padding-left: 40px;
    }

    a {
      display: block;
      color: white;
    }
  }
}
</style>