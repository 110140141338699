<template><div>
<div id="toc" class="toc">
<div id="toctitle">Table of Contents</div>
<ul class="sectlevel1">
<li><a href="#_prerequisites_for_installation">1. Prerequisites for installation</a>
<ul class="sectlevel2">
<li><a href="#_7_31">1.1. 7.31</a></li>
<li><a href="#_7_40">1.2. 7.40</a></li>
</ul>
</li>
<li><a href="#_installation_configuration">2. Installation &amp; configuration</a>
<ul class="sectlevel2">
<li><a href="#_installation_of_add_on">2.1. Installation of Add-On</a></li>
<li><a href="#_installation_of_add_on_upgrade">2.2. Installation of Add-On, upgrade</a></li>
<li><a href="#_configuration">2.3. Configuration</a></li>
<li><a href="#_sicf_setup">2.4. SICF Setup</a></li>
</ul>
</li>
<li><a href="#_de_installation_clean_up">3. De-Installation &amp; Clean-up</a>
<ul class="sectlevel2">
<li><a href="#_de_installation">3.1. De-installation</a></li>
<li><a href="#_sicf_and_event_clean_up">3.2. SICF and event clean-up</a></li>
</ul>
</li>
<li><a href="#_connectivity">4. Connectivity</a></li>
<li><a href="#_template_boomi_processes">5. Template Boomi Processes</a></li>
<li><a href="#_boomi_for_sap_security_guidelines">6. Boomi for SAP Security Guidelines</a></li>
<li><a href="#_authorizations">7. Authorizations</a>
<ul class="sectlevel2">
<li><a href="#_transactions_and_roles">7.1. Transactions and Roles</a></li>
<li><a href="#_websocket_receiver">7.2. Websocket Receiver</a></li>
</ul>
</li>
</ul>
</div>
<div class="sect1">
<h2 class="section-headline pb-4" id="_prerequisites_for_installation">1. Prerequisites for installation</h2>
<div class="sectionbody">
<div class="sect2">
<h2 id="_7_31" class="section-headline pb-4">1.1. 7.31</h2>
<div class="paragraph">
<p>Prerequisites for running Boomi for SAP Add-On in your <strong>7.31</strong> system are:</p>
</div>
<div class="paragraph">
<p>The Boomi for SAP Add-On supports down to SAP_ABAP 731 SP21.</p>
</div>
<div class="ulist">
<ul>
<li>
<p>UI5_731</p>
</li>
<li>
<p>UI_731</p>
</li>
</ul>
</div>
</div>
<div class="sect2">
<h2 id="_7_40" class="section-headline pb-4">1.2. 7.40</h2>
<div class="paragraph">
<p>Prerequisite for running Boomi for SAP Add-On in your <strong>7.4</strong> system is:</p>
</div>
<div class="paragraph">
<p>The Boomi for SAP Add-On supports down to SAP_ABAP 740 SP08.</p>
</div>
<div class="ulist">
<ul>
<li>
<p>SAP_UI 740 SP12</p>
</li>
</ul>
</div>
</div>
</div>
</div>
<div class="sect1">
<h2 class="section-headline pb-4" id="_installation_configuration">2. Installation &amp; configuration</h2>
<div class="sectionbody">
<div class="sect2">
<h2 id="_installation_of_add_on" class="section-headline pb-4">2.1. Installation of Add-On</h2>
<div class="paragraph">
<p><strong>Upload installation file</strong></p>
</div>
<div class="paragraph">
<p>Logon to your SAP system in client 000</p>
</div>
<div class="paragraph">
<p>The installation file(s) should be placed in the folder <em><strong>'*/EPS/in/'</strong></em>. You can find the path with <em>al11</em>.</p>
</div>
<div class="paragraph">
<p>First you need to need to upload the <em>PAT</em> fil(s) into your SAP system by using transaction <em><strong>CG3Z</strong></em></p>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./../sap-certification/graphics/uploadfile.png" alt="uploadfile" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p>Press <strong>'Upload'</strong></p>
</div>
<div class="paragraph">
<p>Via <em>al11</em> you can see the file uploaded:</p>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./../sap-certification/graphics/directory.png" alt="directory" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p><strong>Installation of the Add-On</strong></p>
</div>
<div class="paragraph">
<p>Go to <em>SAINT</em>, press on <strong>'Installation Package'</strong>, then press on <strong>'Load packages'</strong> and then <strong>'EPS files from application server'</strong>. This to import the Add-On installation file(s). Installation of the Add-On must be done in each system in the system landscape via SAINT. The SICF configuration is performed in the Sandbox and/or Development environment and transported the rest of the systems in the landscape.</p>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./../sap-certification/graphics/installation.png" alt="installation" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p>Press on <strong>'Start'</strong></p>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./../sap-certification/graphics/addon.png" alt="addon" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p>Choose <strong>'IXBX'</strong> in the <em>Add-On list</em> and press on <strong>'Continue'</strong> and <strong>'Continue'</strong> again and then again</p>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./../sap-certification/graphics/addon2.png" alt="addon2" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p>You are now asked if Modification Adjustment Transport are relevant for the queue. Choose 'No' as the Add-On do not have any
A pop-up shows, and you are asked if Modification Adjustment Transport are relevant for the queue. Choose 'No' as the Add-On do not have any. Then press <strong>'Continue'</strong> again and then again.</p>
</div>
<div class="paragraph">
<p>Note: The Add-On must be installed via SAP standard tool SAINT in each system in your landscape.</p>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./../sap-certification/graphics/yes.png" alt="yes" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p>Now the installation starts up.</p>
</div>
<div class="paragraph">
<p>When the installation is done, press <strong>'Finish'</strong> and the installation is done.</p>
</div>
</div>
<div class="sect2">
<h2 id="_installation_of_add_on_upgrade" class="section-headline pb-4">2.2. Installation of Add-On, upgrade</h2>
<div class="paragraph">
<p>If you already have a version of the product installed, then follow the same steps as descripted in the previous section.</p>
</div>
</div>
<div class="sect2">
<h2 id="_configuration" class="section-headline pb-4">2.3. Configuration</h2>
<div class="paragraph">
<p>After finalizing the installation you need to set-up zbx in SICF and the BW Extractor, if you want to utilize the BW Extractor functionality.</p>
</div>
<div class="paragraph">
<p>Furthermore it is recommended to create roles to support the functionality in Boomi for SAP and assigne them to the relevant users.</p>
</div>
</div>
<div class="sect2">
<h2 id="_sicf_setup" class="section-headline pb-4">2.4. SICF Setup</h2>
<div class="paragraph">
<p>Run transaction <em><strong>SICF</strong></em>
Click on the <strong>'Execute'</strong> button &lt;F8&gt;</p>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./../end-user-documentation/installation-guide/graphics/execute.png" alt="execute" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p>Click <strong>'default host'</strong>, then click <strong>'Create Host/Service'</strong></p>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./../end-user-documentation/installation-guide/graphics/default-host.png" alt="default-host" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p>Click then on the <strong>'Continue'</strong> button &lt;ENTER&gt;</p>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./../end-user-documentation/installation-guide/graphics/continue.png" alt="continue" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p>Enter <em><strong>ZBX</strong></em> as service name. Choose Independent Service. Press on the <strong>'Input'</strong> button &lt;ENTER&gt;</p>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./../end-user-documentation/installation-guide/graphics/service-element.png" alt="service-element" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p>Enter <em><strong>Boomi for SAP</strong></em> as Description 1.
Enter handler class <em><strong>/IXBX/CL_SICF</strong></em> in the <em>Handler List</em> tab</p>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./../end-user-documentation/installation-guide/graphics/handler.png" alt="handler" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p>Update data for <em>Service data</em> and <em>Logon Data</em> if needed dependent on customer requirements.
Press <strong>'Store'</strong> &lt;CTRL+S&gt; to save press <strong>'Back'</strong> &lt;F3&gt; afterwards to leave to main menu (package and transports according to customer requirements).</p>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./../end-user-documentation/installation-guide/graphics/update-data.png" alt="update-data" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p>To activate service select it by clicking it and press &lt;SHIFT+F11&gt;</p>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./../end-user-documentation/installation-guide/graphics/active-service.png" alt="active-service" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p>Click <strong>'Yes'</strong></p>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./../end-user-documentation/installation-guide/graphics/yes.png" alt="yes" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div style="page-break-after: always;"></div>
</div>
</div>
</div>
<div class="sect1">
<h2 class="section-headline pb-4" id="_de_installation_clean_up">3. De-Installation &amp; Clean-up</h2>
<div class="sectionbody">
<div class="paragraph">
<p>For de-installation of <strong>Boomi for SAP</strong> follow the de-installation guide.</p>
</div>
<div class="sect2">
<h2 id="_de_installation" class="section-headline pb-4">3.1. De-installation</h2>
<div class="paragraph">
<p>Before starting the de-installation you need to remove zbx in SICF.</p>
</div>
</div>
<div class="sect2">
<h2 id="_sicf_and_event_clean_up" class="section-headline pb-4">3.2. SICF and event clean-up</h2>
<div class="ulist">
<ul>
<li>
<p>(TC:SICF) Delete SICF implementation for the Boomi for SAP component.</p>
</li>
<li>
<p>(TC:SWETYPV) Delete events that has been implemented using the Boomi for SAP provided components</p>
</li>
<li>
<p>(TC:BD52) Remove change document triggers for the Boomi for SAP message(s)</p>
</li>
</ul>
</div>
<div class="paragraph">
<p>Furthermore it is recommended to remove roles created for Boomi for SAP.</p>
</div>
<div class="paragraph">
<p>Also notice that you will have to delete all Change Pointers with the Message Type /IXBX/ZBX_EVENT.</p>
</div>
<div class="paragraph">
<p>After de-installation all table services and the BW staging data will be removed during de-installation. If you need the data after de-installing please make a copy of these beforehand.</p>
</div>
<div class="paragraph">
<p>Logon to your SAP system in client 000</p>
</div>
<div class="paragraph">
<p>Go to <em><strong>SAINT</strong></em> and choose <strong>'Uninstallable components'</strong> and press <strong>'Start'</strong></p>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./../sap-certification/graphics/tool.png" alt="tool" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p>Choose to <em>Start in dialog</em> and <strong>'Continue'</strong> and press <strong>'Finish'</strong> when the de-installation is done.</p>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./../sap-certification/graphics/options.png" alt="options" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p>The de-installation is now done, and all the components related to the product is deleted from your system.</p>
</div>
</div>
</div>
</div>
<div class="sect1">
<h2 class="section-headline pb-4" id="_connectivity">4. Connectivity</h2>
<div class="sectionbody">
<div class="ulist">
<ul>
<li>
<p>Set up an <strong>RFC Connection Type "G", HTTP Connections to External Server</strong> in SAP via SM59</p>
</li>
<li>
<p>Set up a Web service in Boomi</p>
</li>
</ul>
</div>
</div>
</div>
<div class="sect1">
<h2 class="section-headline pb-4" id="_template_boomi_processes">5. Template Boomi Processes</h2>
<div class="sectionbody">
<div class="ulist">
<ul>
<li>
<p>Import Boomi for SAP Boomi Processes from Boomi Process Library</p>
</li>
</ul>
</div>
<div class="paragraph">
<p><br></p>
</div>
</div>
</div>
<div class="sect1">
<h2 class="section-headline pb-4" id="_boomi_for_sap_security_guidelines">6. Boomi for SAP Security Guidelines</h2>
<div class="sectionbody">
<div class="paragraph">
<p><strong>Authorization &amp; Security</strong></p>
</div>
<div class="paragraph">
<p>Boomi for SAP is built on ABAP, so it follows SAP&#8217;s established mechanisms for handling authorization and security. This ensures that your SAP system remains secure.</p>
</div>
<div class="paragraph">
<p><strong>Authentication Engine</strong></p>
</div>
<div class="paragraph">
<p>For REST services, Boomi for SAP utilizes ICM as the authentication engine. This enhances the security of your REST services by leveraging ICM&#8217;s capabilities.</p>
</div>
<div class="paragraph">
<p><strong>Role Compatibility</strong></p>
</div>
<div class="paragraph">
<p>Boomi for SAP does not introduce new roles that override your existing PCFG roles. This means you won&#8217;t have to make extensive changes to your current role configurations.</p>
</div>
<div class="paragraph">
<p><strong>Dedicated Roles</strong></p>
</div>
<div class="paragraph">
<p>Boomi for SAP provides dedicated roles that grant access to transactions specifically designed for Boomi for SAP. This simplifies role management and ensures that users have the right permissions for Boomi for SAP-related tasks.</p>
</div>
<div class="paragraph">
<p><strong>Base Path</strong></p>
</div>
<div class="paragraph">
<p>To access all REST JSON services and UI components in Boomi for SAP, it must run from the "/zbx/" base path. This standardizes the location for these services and components, making it easier to manage and access them.</p>
</div>
<div class="paragraph">
<p>By following these guidelines, you can ensure a secure and streamlined experience with Boomi for SAP.</p>
</div>
<div class="paragraph">
<p><br></p>
</div>
</div>
</div>
<div class="sect1">
<h2 class="section-headline pb-4" id="_authorizations">7. Authorizations</h2>
<div class="sectionbody">
<div class="paragraph">
<p>Development for Boomi for SAP only runs from the UI in a development client. In non-development clients it is possible to display services created.</p>
</div>
<div class="paragraph">
<p>This means: If your development system have multiple clients e.g. a development client and a data client, the development UI need to be accessed in the development client, whereas the data extracts utilizing the table services need to be accessed in the data client (Runtime role), where the services only is displayed in the UI.</p>
</div>
<div class="sect2">
<h2 id="_transactions_and_roles" class="section-headline pb-4">7.1. Transactions and Roles</h2>
<div class="paragraph">
<p>A set of transactions are provided in the installation of Boomi for SAP (see next section). If you choose to use the transaction code directly, you need to type '/n' in front of the transaction code for example /n/IXBX/START_UI.</p>
</div>
<div class="paragraph">
<p>If you choose to utilize the roles included in the Boomi for SAP Add-On installation, you need to create a transport(s) via T-code PFCG for the roles in client 000 and then pull the transport to the other clients in your development system. Furthermore, the transport(s) need to be imported to the system(s) in your landscape.</p>
</div>
<div class="sect3">
<h3 id="_runtime_role" class="section-headline pb-4">Runtime role</h3>
<div class="paragraph">
<p>Background user - system user (called from target system e.g. Boomi)</p>
</div>
</div>
<div class="sect3">
<h3 id="_work_flow_user" class="section-headline pb-4">Work Flow user</h3>
<div class="paragraph">
<p>To be able to sent events from SAP, the WF-Batch user has to be created and be active. Furthermore the RFC Destination has to be configured.</p>
</div>
</div>
<div class="sect3">
<h3 id="_roles_delivered_with_the_boomi_for_sap_installation" class="section-headline pb-4">Roles delivered with the Boomi for SAP installation:</h3>
<table id="rolesTable1" class="tableblock frame-all grid-all stretch">
<colgroup>
<col style="width: 33.3333%;">
<col style="width: 33.3333%;">
<col style="width: 33.3334%;">
</colgroup>
<thead>
<tr>
<th class="tableblock halign-left valign-top">Role</th>
<th class="tableblock halign-left valign-top">T-Codes</th>
<th class="tableblock halign-left valign-top">Description</th>
</tr>
</thead>
<tbody>
<tr>
<td class="tableblock halign-left valign-top"><p class="tableblock">/IXBX/AXIS_ALL &amp; /IXBX/BOOMI_FOR_SAP_ALL</p></td>
<td class="tableblock halign-left valign-top"><p class="tableblock">Include all roles</p></td>
<td class="tableblock halign-left valign-top"></td>
</tr>
<tr>
<td class="tableblock halign-left valign-top"><p class="tableblock">/IXBX/UI</p></td>
<td class="tableblock halign-left valign-top"><p class="tableblock">UI</p></td>
<td class="tableblock halign-left valign-top"></td>
</tr>
<tr>
<td class="tableblock halign-left valign-top"></td>
<td class="tableblock halign-left valign-top"><p class="tableblock">/IXBX/ZBX001</p></td>
<td class="tableblock halign-left valign-top"><p class="tableblock">Open UI</p></td>
</tr>
<tr>
<td class="tableblock halign-left valign-top"></td>
<td class="tableblock halign-left valign-top"><p class="tableblock">SM59</p></td>
<td class="tableblock halign-left valign-top"><p class="tableblock">RFC Destinations Display</p></td>
</tr>
<tr>
<td class="tableblock halign-left valign-top"><p class="tableblock">/IXBX/FUNCTIONMODULE</p></td>
<td class="tableblock halign-left valign-top"><p class="tableblock">Function Module</p></td>
<td class="tableblock halign-left valign-top"></td>
</tr>
<tr>
<td class="tableblock halign-left valign-top"></td>
<td class="tableblock halign-left valign-top"><p class="tableblock">/IXBX/ZBX010</p></td>
<td class="tableblock halign-left valign-top"><p class="tableblock">Function module settings</p></td>
</tr>
<tr>
<td class="tableblock halign-left valign-top"><p class="tableblock">/IXBX/TABLE_SERVICE_EXTRACT</p></td>
<td class="tableblock halign-left valign-top"><p class="tableblock">Table Service Extract</p></td>
<td class="tableblock halign-left valign-top"></td>
</tr>
<tr>
<td class="tableblock halign-left valign-top"></td>
<td class="tableblock halign-left valign-top"><p class="tableblock">/IXBX/ZBX008</p></td>
<td class="tableblock halign-left valign-top"><p class="tableblock">Table service extract</p></td>
</tr>
<tr>
<td class="tableblock halign-left valign-top"><p class="tableblock">IXBX/EVENT</p></td>
<td class="tableblock halign-left valign-top"><p class="tableblock">Event</p></td>
<td class="tableblock halign-left valign-top"></td>
</tr>
<tr>
<td class="tableblock halign-left valign-top"></td>
<td class="tableblock halign-left valign-top"><p class="tableblock">/IXBX/ZBX003</p></td>
<td class="tableblock halign-left valign-top"><p class="tableblock">List events</p></td>
</tr>
<tr>
<td class="tableblock halign-left valign-top"></td>
<td class="tableblock halign-left valign-top"><p class="tableblock">/IXBX/ZBX004</p></td>
<td class="tableblock halign-left valign-top"><p class="tableblock">Send events</p></td>
</tr>
<tr>
<td class="tableblock halign-left valign-top"></td>
<td class="tableblock halign-left valign-top"><p class="tableblock">/IXBX/ZBX011</p></td>
<td class="tableblock halign-left valign-top"><p class="tableblock">Event subscriptions</p></td>
</tr>
<tr>
<td class="tableblock halign-left valign-top"></td>
<td class="tableblock halign-left valign-top"><p class="tableblock">/IXBX/ZBX012</p></td>
<td class="tableblock halign-left valign-top"><p class="tableblock">Clean up obsolete EDA events</p></td>
</tr>
<tr>
<td class="tableblock halign-left valign-top"></td>
<td class="tableblock halign-left valign-top"><p class="tableblock">/IXBX/ZBX013</p></td>
<td class="tableblock halign-left valign-top"><p class="tableblock">Remove from Dispatch layer</p></td>
</tr>
<tr>
<td class="tableblock halign-left valign-top"></td>
<td class="tableblock halign-left valign-top"><p class="tableblock">/IXBX/ZBX018</p></td>
<td class="tableblock halign-left valign-top"><p class="tableblock">Event subscriptions - Advanced</p></td>
</tr>
<tr>
<td class="tableblock halign-left valign-top"></td>
<td class="tableblock halign-left valign-top"><p class="tableblock">SWETYPV</p></td>
<td class="tableblock halign-left valign-top"><p class="tableblock">Display/Maint. Event Type Linkages</p></td>
</tr>
<tr>
<td class="tableblock halign-left valign-top"></td>
<td class="tableblock halign-left valign-top"><p class="tableblock">BD52</p></td>
<td class="tableblock halign-left valign-top"><p class="tableblock">Activ.change pointer per chng.doc.it</p></td>
</tr>
</tbody>
</table>
<table id="rolesTable2" class="tableblock frame-all grid-all stretch">
<colgroup>
<col style="width: 33.3333%;">
<col style="width: 33.3333%;">
<col style="width: 33.3334%;">
</colgroup>
<thead>
<tr>
<th class="tableblock halign-left valign-top">Role</th>
<th class="tableblock halign-left valign-top">T-Codes</th>
<th class="tableblock halign-left valign-top">Description</th>
</tr>
</thead>
<tbody>
<tr>
<td class="tableblock halign-left valign-top"><p class="tableblock">IXBX/BW</p></td>
<td class="tableblock halign-left valign-top"><p class="tableblock">BW Data Extractor</p></td>
<td class="tableblock halign-left valign-top"></td>
</tr>
<tr>
<td class="tableblock halign-left valign-top"></td>
<td class="tableblock halign-left valign-top"><p class="tableblock">/IXBX/ZBX006</p></td>
<td class="tableblock halign-left valign-top"><p class="tableblock">Activation BW extract</p></td>
</tr>
<tr>
<td class="tableblock halign-left valign-top"></td>
<td class="tableblock halign-left valign-top"><p class="tableblock">/IXBX/ZBX007</p></td>
<td class="tableblock halign-left valign-top"><p class="tableblock">Send BW extraction events</p></td>
</tr>
<tr>
<td class="tableblock halign-left valign-top"></td>
<td class="tableblock halign-left valign-top"><p class="tableblock">/IXBX/ZBX009</p></td>
<td class="tableblock halign-left valign-top"><p class="tableblock">BW extraction delete</p></td>
</tr>
<tr>
<td class="tableblock halign-left valign-top"></td>
<td class="tableblock halign-left valign-top"><p class="tableblock">RSA2</p></td>
<td class="tableblock halign-left valign-top"><p class="tableblock">Extractor Checker</p></td>
</tr>
<tr>
<td class="tableblock halign-left valign-top"><p class="tableblock">IXBX/IDOC</p></td>
<td class="tableblock halign-left valign-top"><p class="tableblock">IDoc</p></td>
<td class="tableblock halign-left valign-top"></td>
</tr>
<tr>
<td class="tableblock halign-left valign-top"></td>
<td class="tableblock halign-left valign-top"><p class="tableblock">/IXBX/ZBX016</p></td>
<td class="tableblock halign-left valign-top"><p class="tableblock">Alternative IDoc partner</p></td>
</tr>
<tr>
<td class="tableblock halign-left valign-top"></td>
<td class="tableblock halign-left valign-top"><p class="tableblock">/IXBX/ZBX017</p></td>
<td class="tableblock halign-left valign-top"><p class="tableblock">Alternative IDoc port RFC</p></td>
</tr>
<tr>
<td class="tableblock halign-left valign-top"></td>
<td class="tableblock halign-left valign-top"><p class="tableblock">/IXBX/IDOC</p></td>
<td class="tableblock halign-left valign-top"><p class="tableblock">IDoc Authorization Object</p></td>
</tr>
<tr>
<td class="tableblock halign-left valign-top"><p class="tableblock">IXBX/TABLE_SERVICE_RFC</p></td>
<td class="tableblock halign-left valign-top"><p class="tableblock">Table Service RFC</p></td>
<td class="tableblock halign-left valign-top"></td>
</tr>
<tr>
<td class="tableblock halign-left valign-top"></td>
<td class="tableblock halign-left valign-top"><p class="tableblock">/IXBX/ZBX014</p></td>
<td class="tableblock halign-left valign-top"><p class="tableblock">Allow RFC table service test</p></td>
</tr>
<tr>
<td class="tableblock halign-left valign-top"></td>
<td class="tableblock halign-left valign-top"><p class="tableblock">/IXBX/ZBX015</p></td>
<td class="tableblock halign-left valign-top"><p class="tableblock">Table Service test RFC dest.</p></td>
</tr>
<tr>
<td class="tableblock halign-left valign-top"></td>
<td class="tableblock halign-left valign-top"><p class="tableblock">/IXBX/RFC</p></td>
<td class="tableblock halign-left valign-top"><p class="tableblock">RFC Authorisation Object</p></td>
</tr>
<tr>
<td class="tableblock halign-left valign-top"><p class="tableblock">IXBX/HYPERAUTOMATION</p></td>
<td class="tableblock halign-left valign-top"><p class="tableblock">Hyper Automation</p></td>
<td class="tableblock halign-left valign-top"></td>
</tr>
<tr>
<td class="tableblock halign-left valign-top"></td>
<td class="tableblock halign-left valign-top"><p class="tableblock">/IXBX/ZBX002</p></td>
<td class="tableblock halign-left valign-top"><p class="tableblock">Hyper Automation</p></td>
</tr>
<tr>
<td class="tableblock halign-left valign-top"><p class="tableblock">IXBX/RECEIVER</p></td>
<td class="tableblock halign-left valign-top"><p class="tableblock">Receiver</p></td>
<td class="tableblock halign-left valign-top"></td>
</tr>
<tr>
<td class="tableblock halign-left valign-top"></td>
<td class="tableblock halign-left valign-top"><p class="tableblock">/IXBX/ZBX005</p></td>
<td class="tableblock halign-left valign-top"><p class="tableblock">Custom data conversion filter</p></td>
</tr>
<tr>
<td class="tableblock halign-left valign-top"></td>
<td class="tableblock halign-left valign-top"><p class="tableblock">/IXBX/ZBX019</p></td>
<td class="tableblock halign-left valign-top"><p class="tableblock">Data Conv&amp;RecType Mapping</p></td>
</tr>
<tr>
<td class="tableblock halign-left valign-top"><p class="tableblock">IXBX/RECEIVER_MIGRATION</p></td>
<td class="tableblock halign-left valign-top"><p class="tableblock">Receiver Migration</p></td>
<td class="tableblock halign-left valign-top"></td>
</tr>
<tr>
<td class="tableblock halign-left valign-top"></td>
<td class="tableblock halign-left valign-top"><p class="tableblock">/IXBX/ZBX020</p></td>
<td class="tableblock halign-left valign-top"><p class="tableblock">Receiver Migration Tool</p></td>
</tr>
<tr>
<td class="tableblock halign-left valign-top"></td>
<td class="tableblock halign-left valign-top"><p class="tableblock">/IXBX/MIGR</p></td>
<td class="tableblock halign-left valign-top"><p class="tableblock">Migration Authorisation Object</p></td>
</tr>
</tbody>
</table>
</div>
<div class="sect3">
<h3 id="_transactions_overview" class="section-headline pb-4">Transactions overview</h3>
<table id="transactionsTable1" class="tableblock frame-all grid-all stretch">
<colgroup>
<col style="width: 20%;">
<col style="width: 40%;">
<col style="width: 40%;">
</colgroup>
<thead>
<tr>
<th class="tableblock halign-left valign-top">Description</th>
<th class="tableblock halign-left valign-top">Numerical</th>
<th class="tableblock halign-left valign-top">Non-Numerical</th>
</tr>
</thead>
<tbody>
<tr>
<td class="tableblock halign-left valign-top"><p class="tableblock">Start Web UI</p></td>
<td class="tableblock halign-left valign-top"><p class="tableblock">/IXBX/ZBX001</p></td>
<td class="tableblock halign-left valign-top"><div class="content"><div class="paragraph">
<p>/IXBX/START_UI</p>
</div></div></td>
</tr>
<tr>
<td class="tableblock halign-left valign-top"><p class="tableblock">Hyper Automation</p></td>
<td class="tableblock halign-left valign-top"><p class="tableblock">/IXBX/ZBX002</p></td>
<td class="tableblock halign-left valign-top"><div class="content"><div class="paragraph">
<p>/IXBX/HYPER_AUTO</p>
</div></div></td>
</tr>
<tr>
<td class="tableblock halign-left valign-top"><p class="tableblock">List Events</p></td>
<td class="tableblock halign-left valign-top"><p class="tableblock">/IXBX/ZBX003</p></td>
<td class="tableblock halign-left valign-top"><div class="content"><div class="paragraph">
<p>/IXBX/EVENT_LIST</p>
</div></div></td>
</tr>
<tr>
<td class="tableblock halign-left valign-top"><p class="tableblock">Send Events</p></td>
<td class="tableblock halign-left valign-top"><p class="tableblock">/IXBX/ZBX004</p></td>
<td class="tableblock halign-left valign-top"><div class="content"><div class="paragraph">
<p>/IXBX/EVENT_SEND</p>
</div></div></td>
</tr>
<tr>
<td class="tableblock halign-left valign-top"><p class="tableblock">Maintain custom data conversion filter</p></td>
<td class="tableblock halign-left valign-top"><p class="tableblock">/IXBX/ZBX005</p></td>
<td class="tableblock halign-left valign-top"><div class="content"><div class="paragraph">
<p>/IXBX/DATA_CONV</p>
</div></div></td>
</tr>
<tr>
<td class="tableblock halign-left valign-top"><p class="tableblock">Active BW extractor</p></td>
<td class="tableblock halign-left valign-top"><p class="tableblock">/IXBX/ZBX006</p></td>
<td class="tableblock halign-left valign-top"><div class="content"><div class="paragraph">
<p>/IXBX/BW_ACTIVATE</p>
</div></div></td>
</tr>
<tr>
<td class="tableblock halign-left valign-top"><p class="tableblock">Send BW extraction events</p></td>
<td class="tableblock halign-left valign-top"><p class="tableblock">/IXBX/ZBX007</p></td>
<td class="tableblock halign-left valign-top"><div class="content"><div class="paragraph">
<p>/IXBX/BW_EVENT_SEND</p>
</div></div></td>
</tr>
<tr>
<td class="tableblock halign-left valign-top"><p class="tableblock">Table Service Extract</p></td>
<td class="tableblock halign-left valign-top"><p class="tableblock">/IXBX/ZBX008</p></td>
<td class="tableblock halign-left valign-top"><div class="content"><div class="paragraph">
<p>/IXBX/EVENT_INILOADX</p>
</div></div></td>
</tr>
<tr>
<td class="tableblock halign-left valign-top"><p class="tableblock">Delete BW extraction</p></td>
<td class="tableblock halign-left valign-top"><p class="tableblock">/IXBX/ZBX009</p></td>
<td class="tableblock halign-left valign-top"><div class="content"><div class="paragraph">
<p>/IXBX/BW_EXTRACT_DEL</p>
</div></div></td>
</tr>
<tr>
<td class="tableblock halign-left valign-top"><p class="tableblock">Function Modules Settings</p></td>
<td class="tableblock halign-left valign-top"><p class="tableblock">/IXBX/ZBX010</p></td>
<td class="tableblock halign-left valign-top"><div class="content"><div class="paragraph">
<p>/IXBX/FM_SETTINGS</p>
</div></div></td>
</tr>
<tr>
<td class="tableblock halign-left valign-top"><p class="tableblock">Process EDA subscriptions</p></td>
<td class="tableblock halign-left valign-top"><p class="tableblock">/IXBX/ZBX011</p></td>
<td class="tableblock halign-left valign-top"><div class="content"><div class="paragraph">
<p>/IXBX/PROCESS_EVENTS</p>
</div></div></td>
</tr>
<tr>
<td class="tableblock halign-left valign-top"><p class="tableblock">Clean up obsolete EDA events</p></td>
<td class="tableblock halign-left valign-top"><p class="tableblock">/IXBX/ZBX012</p></td>
<td class="tableblock halign-left valign-top"><div class="content"><div class="paragraph">
<p>/IXBX/CLEAN_UP_EVENT</p>
</div></div></td>
</tr>
<tr>
<td class="tableblock halign-left valign-top"><p class="tableblock">Remove from Dispatch layer</p></td>
<td class="tableblock halign-left valign-top"><p class="tableblock">/IXBX/ZBX013</p></td>
<td class="tableblock halign-left valign-top"><div class="content"><div class="paragraph">
<p>/IXBX/CLEAN_DISPATCH</p>
</div></div></td>
</tr>
<tr>
<td class="tableblock halign-left valign-top"><p class="tableblock">Allow RFC table service test</p></td>
<td class="tableblock halign-left valign-top"><p class="tableblock">/IXBX/ZBX014</p></td>
<td class="tableblock halign-left valign-top"><div class="content"><div class="paragraph">
<p>/IXBX/ALLOW_TS_RFC</p>
</div></div></td>
</tr>
<tr>
<td class="tableblock halign-left valign-top"><p class="tableblock">Table service test RFC dest.</p></td>
<td class="tableblock halign-left valign-top"><p class="tableblock">/IXBX/ZBX015</p></td>
<td class="tableblock halign-left valign-top"><div class="content"><div class="paragraph">
<p>/IXBX/TS_TEST_RFC</p>
</div></div></td>
</tr>
<tr>
<td class="tableblock halign-left valign-top"><p class="tableblock">Alternative IDoc partner</p></td>
<td class="tableblock halign-left valign-top"><p class="tableblock">/IXBX/ZBX016</p></td>
<td class="tableblock halign-left valign-top"><div class="content"><div class="paragraph">
<p>/IXBX/IDOC_ALT_PARTN</p>
</div></div></td>
</tr>
<tr>
<td class="tableblock halign-left valign-top"><p class="tableblock">Alternative IDoc port RFC</p></td>
<td class="tableblock halign-left valign-top"><p class="tableblock">/IXBX/ZBX017</p></td>
<td class="tableblock halign-left valign-top"><div class="content"><div class="paragraph">
<p>/IXBX/IDOC_ALT_RFC</p>
</div></div></td>
</tr>
<tr>
<td class="tableblock halign-left valign-top"><p class="tableblock">Event Process Advanced</p></td>
<td class="tableblock halign-left valign-top"><p class="tableblock">/IXBX/ZBX018</p></td>
<td class="tableblock halign-left valign-top"><div class="content"><div class="paragraph">
<p>/IXBX/EVENT_PROC_ADV</p>
</div></div></td>
</tr>
<tr>
<td class="tableblock halign-left valign-top"><p class="tableblock">Data Conversion &amp; Receiver Type Mapping</p></td>
<td class="tableblock halign-left valign-top"><p class="tableblock">/IXBX/ZBX019</p></td>
<td class="tableblock halign-left valign-top"><div class="content"><div class="paragraph">
<p>/IXBX/DCONV_RECV_MAP</p>
</div></div></td>
</tr>
<tr>
<td class="tableblock halign-left valign-top"><p class="tableblock">Receiver Migration (1.5.2 &#8594; 1.6.0)</p></td>
<td class="tableblock halign-left valign-top"><p class="tableblock">/IXBX/ZBX020</p></td>
<td class="tableblock halign-left valign-top"><div class="content"><div class="paragraph">
<p>/IXBX/MIGRATION_TOOL</p>
</div></div></td>
</tr>
</tbody>
</table>
<div id="tablesEnd" class="paragraph">
<p><br></p>
</div>
</div>
</div>
<div class="sect2">
<h2 id="_websocket_receiver" class="section-headline pb-4">7.2. Websocket Receiver</h2>
<div class="sect3">
<h3 id="_activating_websocket_endpoint_in_sicf" class="section-headline pb-4">Activating websocket Endpoint in SICF</h3>
<div class="ulist">
<ul>
<li>
<p>SICF settings has websocket endpoint disabled by default:</p>
</li>
</ul>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./../sap-certification/graphics/ws_receiver_disabled.png" alt="ws_disabled" />
</div>
</div>
<div class="ulist">
<ul>
<li>
<p>Right click on the service and select "Activate Service"</p>
</li>
</ul>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./../sap-certification/graphics/ws_receiver_activate.png" alt="ws_activate" />
</div>
</div>
<div class="ulist">
<ul>
<li>
<p>Choose yes for the popup and the endpoint is activated</p>
</li>
</ul>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./../sap-certification/graphics/ws_receiver_popup.png" alt="ws_popup" />
</div>
</div>
</div>
<div class="sect3">
<h3 id="_configuring_websocket_receiver_to_be_enabled_in_boomi_for_sap" class="section-headline pb-4">Configuring websocket receiver to be enabled in Boomi for SAP</h3>
<div class="ulist">
<ul>
<li>
<p>For transaction code /IXBX/ZBX019, add XML and JSON data conversions for receiver type /IXBX/CL_IXBX_RF_WEBSOCKET</p>
</li>
</ul>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./../sap-certification/graphics/ws_receiver_mapping.png" alt="ws_mapping" />
</div>
</div>
</div>
</div>
</div>
</div>
</div></template>
<script>export default {}; </script>