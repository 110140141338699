<template><div>
<div class="sect1">
<h2 class="section-headline pb-4" id="_table_service">Table Service</h2>
<div class="sectionbody">
<div class="paragraph">
<p>With Table Services, it is easy to expose tables and views from SAP so the data can be used in Boomi processes</p>
</div>
<div class="paragraph">
<p><strong>Features</strong></p>
</div>
<div class="ulist">
<ul>
<li>
<p>The Table Service feature enables creation of REST services based on tables and views in SAP. The REST services can be queried, filtered, sorted, and paged by the consumer.</p>
</li>
<li>
<p>The Table Services can be configured to expose all or selected fields in the table(s)/view(s) including CDS views based on the use-case.</p>
</li>
<li>
<p>The Table Services can join SAP Tables using Inner and Outer join(Left and Right).</p>
</li>
<li>
<p>For large loads (1000s of records), Table Services should be used in conjunction with the Table Service Extracts feature which provides batching.</p>
</li>
</ul>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p><strong>Use case examples</strong></p>
</div>
<div class="ulist">
<ul>
<li>
<p>Synchronize master data</p>
<div class="ulist">
<ul>
<li>
<p>Create a REST service with SAP product data that can be utilized in scheduled integrations to update other applications with the latest product data</p>
</li>
</ul>
</div>
</li>
<li>
<p>Expose up-to-date data</p>
<div class="ulist">
<ul>
<li>
<p>Create a REST service with SAP stock data that can be requested on-demand by other applications</p>
</li>
</ul>
</div>
</li>
<li>
<p>Expose modelled data</p>
<div class="ulist">
<ul>
<li>
<p>Create a REST service of customer data based on an SAP view, or joining several tables.</p>
</li>
</ul>
</div>
</li>
</ul>
</div>
<div class="paragraph">
<p><br></p>
</div>
</div>
</div>
</div></template>
<script>export default {}; </script>