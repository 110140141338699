<template><div>
<div class="sect1">
<h2 class="section-headline pb-4" id="_receiver_framework">Receiver Framework</h2>
<div class="sectionbody">
<div class="paragraph">
<p>The Receiver Framework gives you the freedom of sending data wherever you would like in whatever format suits you.</p>
</div>
<div class="paragraph">
<p>The Receiver Framework has the following components:</p>
</div>
<div class="ulist">
<ul>
<li>
<p>An interface from where you can choose one of the implemented prebuild receivers.</p>
</li>
<li>
<p>Possibility to create custom receivers</p>
</li>
<li>
<p>A custom data formatter which let you choose what output you would like your data send in</p>
</li>
<li>
<p>A Migration Tool to migrate your the receivers you are currently using in version 1.5.2 or older to the new framework.</p>
</li>
<li>
<p>The option to set timelines and parameters for your receivers so you can change them over time automatically.</p>
</li>
</ul>
</div>
<div class="paragraph">
<p>Use case examples:</p>
</div>
<div class="ulist">
<ul>
<li>
<p>You would like to send an email to the customer when a delivery of a good is happening, without having to use other middleware</p>
</li>
<li>
<p>You would like your receivers to automatically change periodically</p>
</li>
</ul>
</div>
</div>
</div>
</div></template>
<script>export default {}; </script>