<template><div>
<div class="sect1">
<h2 class="section-headline pb-4" id="_events">Events</h2>
<div class="sectionbody">
<div class="paragraph">
<p>The Event feature enables creation of subscriptions for Business Object events and Change Pointers in SAP.</p>
</div>
<div class="paragraph">
<p>Use case examples:</p>
</div>
<div class="olist arabic">
<ol class="arabic">
<li>
<p>Subscribe for a Business Object Event</p>
<div class="ulist">
<ul>
<li>
<p>Identify the data object you want to relate the events to. The business objects must either be related to change pointer events or business events.</p>
</li>
</ul>
</div>
</li>
<li>
<p>Identify event and subscribe</p>
<div class="ulist">
<ul>
<li>
<p>Choose the related event to the business object (i.e. Created, Changed, Deleted) or choose relevant changepointer.</p>
</li>
</ul>
</div>
</li>
<li>
<p>Define subscription type.</p>
<div class="ulist">
<ul>
<li>
<p>Define whether this is an event including payload, metadata or just an event. If it is an event with payload, then a table service must be chosen.</p>
</li>
<li>
<p>Also define whether this is a batch subscription or a realtime subscription - i.e. will this just be sent whenever events are sent from SAP following normal event framework, or whenever this occurs, must it start a transfer of events. Realtime subscription is not possible for Change Pointers.</p>
</li>
</ul>
</div>
</li>
<li>
<p>Define Receiver type.</p>
<div class="ulist">
<ul>
<li>
<p>From release 1.6.0 the Receiver Framework has been created which enables you to send exactly where you want, in whatever format. Read more by choosing Receiver Framework under Getting Started.</p>
</li>
</ul>
</div>
</li>
<li>
<p>Define Topic</p>
<div class="ulist">
<ul>
<li>
<p>Define the topic which must be sent as a path to e.g. Solace or Boomi. The topic includes both static and dynamic fields, where the topic is either written in the UI or associated with fields in the payload structure (if available).</p>
</li>
</ul>
</div>
</li>
</ol>
</div>
</div>
</div>
</div></template>
<script>export default {}; </script>