import Vue from "vue";
import { Component } from "vue-property-decorator";
import { mdiTruckFast, mdiBankTransfer, mdiFolderTable, mdiAccountSupervisor, mdiAccountClock, mdiInfinity, mdiApi, mdiGoogleCloud, mdiNas, mdiCloudOutline } from "@mdi/js";

@Component({})
export default class Diagram extends Vue {
  showDiagram = true;

  truckIcon = mdiTruckFast;
  bankTransferIcon = mdiBankTransfer;
  folderTableIcon = mdiFolderTable;
  accountSupervisorIcon = mdiAccountSupervisor;
  clockIcon = mdiAccountClock;
  infinityIcon = mdiInfinity;
  apiIcon = mdiApi;
  googleCloudIcon = mdiGoogleCloud;
  nasIcon = mdiNas;
  cloudOutlineIcon = mdiCloudOutline;
  wrapperDimension = {
    width: 0,
    height: 0
  };

  sapToPointDone = false;
  startPointOn = false;
  axisBurst = false;
  showAxisToBoomi = false;
  showBoomiToEndPoints = false;

  sendDataToBoomi = false;
  sendDataToEndPoint = false;

  startNodes = [
    { x: -5, y: 35, icon: this.truckIcon },
    { x: 14, y: 23, icon: this.accountSupervisorIcon },
    { x: 30, y: 10, icon: this.folderTableIcon },
    { x: 38, y: 63, icon: this.bankTransferIcon },
    { x: 35, y: 80, icon: this.clockIcon },
    { x: 6, y: 90, icon: this.infinityIcon }
  ];

  endNodes = [
    { x: 95, y: 23, icon: this.apiIcon },
    { x: 95, y: 38, icon: this.googleCloudIcon },
    { x: 95, y: 53, icon: this.nasIcon },
    { x: 95, y: 68, icon: this.cloudOutlineIcon }
  ];

  sapToStartPointConnections = [
    // sap > truckIcon
    [[6, 10], [-3, 20], [this.startNodes[0].x, this.startNodes[0].y]],
    // sap > accountSupervisorIcon
    [[6, 10], [6, 23], [14, 23]],
    // sap > folderTableIcon
    [[6, 10], [14, 4], [25, 4], [30, 10]],
    // sap > bankTransferIcon
    [[6, 10], [-6, 16], [-10, 25], [-10, 63], [38, 63]],
    // sap > clockIcon
    [[6, 10], [-10.7, 10], [-10.7, 70], [-3, 80], [35, 80]],
    // sap > infinityIcon
    [[6, 10], [-3, 2], [-11.4, 2], [-11.4, 76], [-1, 90], [6, 90]]
  ];

  startPointsToAxisConnections = [
    [[-5, 35], [0, 40], [45, 40], [58, 45]],
    [[14, 23], [50, 23], [58, 45]],
    [[30, 10], [37, 18], [58, 18], [58, 45]],
    [[38, 63], [45, 63], [58, 45]],
    [[35, 80], [50, 80], [58, 45]],
    [[6, 90], [58, 90], [58, 45]]
  ];

  axisToBoomi = [
    [[58, 45], [78, 45]]
  ];

  boomiToEndPoints = [
    [[78, 45], [88, 23], [95, 23]],
    [[78, 45], [88, 38], [95, 38]],
    [[78, 45], [88, 53], [95, 53]],
    [[78, 45], [88, 68], [95, 68]]
  ];

  sapToStartPointConnectionsDistances: Array<number> = [];
  startPointsToAxisConnectionsDistances: Array<number> = [];
  axisToBoomiConnectionsDistances: Array<number> = [];

  randomDataArray: Array<Array<Array<number>>> = [];

  created() {
    window.addEventListener("resize", this.onWindowResize);
    this.diagramInit();
  }

  destroyed() {
    window.removeEventListener("resize", this.onWindowResize);
  }

  onWindowResize() {
    this.showDiagram = false;
    this.$nextTick(() => {
      this.showDiagram = true;
      this.diagramInit();
    });
  }

  diagramInit() {
    this.$nextTick(() => {
      this.wrapperDimension.width = (this.$refs["diagram-wrapper"] as HTMLElement).clientWidth;
      this.wrapperDimension.height = (this.$refs["diagram-wrapper"] as HTMLElement).clientHeight;
      this.connectionDistance(this.sapToStartPointConnections, this.sapToStartPointConnectionsDistances);
      this.connectionDistance(this.startPointsToAxisConnections, this.startPointsToAxisConnectionsDistances);
      this.connectionDistance(this.axisToBoomi, this.axisToBoomiConnectionsDistances);
    });
  }

  generateRandomData() {
    const numberOfStartPoints = this.startNodes.length;
    // const numberOfEndPoints = this.endNodes.length;
    const numberOfRandomStartPoints = this.getRandomInt(numberOfStartPoints) || 1;
    const randomStartPoints = [];
    while (randomStartPoints.length < numberOfRandomStartPoints) {
      const randomNumber = this.getRandomInt(numberOfStartPoints);
      if (randomStartPoints.indexOf(randomNumber) === -1) randomStartPoints.push(randomNumber);
    }
    const randomData = [randomStartPoints, [this.getRandomInt(4)]];
    this.randomDataArray.push(randomData);
    // console.log("axisIterationAnimation", numberOfRandomStartPoints, randomData);
  }

  connectionDistance(source: Array<Array<Array<number>>>, target: Array<number>) {
    source.forEach((nodeLine: Array<Array<number>>) => {
      let distance = 0;
      nodeLine.forEach((point, index) => {
        if (index < nodeLine.length - 1) {
          distance = distance + this.getDistance(nodeLine[index][0], nodeLine[index][1], nodeLine[index + 1][0], nodeLine[index + 1][1]);
        }
      });
      target.push(distance);
    });
  }

  getRandomInt(max: number) {
    return Math.floor(Math.random() * Math.floor(max));
  }

  getDistance(x1: number, y1: number, x2: number, y2: number) {
    const xDiff = x1 - x2;
    const yDiff = y1 - y2;

    return Math.sqrt(xDiff * xDiff + yDiff * yDiff);
  }

  onConnectionLineAnimationEnd(source: string) {
    if (source === "sapToStartPoints") this.sapToPointDone = true;
    if (source === "startPointsToAxis") this.showAxisToBoomi = true;
    if (source === "axisToBoomi") this.showBoomiToEndPoints = true;
    if (source === "boomiToEndPoints" && this.randomDataArray.length === 0) this.generateNewDataBurst();
  }

  startNodeAnimationEnd() {
    const animationName = (event as AnimationEvent).animationName;
    if (animationName.indexOf("ripple-burst-animation-data") !== -1) {
      (event!.target as any).parentElement.classList.remove("burst");
    }
    if (animationName.indexOf("node-bubble-outer-wrapper-start-node-fadeIn-animation") !== -1) {
      (event!.target as any).parentElement.classList.remove("show");
    }
    this.startPointOn = true;
  }

  axisIterationAnimation() {
    const animationName = (event as AnimationEvent).animationName;
    if (animationName.indexOf("axis-data-circle-ripple") !== -1 && this.sendDataToBoomi) {
      // console.warn(event);
    }
  }

  dataToAxisAnimationEnd(point: number) {
    // this.axisBurst = true;
    const index = this.randomDataArray[0][0].indexOf(point);
    this.randomDataArray[0][0].splice(index, 1);
    if (this.randomDataArray[0][0].length === 0) {
      this.sendDataToBoomi = true;
      (this.$refs.axisNode as any).classList.add("axis-burst");
    }
  }

  dataToBoomiAnimationEnd() {
    this.sendDataToBoomi = false;
    this.sendDataToEndPoint = true;
    (this.$refs.boomiNode as any).classList.add("burst");
  }

  boomiNodeAnimationEnd() {
    const animationName = (event as AnimationEvent).animationName;
    if (animationName.indexOf("ripple-burst-animation") !== -1) {
      (this.$refs.boomiNode as any).classList.remove("burst");
    }
  }

  dataToEndPointAnimationEnd() {
    this.sendDataToEndPoint = false;
    this.randomDataArray.forEach((randomData) => {
      randomData[1].forEach((randomPoint) => {
        ((this.$refs.endPointBubble as any)[randomPoint] as any).classList.add("burst");
      });
    });
    setTimeout(() => this.generateNewDataBurst(), 1000);
  }

  endPointNodeAnimationEnd(index: number) {
    const animationName = (event as AnimationEvent).animationName;
    if (animationName.indexOf("ripple-burst-animation") !== -1) {
      (this.$refs.endPointBubble as any)[index].classList.remove("burst");
    }
  }

  generateNewDataBurst() {
    this.randomDataArray = [];
    this.$nextTick(() => {
      this.generateRandomData();
      this.randomDataArray.forEach((randomData) => {
        randomData[0].forEach((randomPoint) => {
          ((this.$refs.startPointBubble as any)[randomPoint] as any).classList.add("burst");
        });
      });
    });
  }

  pointsGenerator(nodeLine: Array<Array<number>>) {
    // console.log("pointsGenerator", this.wrapperDimension.width);
    let points = "M";
    nodeLine.forEach((nodePoint) => {
      let point = "";
      nodePoint.forEach((coordinate, index) => {
        if (index === 0) {
          point += `${this.wrapperDimension.width / 100 * coordinate},`;
        } else {
          point += `${this.wrapperDimension.height / 100 * coordinate} `;
        }
      });
      points += point;
    });
    return points;
  }

  dataCircleStyle(node: any) {
    // const nodeLinePoints = this.nodeLines[index];
    // let distance = 0;
    let styleData = `offset-path:path('${this.pointsGenerator(node)}');`;
    // for (let i = 0, len = nodeLinePoints.length; i < len - 1; i++) {
    //   distance += this.getDistance(nodeLinePoints[i][0], nodeLinePoints[i][1], nodeLinePoints[i + 1][0], nodeLinePoints[i + 1][1]);
    //   console.log("calculate");
    // }
    // console.log(index);
    styleData += `animation-duration:${50 * 8 / 150}s;`;

    // console.log(nodeLinePoints);
    return styleData;
  }
}