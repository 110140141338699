<template><div>
<div class="sect1">
<h2 class="section-headline pb-4" id="_bw_data_extractor">BW Data Extractor</h2>
<div class="sectionbody">
<div class="paragraph">
<p>BW Data extractors send data from SAP ECC to SAP BW for reporting.</p>
</div>
<div class="paragraph">
<p>The BW Extractor feature enables processing BW Extractors via REST services. The feature plugs into the standard BW Extractor feature of SAP and exposes the Extractors for integration purposes.</p>
</div>
<div class="paragraph">
<p>The feature has two components:</p>
</div>
<div class="ulist">
<ul>
<li>
<p>Boomi for SAP Core is configured to send BW Extractors metadata to a REST service</p>
</li>
<li>
<p>Boomi for SAP Core exposes REST services which are utilized to retrieve and/or delete the BW Extractors</p>
</li>
</ul>
</div>
<div class="paragraph">
<p>Use case examples:</p>
</div>
<div class="ulist">
<ul>
<li>
<p>Send aggregated financial data to a reporting database</p>
</li>
<li>
<p>Send changed order data to a data warehouse</p>
</li>
</ul>
</div>
</div>
</div>
</div></template>
<script>export default {}; </script>