<template><div>
<div class="sect1">
<h2 class="section-headline pb-4" id="_configuration">Configuration</h2>
<div class="sectionbody">

</div>
</div>
<div class="sect1">
<h2 class="section-headline pb-4" id="_bw_data_extractor">BW Data Extractor</h2>
<div class="sectionbody">
<div class="paragraph">
<p>To use the BW component, 'BADI RSU5_SAPI_BADI' must be implemented. This is delivered as part of the Boomi for SAP product via implementation /IXBX/RSU5_SAPI_BADI.
If other implementations for the BADI already exist, it must analyzed how the BADI’s can coexist or be merged.</p>
</div>
<div class="sect2">
<h2 id="_activation_of_bw_extractors" class="section-headline pb-4">Activation of BW extractors</h2>
<div class="paragraph">
<p>Via transaction /IXBX/ZBX006 data extractors are activated for Boomi for SAP integration. These must be transported. if they are handled by the Boomi for SAP implementation field Custom must be blank. If they are to be handled by existing customer BADI implementation The value for Custom must be 'X'-</p>
</div>
<div class="paragraph">
<p>When merging with custom implementation the Boomi for SAP logic must be called via method 'DATA_TRANSFORM_CUSTOM' after the BADI has filtered for relevant datasources.
This Method ensures that only a datasource marked for Custom is extracted. This ensures that the data source is only handled in a Single implementation.</p>
</div>
</div>
<div class="sect2">
<h2 id="_scheduling_bw_send" class="section-headline pb-4">Scheduling BW send</h2>
<div class="paragraph">
<p>Activated extractors are listed in the UI for verification of activation.</p>
</div>
<div class="paragraph">
<p>Program /IXBX/ZBX_BW_EXT_SEND (/IXBX/ZBX007) must be scheduled as a periodic job to send Boomi ID’s for created extracts. Variant for the program must be created and used for the job for setting the parameters to integrate to Boomi like URL to the ATOM and path to HTTP listener process. The ID&#8217;s for the new extracts will be send to Boomi and marked as processed. When the program runs, it will only process new and unprocessed entries.</p>
</div>
<div class="paragraph">
<p>Test extracts can be created in SAP transaction RSA3 (Extractor Checker)</p>
</div>
</div>
</div>
</div>
</div></template>
<script>export default {}; </script>