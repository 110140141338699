<template><div>
<div class="sect1">
<h2 class="section-headline pb-4" id="_table_service_extracts">Table Service Extracts</h2>
<div class="sectionbody">
<div class="paragraph">
<p>With Table Service Extracts, large amounts of Table Service data can easily be sent to Boomi.</p>
</div>
<div class="paragraph">
<p>The Table Service Extract feature enables users/applications to request Table Service data. The data is pushed by Boomi for SAP to a REST web service in one or more threads.</p>
</div>
<div class="paragraph">
<p>Using the Table Service Extract feature is better than Table Services for performance in most scenarios since it can run in multiple threads and batching is performed automatically.</p>
</div>
<div class="paragraph">
<p>Table Service Extracts can be requested in SAP via a manual/scheduled job or in Boomi via a Function Module REST service.</p>
</div>
<div class="paragraph">
<p>Use case examples:</p>
</div>
<div class="ulist">
<ul>
<li>
<p>Replication of an SAP table/view to another application</p>
<div class="ulist">
<ul>
<li>
<p>Migration from SAP ECC to SAP S4</p>
</li>
</ul>
</div>
</li>
<li>
<p>Scheduled synchronization of changed SAP data to another application</p>
<div class="ulist">
<ul>
<li>
<p>Keeping stock and customer data updated in a B2B Web CRM</p>
</li>
</ul>
</div>
</li>
<li>
<p>Scheduled synchronization of filtered SAP data to another application</p>
<div class="ulist">
<ul>
<li>
<p>Keeping employee data updated in subsidiaries</p>
</li>
</ul>
</div>
</li>
</ul>
</div>
</div>
</div>
</div></template>
<script>export default {}; </script>