import Vue from "vue";
import { Component } from "vue-property-decorator";
import HeaderBanner from "@/components/header-banner/HeaderBanner.vue";
import VideoPanel from "@/components/videoPanel/videoPanel.vue";
import HexPanels from "@/components/hexPanels/HexPanels.vue";
import TypeOnePanels from "@/components/typeOnePanels/typeOnePanels.vue";
import TypeTwoPanels from "@/components/typeTwoPanels/typeTwoPanels.vue";
import TypeThreePanels from "@/components/typeThreePanels/typeThreePanels.vue";

@Component({ name: "Home", components: { HeaderBanner, VideoPanel, HexPanels, TypeOnePanels, TypeTwoPanels, TypeThreePanels } })
export default class Home extends Vue {
}