<template><div>
<div class="sect1">
<h2 class="section-headline pb-4" id="_introduction">Introduction</h2>
<div class="sectionbody">
<div class="paragraph">
<p>This user guide explains how to set up an RFC connection in SAP.</p>
</div>
<div class="paragraph">
<p>This guide will describe how to set up an RFC Connection in SAP that connects to a Boomi Atom via Basic authentication. Your setup might differ if you use another type of authentication, a reverse proxy, or a load balancer.</p>
</div>
<div class="paragraph">
<p>The user guide follows these steps:</p>
</div>
<div class="ulist">
<ul>
<li>
<p>Find Atom information in Boomi Atomsphere</p>
</li>
<li>
<p>Set-up Web service listener operation in Boomi</p>
</li>
<li>
<p>Create external connection in SM59 in SAP</p>
</li>
</ul>
</div>
</div>
</div>
<div class="sect1">
<h2 class="section-headline pb-4" id="_boomi_configuration">Boomi Configuration</h2>
<div class="sectionbody">
<div class="paragraph">
<p>To receive SAP data in Boomi, configure the Boomi Shared Web Server and develop and deploy Boomi web services processes.</p>
</div>
<div class="sect2">
<h2 id="_boomi_shared_web_server" class="section-headline pb-4">Boomi Shared Web Server</h2>
<div class="paragraph">
<p>On the Boomi platform:</p>
</div>
<div class="olist arabic">
<ol class="arabic">
<li>
<p>Navigate to the the Shared Web Server settings on the runtime you want to connect to</p>
</li>
<li>
<p>On the General tab take note of the hostname and port as they will be used in the SAP configuration</p>
</li>
<li>
<p>On the User Management tab, create a new user or verify that an existing user can be used</p>
<div class="ulist">
<ul>
<li>
<p>Take note of the Username and Token as they will be used in the SAP configuration</p>
</li>
<li>
<p>'Token' corresponds to 'Password' in SAP</p>
</li>
</ul>
</div>
</li>
</ol>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/token.png" alt="token" />
</div>
</div>
</div>
<div class="sect2">
<h2 id="_boomi_web_service_processes" class="section-headline pb-4">Boomi Web Service Processes</h2>
<div class="paragraph">
<p>Set up Boomi Web Service processes for the features you want to use. The only requirement is:</p>
</div>
<div class="ulist">
<ul>
<li>
<p>The Web Server Service listener must be configured to receive JSON via the POST method.</p>
</li>
</ul>
</div>
<div class="paragraph">
<p>Deploy the process(es) to a Boomi Atom.</p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/boomi-web-server-operation.png" alt="operation" />
</div>
<div class="title">Figure 1. Example of Web Services Server Operation that receives Table Service Extracts.</div>
</div>
</div>
</div>
</div>
<div class="sect1">
<h2 class="section-headline pb-4" id="_sap_rfc_configuration">SAP RFC Configuration</h2>
<div class="sectionbody">
<div class="paragraph">
<p>Set up an RFC connection to connect to the corresponding Boomi Atom by using transaction SM59 with the Atom Web Server details including:</p>
</div>
<div class="ulist">
<ul>
<li>
<p>Target Host: The base url for the atom</p>
</li>
<li>
<p>Service No: The port (defaults to 80 for http and 443 for https)</p>
<div class="ulist">
<ul>
<li>
<p>Standard ports for the Boomi Web Service are 9090 and 9093 for http and https, respectively.</p>
</li>
</ul>
</div>
</li>
<li>
<p>User and Password (Token)</p>
</li>
</ul>
</div>
<div class="paragraph">
<p>on the Technical Settings tab, set the Target host and Service No:</p>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/SM59_1.png" alt="SM59_1" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p>On the Logon &amp; Security page, set the User and Password. If using SSL, set the SSL certificate.</p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/SM59_2.png" alt="SM59_2" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p>Perform a Connection Test to verify that the connection works. Note that the connection test will only be successful if the Boomi Atom Web Server is running. This requires at least one web service listener to be deployed to the Atom.</p>
</div>
<div class="paragraph">
<p>Take note of the RFC Destination Name since this will be used later.</p>
</div>
</div>
</div>
</div></template>
<script>export default {}; </script>