import Vue from "vue";
import { Component } from "vue-property-decorator";

@Component({})
export default class HexPanels extends Vue {
  selectedPanel = -1;
  contentPanels = [3, 5, 12, 14];
  contentPanelsData = [
    {
      text: "Accelerate digital ambition by less dependency on specialists",
      icon: require("@/graphics/icons/speed.svg")
    },
    {
      text: "Empower diverse line-of-business by providing a comprehensive self-service data ecosystem",
      icon: require("@/graphics/icons/space.svg")
    },
    {
      text: "Faster decision by giving trusted real-time business data",
      icon: require("@/graphics/icons/trust.svg")
    },
    {
      text: "Minimize costs of data integration maintenance",
      icon: require("@/graphics/icons/efficiency.svg")
    }
  ];

  panelClassName(initialClassName: string, index: number) {
    let className = initialClassName;
    const topHalfPanels = [4, 6, 8];
    const bottomHalfPanels = [9, 11, 13];
    if (topHalfPanels.indexOf(index) !== -1) {
      className += " top-half-cropped";
    }

    if (bottomHalfPanels.indexOf(index) !== -1) {
      className += " bottom-half-cropped";
    }

    if (this.contentPanels.indexOf(index) !== -1) {
      className += ` content-panel content-panel_${this.contentPanels.indexOf(index) + 1}`;
    }

    if (this.selectedPanel !== -1) {
      if (this.selectedPanel === index) {
        className += " selected";
      } else {
        className += " not-selected";
      }
    }

    return className;
  }

  onClickOutside(index: number) {
    if (this.selectedPanel === index) {
      this.selectedPanel = -1;
    }
  }

  onClick(index: number) {
    if (this.contentPanels.indexOf(index) !== -1) {
      this.selectedPanel = index;
      // console.log(index, window.getComputedStyle((this.$refs.contentHexPanel as any)[index - 1]).transform);
    }
  }
}