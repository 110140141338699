<template><div>
<div class="sect1">
<h2 class="section-headline pb-4" id="_appendix">Appendix</h2>
<div class="sectionbody">
<div class="paragraph">
<p>The following appendixes are a deep dive into event and payload filtering.</p>
</div>
</div>
</div>
<div class="sect1">
<h2 class="section-headline pb-4" id="_event_filtering_for_business_objects">Event Filtering for Business objects</h2>
<div class="sectionbody">
<div class="paragraph">
<p>This document assumes that the document for change pointer filtering has been read and will focus on the differences between these and not go into details about filtering covered by CP (Change Pointer)</p>
</div>
<div class="paragraph">
<p>In this example we will use the BANKDETAIL object as this has many key fields and therefore allows for a more complex filter setup.</p>
</div>
<div class="paragraph">
<p>Main subscription screen only ‘ADD_EVENT_FILTER’ is available if Payload has not been selected.</p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/BO1.png" alt="BO1" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p>When clicking ‘ADD EVENT FILTERS’ the event filter screen is shown. Object Class is the BO (BUSINESS OBJECT). Table will be defaulted with the table value if there are only key fields with references to a single table.</p>
</div>
<div class="paragraph">
<p>The key fields for this object class – table combination is shown.</p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/BO2.png" alt="BO2" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p>The length of the input is compared to the definition of the referenced field and indicates if input is too long</p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/BO3.png" alt="BO3" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p>Here simple filtering rules are added for two fields. Fields that should not have a filter are just left blank.</p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/BO4.png" alt="BO4" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p>Click Apply Filters to keep changes. (Scroll down if button is not shown due to too many fields shown)</p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/BO5.png" alt="BO5" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p>By choosing the payload option the key mapping is shown and allows you to link the event data to input for the payload extraction. At least one mapping is required to ensure that some limitation is done for the extract. But depending on the business object key definition and the table service table keys not all might be required. The picture shows a partial mapping. The extract will then extract all records from PA0009 which has the values for the specified fields in the mapping according to values in events. Even if this results in multiple records. Therefore, this option provided high flexibility but also requires some consideration of the consequences of the mapping selected.</p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/BO6.png" alt="BO6" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p>If an event filter exist for a business object event subscription, then no filter is relevant for the actual change and the event will then be discarded.</p>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="sect2">
<h2 id="_payload_filter" class="section-headline pb-4">Payload filter</h2>
<div class="paragraph">
<p>When selecting ‘payload’ as event mode the payload filter displays as an option</p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/BO8.png" alt="BO8" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p>When pre presented with an option to select the tables in the table service used for payload data. The object class is the business object and is a static value.</p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/BO9.png" alt="BO9" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p>When selecting a table, you are presented with an option to select a field for that table.</p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/BO10.png" alt="BO10" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p>When you have selected a column name, the filtering options will appear.</p>
</div>
<div class="paragraph">
<p>Furthermore you can create advanced filtering or add additional field filtering</p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/BO11.png" alt="BO11" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p>Advanced filtering works as described for change pointers.</p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/BO12.png" alt="BO12" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p>When adding a field, the list is shown again but the fields that already have filtering are not available as these filtering rules must be defined together. (Here BANKL is disabled as this filter is already added)</p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/BO13.png" alt="BO13" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p>Now we have added two filters – Note the advanced filter icon to the right of the BANKL filter due to more than one rule. For all fields there is a minus icon This is to remove a field if the filter rule for this is not needed any more.</p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/BO14.png" alt="BO14" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p>Please note that if a filter is not fully filled the Add/Apply filter button is deactivated as the filter rule for a field must be fully specified or removed if not needed.</p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/BO15.png" alt="BO15" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p>The overview of implemented events shows some details of the events but not the filtering part.</p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/BO16.png" alt="BO16" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p>For this you need to go into the View/edit mode and go to the Subscription configuration sub-page and click the event filter or payload filter button to see the details.</p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/BO17.png" alt="BO17" />
</div>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/BO18.png" alt="BO18" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
</div>
</div>
</div>
<div class="sect1">
<h2 class="section-headline pb-4" id="_change_pointer_event_filtering">Change Pointer Event filtering</h2>
<div class="sectionbody">
<div class="paragraph">
<p>When creating an Event subscription the event filtering options are based on the data available in the event. For Business objects the event filtering is done at the time of the event creation, for Change pointers this is done immediately as part of moving from SAP standard events to the Boomi for SAP dispatch layer.</p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/CP1.png" alt="CP1" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p>When adding an event, the object and table that the filtering is related to must be selected.</p>
</div>
<div class="paragraph">
<p>Example MATMAS (Material Master) – only has the object MATERIAL, therefore it is defaulted, and you need to select the table that the event filter is relevant for.</p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/CP2.png" alt="CP2" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p>By selecting DMAKT the key for that is shown.</p>
</div>
<div class="paragraph">
<p>Example for filter out non-German (Language code ‘D’) texts</p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/CP3.png" alt="CP3" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p>It is also possible to use external values here, but please note that the values are converted to internal SAP values, therefore high and low values may differ from their external values. So for instance if you use the languages "DA" as Low and "DE" as High the system will complain that "DA" is not lower than "DE" since "DA" internally is "K" where "DE" is "D".</p>
</div>
<div class="paragraph">
<p>Press ‘Show Advanced Filters’ to allow more than one filter rule for a field.</p>
</div>
<div class="paragraph">
<p>Here we add ‘E’ To allow Both German and English Material texts.</p>
</div>
<div class="paragraph">
<p>All rules with the with either I (Incl) or E (excl) will be OR’ed whereas the included will be AND’ed with the excluded ones.</p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/CP4.png" alt="CP4" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p>Click ‘ADD ADVANCED FILTERS’ to keep changes or ‘CANCEL’ to leave advanced filters without keeping changes</p>
</div>
<div class="paragraph">
<p>If there is an “Advanced filter (more than 1 row)” then the marked Icon is added to show the user that additional rules exist.</p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/CP5.png" alt="CP5" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p>If we also want to add a filter since the event is triggered by another table, an example could be MARC, choose this from the Table drop down list to display the key fields available for filtering for this table.</p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/CP6.png" alt="CP6" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p>When entering the value in ‘Low value’ (From value) the Sign is defaulted to ‘I’ for include, and ‘Equal to’ for the option</p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/CP7.png" alt="CP7" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p>If we also add a value in ‘High value’ (To value) to create a range the Option is changed to ‘Between.’ Similar if you remove the value in ‘High value’ the Option is changed back to Option.</p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/CP8.png" alt="CP8" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p>Added filters in the upper left corner shows which tables there has been implemented filters for.</p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/CP9.png" alt="CP9" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p>If an event filter exist for a change pointer subscription. Then if no filter is relevant for the actual change. Then the event is not relevant, eg. a filter for MATMAS object MATERIAL table MARA. If an event is created for other tables than MARA for CP MATMAS eg. MAKT then the event is filtered out as none of the filter rules are relevant for this table.</p>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="sect2">
<h2 id="_change_pointer_payload" class="section-headline pb-4">Change pointer Payload</h2>
<div class="paragraph">
<p>When selecting Event – payload four new options are available.</p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/CP10.png" alt="CP10" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p><strong>1. Payload Filter:</strong> This will allow you to add filter rules for the table service tables which the payload contains.</p>
</div>
<div class="paragraph">
<p><strong>2. Table service Selection:</strong> Which Table Service would you like to use for extraction</p>
</div>
<div class="paragraph">
<p><strong>3. Class and Table for Change:</strong> If you want to differentiate mapping, you can choose object class and table</p>
</div>
<div class="paragraph">
<p><strong>4. Change Object ID:</strong> If the key fields are not sufficient you can manually map the contents of the change object ID here.</p>
</div>
<div class="paragraph">
<p>The table key is for the specific table e.g., MARC whereas the Material object is on a higher-level containing information about which material is being changed.</p>
</div>
<div class="paragraph">
<p>Please note that some tables are stored in the event as a reduced dataset. E.g., MAKT is stored as DMAKT (Material texts) DMAKT only stores the language key as it is linked to MATERIAL object which stores the material number. To identify the material text both parts are needed for key mapping to get the correct entry in table MAKT.</p>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="sect3">
<h3 id="_mapping_via_cd_object_id" class="section-headline pb-4">Mapping via CD object ID.</h3>
<div class="paragraph">
<p>Click the plus sign to get the input field. As Boomi for SAP cannot determine the key definition of this object. Whereas when referring business objects or tables, the fields are provided as input.</p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/CP11.png" alt="CP11" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p>The user needs to know how the key info is stored in the CD object key. If the full key should be mapped, then just add offset = 0 and Length = 0 to use the full CD key. E.g. For MATERIAL, the key is a material number (char 18). As there is only one key field you can either use Length = 0 for full field or the precise length (18).</p>
</div>
<div class="paragraph">
<p>Here we have mapped the full object key of the event as input to the table service.</p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/CP12.png" alt="CP12" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p>Example of Event data (BDCP2)</p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/CP13.png" alt="CP13" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p>Here the material of the event is ‘TG12’ Boomi for SAP will convert the mapping above to</p>
</div>
<div class="paragraph">
<p>“MARA~MATNR = ‘TG12’” for the selection "where clause" when linking the data from the event to the data extraction for the payload.</p>
</div>
<div class="paragraph">
<p>This is an example of a key mapping for use for all tables (since the table selection is left blank) for the MATERIAL object.</p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/CP14.png" alt="CP14" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p>Here we have changed the mapping rule to be specific for material texts (DMAKT)</p>
</div>
<div class="paragraph">
<p>Then the key field for DMAKT is shown allowing the user to use the DMAKT key info as part of the payload extraction.</p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/CP15.png" alt="CP15" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p>For our BDCP2 example data shown above the "where clause" input will be converted to:</p>
</div>
<div class="paragraph">
<p>“MARA~MATNR = ‘TG12’ AND</p>
</div>
<div class="paragraph">
<p>MAKT~SPRAS = ‘E’”.</p>
</div>
<div class="paragraph">
<p>As DMAKT key is split between the Change object key and the Table key it is a good example of when it is needed to use both to get the correct table entry in the database table MAKT.</p>
</div>
<div class="paragraph">
<p>The tables that can be used for mapping are the ones from the table service selected.</p>
</div>
<div class="paragraph">
<p>It is required to have at least one field mapped. The reason one mapping is needed is to restrict the data being extracted otherwise it would just do a full extract of the table service.</p>
</div>
<div class="paragraph">
<p>The reason not all event key data needs to be mapped is that if for an example that a change to MARC is changed (key material (MATNR) and Plant (WERKS)) the subscription is perhaps linked to a custom material table with only the material as key and therefore only this field is mapped.</p>
</div>
<div class="paragraph">
<p><br></p>
</div>
</div>
</div>
<div class="sect2">
<h2 id="_payload_filter_for_change_pointers" class="section-headline pb-4">Payload Filter for change pointers.</h2>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/CP16.png" alt="CP16" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p>The table field is used for both the event table and the table filter table (therefore this needs to be split into two separate fields)</p>
</div>
<div class="paragraph">
<p>The key field is the field of the table that the filter is implemented for.</p>
</div>
<div class="paragraph">
<p>The plus sign allows you to add additional fields which you wish to filter on. The fields available are the fields of the table in the table service (field not required to be part of table service extract).</p>
</div>
<div class="paragraph">
<p>Here we have a filter on materials belonging to division 10-50 and Creation date between 01.01.2022 and 01.01.2030. Please note the use of internal SAP date format.</p>
</div>
<div class="paragraph">
<p>The minus sign next to the field rule allows for the removal of an unwanted filter rule that is not needed anyway.</p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/CP17.png" alt="CP17" />
</div>
</div>
</div>
</div>
</div>
</div></template>
<script>export default {}; </script>