<template><div>
<div class="sect1">
<h2 class="section-headline pb-4" id="_idocs">IDocs</h2>
<div class="sectionbody">
<div class="sect2">
<h2 id="_sap_and_boomi_for_sap_core_configuration" class="section-headline pb-4">SAP and Boomi for SAP Core Configuration</h2>
<div class="paragraph">
<p>The following steps (and transactions) will have to be done to utilize the IDoc feature in Boomi for SAP.
- Create an Boomi for SAP IDoc user (SU01) and add the role /IXBX/idoc</p>
</div>
<div class="paragraph">
<p><em>It is necessary to add role /IXBX/idoc to the user as an authorization object embedded in the role to access the Boomi for SAP functionality.</em></p>
</div>
<div class="ulist">
<ul>
<li>
<p>Set up a RFC destination for IDoc handling (SM59)</p>
</li>
<li>
<p>Set up a Ports for IDoc Processing (WE21)</p>
</li>
<li>
<p>Create Logical System(s) in BD54 - suggestion BOOMI</p>
</li>
<li>
<p>Set up a Partner Profile(s) for Boomi for SAP (WE20)</p>
</li>
<li>
<p>Set up a IDoc handler (SICF)</p>
</li>
<li>
<p>Set up a RFC destination (SM59) for the receiving system</p>
</li>
<li>
<p>Set up relation between IDoc Ports and RFC destination</p>
</li>
</ul>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="sect3">
<h3 id="_create_an_boomi_for_sap_idoc_user" class="section-headline pb-4">Create an Boomi for SAP IDoc user</h3>
<div class="paragraph">
<p>User Type = SYSTEM</p>
</div>
<div class="paragraph">
<p>Add Boomi for SAP role /IXBX/IDOC or add the created Authorization Object</p>
</div>
<div class="paragraph">
<p>Suggested name: BOOMI-IDOC</p>
</div>
<div class="paragraph">
<p><br></p>
</div>
</div>
<div class="sect3">
<h3 id="_set_up_a_rfc_destination_for_idoc_handling" class="section-headline pb-4">Set up a RFC destination for IDoc handling</h3>
<div class="paragraph">
<p>Following steps need to be completed:</p>
</div>
<div class="ulist">
<ul>
<li>
<p>RFC Destination for IDoc handling: Suggested name - BOOMI_IDOC</p>
</li>
<li>
<p>Type = G</p>
</li>
<li>
<p>Target Host</p>
</li>
</ul>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/configuration_RFC_connection_technical_settings.png" alt="SM59" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
</div>
<div class="sect3">
<h3 id="_set_up_a_ports_for_idoc_processing" class="section-headline pb-4">Set up a Ports for IDoc Processing</h3>
<div class="ulist">
<ul>
<li>
<p>Select port type: XML HTTP</p>
</li>
<li>
<p>Port - Suggested names: BOOMI (JSON, pashing though Boomi for SAP as in example) and BOOMI (XML, sending to Boomi, choose RFC Destination for Boomi)</p>
</li>
<li>
<p>RFC Destination: Choose the RFC Destination just created in SM59 (BOOMI_DOC)</p>
</li>
</ul>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/configuration_RFC_connection_we21.png" alt="WE21" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
</div>
<div class="sect3">
<h3 id="_batch_processing" class="section-headline pb-4">Batch processing</h3>
<div class="paragraph">
<p>Note that when you choose to use batching, sending more IDOC&#8217;s(inbound) to SAP via XML request, you will need to configure the business partner to "collect", when having a separate processing SAP batch job running. This forces the IDOC&#8217;s to be handled sequentially instead of parallel. Then the IDOC&#8217;s are all handled correctly and will return the correct IDOC numbers in the response.</p>
</div>
<div class="paragraph">
<p><br></p>
</div>
</div>
<div class="sect3">
<h3 id="_set_up_a_partner_profiles_for_boomi_for_sap" class="section-headline pb-4">Set up a Partner Profiles for Boomi for SAP.</h3>
<div class="paragraph">
<p>Create number of Partner Profiles depending on how many system you like to send data to.</p>
</div>
<div class="ulist">
<ul>
<li>
<p>Partner Number: Suggested name - BOOMI</p>
</li>
<li>
<p>Partner Type: "LS"</p>
</li>
</ul>
</div>
<div class="paragraph">
<p>Set up Outbound parameters(example):</p>
</div>
<div class="ulist">
<ul>
<li>
<p>Message type: MATMAS</p>
</li>
<li>
<p>Receiver port: BOOMI</p>
</li>
<li>
<p>Basic type: MATMAS05</p>
</li>
</ul>
</div>
<div class="paragraph">
<p>Set up Inbound parameters(example).</p>
</div>
<div class="ulist">
<ul>
<li>
<p>Partner Role: LS</p>
</li>
<li>
<p>Message Type: MATMAS</p>
</li>
<li>
<p>Process code: MATM</p>
</li>
</ul>
</div>
</div>
<div class="sect3">
<h3 id="_set_up_a_idoc_handler_in_sicf" class="section-headline pb-4">Set up a IDoc handler in SICF</h3>
<div class="paragraph">
<p>Set up the specific idoc handler in SICF under /ixbx as shown in the image below.</p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/sicf_idoc_setup.png" alt="SIFC_IDoc_setup" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p>Add a handler to the idoc service:</p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/sicf_idoc_handler_setup.png" alt="SICF_IDoc_handler" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p><strong>Note for version 1.4</strong></p>
</div>
<div class="exampleblock">
<div class="content">
 <em>Bundling of outbound IDoc for JSON is not supported in this version. To be able send in batch, set the Pack size in the Partner Profile (WE20) in "Outbound Options".</em>
</div>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/configuration_RFC_connection_we20.png" alt="WE20" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
</div>
<div class="sect3">
<h3 id="_further_information" class="section-headline pb-4">Further information</h3>
<div class="paragraph">
<p>Logic system for Boomi for SAP is setup in /n/ixbx/zbx016 = what is show in the UI under Partner.</p>
</div>
<div class="paragraph">
<p>Alternative setup, if Port and RFC Destination is not created with the same name: /n/ixbx/zbx017</p>
</div>
<div class="paragraph">
<p><br></p>
</div>
</div>
</div>
<div class="sect2">
<h2 id="_list_and_manage" class="section-headline pb-4">List and Manage</h2>
<div class="sect3">
<h3 id="_idoc_explorer" class="section-headline pb-4">IDoc Explorer</h3>
<div class="paragraph">
<p>In the Idoc explorer you can see all Idoc&#8217;s in SAP. You can search for either Idoc type or message  type or both. You are able to see the following information:</p>
</div>
<div class="paragraph">
<p><strong>Idoc type</strong>: Idoc identification</p>
</div>
<div class="paragraph">
<p><strong>Description</strong>: Document description</p>
</div>
<div class="paragraph">
<p><strong>Message type</strong>: Type of message</p>
</div>
<div class="paragraph">
<p><strong>Released</strong>: Release ID for IDoc</p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/list_and_manage_canvas.png" alt="List_and_Manage" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p><strong>Documentation</strong>: Documentation let&#8217;s you see the SAP documentation of the IDoc. This is meant to let you easy understand the content of the IDoc.</p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/list_and_manage_canvas_documentation.png" alt="List_and_Manage_Documentation" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p><strong>XML Schema</strong>: Downloading the XML Schema. This download option is meant to make test on other systems easier for integration.</p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/list_and_manage_canvas_xml_schema.png" alt="list_and_manage_canvas_xml_schema" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
</div>
<div class="sect3">
<h3 id="_boomi_for_sap_idoc_partner_setup" class="section-headline pb-4">Boomi for SAP IDoc Partner Setup</h3>
<div class="paragraph">
<p>For IDoc Partner setup you are able to see which partners are already setup and for which parameters they are set up for. Outbound parameters shows which services are setup to be send and inbound shows which are setup to be received. The following information is shown:</p>
</div>
<div class="paragraph">
<p><strong>Partner role</strong>: Partner type(SAP standard)</p>
</div>
<div class="paragraph">
<p><strong>Message type</strong>: Type of Message</p>
</div>
<div class="paragraph">
<p><strong>Message Function</strong>: File Format</p>
</div>
<div class="paragraph">
<p><strong>Test</strong>: Is it activated for test</p>
</div>
<div class="paragraph">
<p><strong>Receiver Port</strong>: The connection port</p>
</div>
<div class="paragraph">
<p><strong>Receiver Port Description</strong>: Description of Port</p>
</div>
<div class="paragraph">
<p><strong>Pack Size</strong>: Size of</p>
</div>
<div class="paragraph">
<p><strong>Basic Type</strong>: Type of service</p>
</div>
<div class="paragraph">
<p><strong>Message Type Description</strong>: Description of Message type</p>
</div>
<div class="paragraph">
<p><strong>Process Code</strong>: ID of the processing service</p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/list_and_manage_partner_setup.png" alt="list_and_manage_partner_setup" />
</div>
</div>
</div>
</div>
<div class="sect2">
<h2 id="_boomi_atomsphere_process" class="section-headline pb-4">Boomi AtomSphere Process</h2>
<div class="paragraph">
<p>Boomi for SAP Core and Boomi communicates in two different ways depending on which system initiates the communication.</p>
</div>
<div class="paragraph">
<p><strong>Send IDocs from Boomi to SAP</strong></p>
</div>
<div class="paragraph">
<p>IDoc services created in Boomi for SAP Core are imported in Boomi using the branded Boomi for SAP Connector.</p>
</div>
<div class="paragraph">
<p><strong>Receive IDocs in Boomi from SAP</strong></p>
</div>
<div class="paragraph">
<p>REST services are created in Boomi using the standard Boomi Web Services component. Boomi for SAP Core is configured to send IDocs to the Boomi REST service.</p>
</div>
</div>
<div class="sect2">
<h2 id="_send_idocs_to_sap_from_boomi" class="section-headline pb-4">Send IDocs to SAP from Boomi</h2>
<div class="paragraph">
<p>This section describes how to utilize the IDoc Service created above.</p>
</div>
<div class="paragraph">
<p>The <em><strong>Boomi for SAP</strong></em> is no different from other Boomi connectors. It makes development easy by importing operations and profiles.</p>
</div>
<div class="sect3">
<h3 id="_building_a_boomi_process" class="section-headline pb-4">Building a Boomi Process</h3>
<div class="paragraph">
<p>The example process will demonstrate how to send IDocs to SAP.</p>
</div>
<div class="paragraph">
<p>Create a new Process and configure the Start Shape type to <em><strong>No Data</strong></em>.</p>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/IDoc-send-nodata.png" alt="IDoc-nodata" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p>Add a <em><strong>Message Shape</strong></em> immediately after the Start Shape and connect the Start Shape to the Message Shape. In this example process, the Message Shape contains test data corresponding to the Request Profile created in the next step. When building your processes, map your data to the Request Profiles of the Operations.</p>
</div>
</div>
<div class="sect3">
<h3 id="_operation" class="section-headline pb-4">Operation</h3>
<div class="paragraph">
<p>This section describes how to configure the operation for the <em><strong>Boomi for SAP Connector</strong></em>.</p>
</div>
<div class="paragraph">
<p>Add a Connector Shape to the canvas. Add a <em>'Display Name'</em>. Select an existing Connector or create a new Connector.</p>
</div>
<div class="paragraph">
<p>Choose the Action <strong><em>IDOC_SEND</em></strong> and create a new operation.</p>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/IDoc-send-operation.png" alt="IDoc-send-operation" />
</div>
</div>
<div class="paragraph">
<p>In the new operation, click <em>Import</em> and follow the guide to Import the operation and profile.</p>
</div>
<div class="paragraph">
<p>Choose an <em><strong>Atom</strong></em> to perform the import. The Atom should have network access to the SAP and Boomi for SAP Core instance. Choose the appropriate Connector. The optional Filter option allows for wildcard filtering.</p>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/IDoc-send-import.png" alt="IDoc-send-import" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
</div>
<div class="sect3">
<h3 id="_operation_2" class="section-headline pb-4">Operation</h3>
<div class="paragraph">
<p>Create a new Connector Operation, you should click on the small <strong>'green plus icon'</strong> in the Operation field. You are redirected to the page where you can create the Connector Operation.</p>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/IDoc-send-operation-shape.png" alt="IDoc-send-operation-shape" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p>The operation is now populated with the Object and Request Profile.</p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/IDoc-send-operation.png" alt="IDoc-send-operation" />
</div>
</div>
</div>
<div class="sect3">
<h3 id="_completing_the_process" class="section-headline pb-4">Completing the Process</h3>
<div class="paragraph">
<p>Finalize the configuration by adding a <em><strong>Stop Shape</strong></em> and connecting it to the Connector Shape.</p>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/IDoc-send-process-complete.png" alt="IDoc-receive-process" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p>Add test data to the <em><strong>Message Shape</strong></em> that conforms to the Request Profile format.
To test the process, click on <strong>'Test'</strong> in the upper right corner and choose the Atom to run the test.</p>
</div>
</div>
</div>
<div class="sect2">
<h2 id="_receive_idocs_in_boomi_from_sap" class="section-headline pb-4">Receive IDocs in Boomi from SAP</h2>
<div class="paragraph">
<p>This section describes how to receive IDocs from SAP using Boomi for SAP.</p>
</div>
<div class="paragraph">
<p>The IDocs will be sent from Boomi for SAP Core to a standard Boomi Web Service with a Shared Web Server using API Type 'Intermediate'.</p>
</div>
<div class="sect3">
<h3 id="_building_a_boomi_process_2" class="section-headline pb-4">Building a Boomi Process</h3>
<div class="paragraph">
<p>The example process will demonstrate how to receive IDocs from SAP.</p>
</div>
<div class="paragraph">
<p>Create a new Process and configure the Start Shape type to <em><strong>Web Services Server</strong></em>.</p>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/IDoc-receive-web-service.png" alt="IDoc-web-service" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="paragraph">
<p>Create a new Web Services Server Operation that is configured with:</p>
</div>
<div class="ulist">
<ul>
<li>
<p><strong>Operation Type</strong> Choose an appropriate type.</p>
</li>
<li>
<p><strong>Object</strong> Choose an appropriate name.</p>
</li>
<li>
<p><strong>Expected Input Type</strong> Either <em>'Single XML Object'</em> or <em>'Single JSON Object'</em> depending on your setup in Boomi for SAP Core</p>
</li>
<li>
<p><strong>Response Output Type</strong> 'Single Data'</p>
</li>
</ul>
</div>
<div class="paragraph">
<p>Take note of the <em>*Simple URL Path*</em>. This path must match the path configured in SAP.</p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/IDoc-receive-web-service-operation.png" alt="IDoc-web-service-operation" />
</div>
</div>
<div class="paragraph">
<p>The Start Shape is now configured to receive IDocs from Boomi for SAP Core.</p>
</div>
</div>
<div class="sect3">
<h3 id="_importing_the_idoc_profile" class="section-headline pb-4">Importing the IDoc Profile</h3>
<div class="paragraph">
<p>To import the IDoc Profile (XML or JSON):</p>
</div>
<div class="ulist">
<ul>
<li>
<p>Deploy the Web Service Process</p>
</li>
<li>
<p>Send an IDoc to the Web Service</p>
</li>
<li>
<p>Download the IDoc from the Boomi Process Log</p>
</li>
<li>
<p>Import the IDoc in Boomi</p>
</li>
</ul>
</div>
<div class="paragraph">
<p>With the imported Profile, the process can be completed.</p>
</div>
</div>
<div class="sect3">
<h3 id="_completing_the_process_2" class="section-headline pb-4">Completing the Process</h3>
<div class="paragraph">
<p>The example process below is completed by mapping the IDoc Profile to a flat file and saving it to the disk.</p>
</div>
<div class="paragraph">
<p><br></p>
</div>
<div class="imageblock">
<div class="content">
<v-img contain src="./graphics/IDoc-receive-process-complete.png" alt="IDoc-receive-process" />
</div>
</div>
<div class="paragraph">
<p><br></p>
</div>
</div>
</div>
</div>
</div>
</div></template>
<script>export default {}; </script>