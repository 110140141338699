<template>
  <div class="subpage-wrapper">
    <div class="header-banner">
      <div class="image-wrapper">
        <v-img
          alt="Boomi for SAP core"
          contain
          src="@/graphics/hand-paper-airplanes.svg"
        />
      </div>
      <v-container class="content">
        <h1>{{ pageHeaderText }}</h1>
        <h3>{{ pageSubheadline }}</h3>
      </v-container>
    </div>
    <v-container class="content-section">
      <v-card width="100%" class="pa-10 mb-10">

        <HyperAuto />

        <v-row class="buttons-wrapper" align="center">
          <v-col cols="12" sm="12">
            <div class="text-center">
              <div class="my-8">
                <v-btn
                  x-large
                  color="accent"
                  dark
                  to="/getting-started-with-axis/hyper-automation/user-guide"
                  >User Guide</v-btn
                >
              </div>
            </div>
          </v-col>
        </v-row>
      </v-card>
    </v-container>
  </div>
</template>

<style scoped lang="scss">
.header-banner .image-wrapper ::v-deep .v-image__image--contain {
  height: 750px;
  transform: translate(-630px, -40px) rotate(10deg);
}
</style>

<script>
import Vue from "vue";
import { Component } from "vue-property-decorator";
import HyperAuto from "./_index.vue";

@Component({ components: { HyperAuto } })
export default class index extends Vue {
  pageHeaderText = "";
  pageSubheadline = "";
  mounted() {
    const $pageHeader = document.getElementById("_hyper_automation");
    const $pageSubheader = document.getElementsByClassName("paragraph")[0];
    $pageHeader.classList.add("hidden-content");
    $pageSubheader.classList.add("hidden-content");
    this.pageHeaderText = $pageHeader.innerText;
    this.pageSubheadline = $pageSubheader.innerText;
  }
}
</script>
