var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._m(0),_c('div',{staticClass:"sect1"},[_c('h2',{staticClass:"section-headline pb-4",attrs:{"id":"_guide"}},[_vm._v("Guide")]),_c('div',{staticClass:"sectionbody"},[_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8),_vm._m(9),_vm._m(10),_vm._m(11),_vm._m(12),_vm._m(13),_c('div',{staticClass:"imageblock"},[_c('div',{staticClass:"content"},[_c('v-img',{attrs:{"contain":"","src":require("./graphics/filled_initial_load_startscreen.png"),"alt":"initial-load-startscreen"}})],1)]),_vm._m(14),_vm._m(15),_vm._m(16),_c('div',{staticClass:"imageblock"},[_c('div',{staticClass:"content"},[_c('v-img',{attrs:{"contain":"","src":require("./graphics/dynamic_selections.png"),"alt":"dynamic-selections"}})],1)]),_vm._m(17),_vm._m(18),_vm._m(19),_vm._m(20),_vm._m(21),_c('div',{staticClass:"imageblock"},[_c('div',{staticClass:"content"},[_c('v-img',{attrs:{"contain":"","src":require("./graphics/successful_run.png"),"alt":"successful_run"}})],1)]),_vm._m(22)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sect1"},[_c('h2',{staticClass:"section-headline pb-4",attrs:{"id":"_introduction"}},[_vm._v("Introduction")]),_c('div',{staticClass:"sectionbody"},[_c('div',{staticClass:"paragraph"},[_c('p',[_vm._v("This guide will describe how to request Table Service Extracts in SAP.")])]),_c('div',{staticClass:"paragraph"},[_c('p',[_vm._v("In Boomi, the Function Module Service "),_c('strong',[_c('em',[_vm._v("/IXBX/BATCH_TAB_SERV_EXTRACT")])]),_vm._v(" can be used to achieve the same result.")])]),_c('div',{staticClass:"sect2"},[_c('h2',{staticClass:"section-headline pb-4",attrs:{"id":"_prerequisites"}},[_vm._v("Prerequisites")]),_c('div',{staticClass:"ulist"},[_c('ul',[_c('li',[_c('p',[_vm._v("SAP RFC Connection to the Boomi runtime (Atom, Molecule, Cloud) and a Boomi process to receive the data")]),_c('div',{staticClass:"ulist"},[_c('ul',[_c('li',[_c('p',[_c('a',{attrs:{"href":"../configuration/rfc-configuration.adoc"}},[_vm._v("RFC Configuration User Guide")])])])])])]),_c('li',[_c('p',[_vm._v("The Table Service to be used has been created")]),_c('div',{staticClass:"ulist"},[_c('ul',[_c('li',[_c('p',[_c('a',{attrs:{"href":"tableservice-userguide.adoc"}},[_vm._v("Table Service User Guide")])])])])])])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"paragraph"},[_c('p',[_vm._v("To start a Table Service Extract, log on to SAP and run transaction "),_c('em',[_c('strong',[_vm._v("/IXBX/ZBX008")])]),_vm._v(" to get to the Table Service Extract page.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"paragraph"},[_c('p',[_vm._v("Fill out the below listed fields:")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"paragraph"},[_c('p',[_c('strong',[_vm._v("Table Service")]),_vm._v(": which table service definition to use for extracting data. Click on the "),_c('strong',[_vm._v("search icon")]),_vm._v(" to be able to choose the table service in a drop down menu.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"paragraph"},[_c('p',[_c('strong',[_vm._v("Topic")]),_vm._v(": Used to allow the output paths to be identical, if the data has been extracted via events via the payload option.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"paragraph"},[_c('p',[_c('strong',[_vm._v("RFC Destination")]),_vm._v(": The destination of the atom. Click to the right in the RFC destination box to be able to choose the RFC destination in a drop down menu.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"paragraph"},[_c('p',[_c('strong',[_vm._v("Max Records")]),_vm._v(": Maximum records to send. In the below example max records is set to 10.000 records. If max records is set to 0 it means that the amount of records are unlimited.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"paragraph"},[_c('p',[_c('strong',[_vm._v("Path")]),_vm._v(": The path is appended to the RFC Destination. The path must match the path configured in the receiving Boomi web service.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ulist"},[_c('ul',[_c('li',[_c('p',[_c('strong',[_vm._v("Path Not Required if")]),_vm._v(": Path is not required for a Table Service Extract. But note that RFC or Topic must handle the path if not provided in the execution of the Table Service Extract.")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"paragraph"},[_c('p',[_c('strong',[_vm._v("Bundle size")]),_vm._v(": How many objects in each bundle. In our example it is set to 1.000, which means the 10.000 max records would be sent in 10 bundles with 1.000 records in each.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"paragraph"},[_c('p',[_c('strong',[_vm._v("Server Group")]),_vm._v(": Choose server group in the drop down menu")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"paragraph"},[_c('p',[_c('strong',[_vm._v("Max Threads")]),_vm._v(": Choose how many simultaneous workers")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"paragraph"},[_c('p',[_c('strong',[_vm._v("Delay, each message")]),_vm._v(": How many seconds to sleep a thread after a bundle has been sent, in case receiver has trouble processing")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"paragraph"},[_c('p',[_c('br')])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"paragraph"},[_c('p',[_c('br')])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"paragraph"},[_c('p',[_vm._v("To limit the data that will be send to Boomi it is possible to define filter values. Click on the "),_c('strong',[_vm._v("'Dynamic where criterias'")]),_vm._v(" button to filter the values. You will then be redirected to a new page showing the fields in the table selected previously, which in this example is the "),_c('em',[_vm._v("'SKAT'")]),_vm._v(" table. Double click on the folder shown in the upper left-hand corner to see the fields included in the "),_c('em',[_vm._v("'SKAT'")]),_vm._v(" table. Then double click on the field you want to filter and fill in the values in the boxes that shows up. If you want to remove one of the filters, then double click on the field you want to remove in the menu on the left.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"paragraph"},[_c('p',[_c('br')])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"paragraph"},[_c('p',[_c('br')])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"paragraph"},[_c('p',[_vm._v("When the values are filled in click on the "),_c('strong',[_vm._v("'Save'")]),_vm._v(" button. You will then return to the Boomi for SAP Table Service Extract page.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"paragraph"},[_c('p',[_vm._v("When the information has been filled in and you have filtered the fields if relevant, press on the "),_c('strong',[_vm._v("'Execute'")]),_vm._v(" button. If the extraction is successful, a message will inform regarding the number of extracted messages. In case of errors, an error message will be shown.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"paragraph"},[_c('p',[_vm._v("In the above "),_c('em',[_vm._v("'SKAT'")]),_vm._v(" example "),_c('em',[_vm._v("Max Records")]),_vm._v(" were specified as "),_c('em',[_vm._v("10.000 Max Records")]),_vm._v(", but after applying a filter there is only "),_c('em',[_vm._v("6601")]),_vm._v(" that meets our Dynamic Criteria. These are then successfully sent as seen below.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"paragraph"},[_c('p',[_c('br')])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"paragraph"},[_c('p',[_c('br')])])
}]

export { render, staticRenderFns }