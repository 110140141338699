import Vue from "vue";
import { Component } from "vue-property-decorator";

@Component({})
export default class TypeThreePanels extends Vue {
  panels = [
    {
      headline: "Lower development time and costs",
      icon: "coding",
      description: "This is done ensuring SAP Data can be exposed to Boomi by line of business via a browser based interface and easily imported into Boomi. This will lower your ABAP and Boomi Development costs. Another way to lower development time and costs is by utilizing the Data Wizards. Data Wizards help line of business users identify tables to expose to Boomi. Instead of having to know the technical table name, the line of business user can search for business object or domain via a browser based interface and find the relevant technical table names. This will ensure, that development time and costs will be significantly lowered for exposing standard SAP data."
    },
    {
      headline: "Lower maintenance costs",
      icon: "agile",
      description: "Boomi for SAP Connector is presented in a browser based interface which makes it simple to get an overview of current Services exposed to Boomi via Boomi for SAP Connector, and get a detailed view of these services, delete these services or create new services. This lowers maintenance costs due to minimal developer locking and the ability to utilize line of business users for maintenance as well as development."
    },
    {
      headline: "Leverage current investments in SAP",
      icon: "sap",
      description: "Boomi for SAP Connector ensures a direct link between Boomi and SAP via the inbuilt web server in SAP, ensuring you do not need to maintain and use other components to connect Boomi and SAP. By ensuring Boomi for SAP is based on SAP Standard technology and utilizes SAP Standard framework, it is possible to leverage current investments in the SAP stack via Boomi. Thereby Boomi for SAP Connector can follow current SAP Releases via transport management system and utilize current setup in SAP authentication and security."
    },
    {
      headline: "Enable the digital integration hub and ensure SAP Data is updated across the IT Landscape",
      icon: "refactoring",
      description: "A baseline for having a digital integration hub, is the possibility of exposing events from backend systems. Boomi for SAP enables this, by establishing event micro-services in SAP by exposing SAP Events to Boomi, thereby making it possible to react to events in Boomi and e.g. update relevant data across the IT Landscape."
    }
  ];
}