<template>
  <div class="subpage-wrapper">
    <div class="header-banner">
      <div class="image-wrapper">
        <v-img
          alt="Boomi for SAP"
          contain
          src="@/graphics/birds.svg"
        />
      </div>
      <v-container class="content">
        <h1>{{ pageHeaderText }}</h1>
      </v-container>
    </div>
    <v-container class="content-section">
      <v-card
        width="100%"
        class="pa-10 mb-10"
      >
        <RfcConfiguration />
      </v-card>
    </v-container>
  </div>
</template>

<style scoped lang="scss">
.header-banner .image-wrapper ::v-deep .v-image__image--contain {
  height: 750px;
  transform: translate(-580px, -140px);
}
</style>

<script>
import Vue from "vue";
import { Component } from "vue-property-decorator";
import RfcConfiguration from "./_RfcConfiguration.vue";

@Component({ components: { RfcConfiguration } })
export default class rfcConfiguration extends Vue {
  pageHeaderText = "RFC Configuration";
  mounted() {
  }
}
</script>